import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import secureLocalStorage from "react-secure-storage";
import "./Header.css";
import Login from "../PopUps/Login";
import CheckAuth from "../ServiceFunctions/CheckAuth";

// Currency options with flag image URLs
const currencies = [
  { code: "INR", name: "INR (₹)", flag: "https://flagcdn.com/in.svg" },
  { code: "USD", name: "USD ($)", flag: "https://flagcdn.com/us.svg" },
  { code: "EUR", name: "EUR (€)", flag: "https://flagcdn.com/eu.svg" },
  { code: "GBP", name: "GBP (£)", flag: "https://flagcdn.com/gb.svg" },
  { code: "JPY", name: "JPY (JP¥)", flag: "https://flagcdn.com/jp.svg" },
  { code: "CNY", name: "CNY (CN¥)", flag: "https://flagcdn.com/cn.svg" },
];

const sidebarData = [
  {
    id: 0,
    title: "Catalogue",
    href: "/catalogue?category=boots",
    icon: <img src="/images/adminImages/Material.svg" />,
  },
  {
    id: 1,
    title: "Accessories",
    href: "/accessories",

    icon: <img src="/images/adminImages/product listing.svg" />,
  },
  {
    id: 2,
    title: "Affiliates",
    href: "/affiliates",
    icon: <img src="/images/adminImages/orderhistory.svg" />,
  },
  {
    id: 3,
    title: "Connect",
    href: "/connect",
    icon: <img src="/images/adminImages/connect.svg" />,
  },
  {
    id: 4,
    title: "Shoemaking",
    href: "/shoemaking",
  },
  {
    id: 5,
    title: "Guide",
    href: "/guide",
  },
  {
    id: 6,
    title: "Blogs",
    href: "/blogs",
  },
  {
    id: 7,
    title: "Financial",
    href: "/financial",
  },
  {
    id: 7,
    title: "Events",
    href: "/events",
  },
  {
    id: 7,
    title: "Virtual Tour",
    href: "/virtualtour",
  },
];

function Header({
  popupState,
  closePopup = () => {},
  curr,
  onCartPosition,
  cartCount,
}) {
  const location = useLocation();
  const hash = location.hash.substring(1);
  const { email, isAuthenticated, type, message } = CheckAuth({ hash });
  const [shoes, setShoes] = useState([]);
  const [hoveredContent, setHoveredContent] = useState("");
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [PopUp, setPopUp] = useState(
    location?.state !== null ? location?.state : popupState
  );
  const dropdownRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("INR");
  const [activeId, setActiveId] = useState(0);
  const [cartItem, setCartItem] = useState(cartCount || 0);
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const navigate = useNavigate();
  const sideBarRef = useRef(null);
  const cartRef = useRef(null); // Reference to the Hello button

  useEffect(() => {
    if (cartRef.current && onCartPosition) {
      const helloRect = cartRef.current.getBoundingClientRect();
      onCartPosition(helloRect); // Pass the position to the parent
    }
  }, [cartRef.current]);

  useEffect(() => {
    if (
      message === "Secret Code is Invalid." ||
      message === "Secret Code has Expired."
    ) {
      setPopUp("login");
    }
    if (message === "success") {
      alert("Successfully logged in.");
    }
  }, [message]);

  useEffect(() => {
    if (email && email !== "") {
      fetch(`https://buwchlifestyles.com/api/get-cart-data/`, {
        method: "POST",
        body: JSON.stringify({
          email,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          if (data.message === "success") {
            setCartItem(data.data);
          } else {
            alert(data.message);
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    }
  }, [cartCount, email]);

  // Sync PopUp state with popupState prop from Dashboard
  useEffect(() => {
    setPopUp(location?.state !== null ? location?.state : popupState);
  }, [popupState]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (sideBarRef.current && !sideBarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    }

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sideBarRef]);

  useEffect(() => {
    if (query.length > 2) {
      const fetchResults = async () => {
        try {
          const response = await fetch(
            "https://buwchlifestyles.com/api/search_function/",
            {
              method: "POST", // Use POST method
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ query }), // Send the query in the body
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          setResults(data);
        } catch (error) {
          console.error("Error fetching search results:", error);
        }
      };
      const delayDebounceFn = setTimeout(() => {
        fetchResults();
      }, 300); // debounce by 300ms

      return () => clearTimeout(delayDebounceFn);
    } else {
      setResults([]);
    }
  }, [query]);

  useEffect(() => {
    fetch(`https://buwchlifestyles.com/api/get-all-products-data/`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setShoes(data.products);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, []);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  const logout = () => {
    const refreshToken = getCookie("refresh_token");
    secureLocalStorage.clear();
    if (refreshToken) {
      fetch(`https://buwchlifestyles.com/api/logout/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh_token: refreshToken,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to logout");
          }
          return response.json();
        })
        .then((response) => {
          document.cookie =
            "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie =
            "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          if (response.error === "Logged out successfully") {
            const currentUrl = new URL(window.location.href);
            if (currentUrl.pathname === "/") {
              window.location.reload();
            } else {
              window.location.href = "/";
            }
          } else {
            alert(response.error);
            window.location.href = "/userLost";
          }
        })
        .catch((error) => {
          // console.error("Error during logout:", error);
        });
    } else {
      alert("refresh token not provided or connection failure");
      window.location.href = "/accessDenied";
    }
  };

  const popupRef = useRef(null);

  // Handle clicks outside the popup to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopUp("");
        closePopup();
      }
    };

    if (PopUp) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [PopUp]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setAnimating(true);
        setTimeout(() => {
          setShow(false);
          setAnimating(false);
        }, 300);
      }
    }

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      <div className="w-full flex justify-center fixed top-0 h-fit z-50 bg-white transition-all duration-150 shadow-[0px_1px_0px_0px_#00000024]">
        {/* Non-logged In  */}
        {!isAuthenticated ? (
          <nav className="  w-full  flex   flex-col justify-center items-center ">
            <div className=" w-[90%] lg:w-[85%] flex  bg-white   justify-between items-center">
              {/* menu-items */}
              <div className="w-[40%] flex justify-start items-center text-[#212121]">
                <div className="max-lg:hidden lg:flex justify-start items-center md:gap-3 lg:gap-[25px]">
                  <button
                    onClick={() => {
                      setIsSidebarOpen(true);
                    }}
                  >
                    <svg
                      className="w-7 h-7"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      id="fi_9344210"
                    >
                      <g id="Layer_2" fill="rgb(0,0,0)" data-name="Layer 2">
                        <path d="m27 17h-22a1 1 0 0 1 0-2h22a1 1 0 0 1 0 2z"></path>
                        <path d="m27 10h-22a1 1 0 0 1 0-2h22a1 1 0 0 1 0 2z"></path>
                        <path d="m27 24h-22a1 1 0 0 1 0-2h22a1 1 0 0 1 0 2z"></path>
                      </g>
                    </svg>
                  </button>

                  <div className="menu-item   transition-all duration-75 py-4">
                    <a
                      href="/catalogue?category=boots"
                      className={`${
                        window.location.pathname === "/products" ? "" : ""
                      } font-semibold text-sm lg:text-base  flex items-center justify-center header-text text-[#212121] hover:text-[#212121] `}
                    >
                      Catalogue <FaChevronDown size={10} className="ms-1" />
                    </a>
                    {/* <section className="sub-menu-container absolute w-screen left-0 py-3"> */}
                    {/* <div className="px-3 w-[20%]">
                        {uniqueShoes?.map((pro, index) => (
                          <div className="flex justify" key={index}>
                            <div className="border-l-2 px-3 border-l-gray-400 text-sm hover:font-bold font-normal text-gray-400 hover:text-gray-900 hover:border-l-gray-900">
                              <a
                                href={`/products_page/${pro.Article?.split("_")
                                  ?.slice(0, -1)
                                  .join("_")}`}
                                onMouseEnter={() =>
                                  setHoveredContent(
                                    Object.values(pro?.Images)?.[0]?.Main
                                  )
                                }
                                onMouseLeave={() => setHoveredContent("")}
                              >
                                <div className="flex justify-start items-center">
                                  <img
                                    src={`${
                                      Object.values(pro?.Images)?.[0]?.Main
                                    }`}
                                    alt={pro.Article?.split("_")
                                      ?.slice(0, -1)
                                      .join("_")}
                                    className="h-12 w-12"
                                  />

                                  <span className="ml-4 text-sm">
                                    {pro.Article?.split("_")
                                      ?.slice(0, -1)
                                      .join("_")}
                                  </span>
                                </div>
                              </a>
                            </div>
                          </div>
                        ))}
                      </div> */}
                    {/* <div className="px-3 w-[20%]">
                        <div className="bg-gray-200 w-full h-full flex items-center justify-center">
                          {hoveredContent !== "" && (
                            <div className="">
                              <img
                                alt=""
                                src={`${hoveredContent}`}
                                className="h-60 w-60 "
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="px-3 w-[20%]">
                        <h5 className="border-b-2 border-b-black inline ">
                          Featured Product
                        </h5>
                        <p className="mt-3">
                          <ul>
                            <li>LIMITED EDITION SHOES BLACK LOAFERS </li>
                            <li>NEW COLLECTION</li>
                            <li>CHELSEA BOOTS MEN</li>
                            <li>CARMINA X ABSOLUTE BESPOKE</li>
                            <li>TASSEL LOAFER</li>
                            <li>SUNLINED SHOE</li>
                            <li>SOUR BEST SELLERS</li>
                            <li>BLACK DRESS SHOES</li>
                            <li>BLACK CHELSEA BOOTSEEE</li>
                          </ul>
                        </p>
                      </div>
                      <div className="px-3 w-[20%]">
                        <h5 className="border-b-2 border-b-black inline ">
                          Featured Product
                        </h5>
                        <p className="mt-3">
                          <ul>
                            <li>LIMITED EDITION SHOES BLACK LOAFERS </li>
                            <li>NEW COLLECTION</li>
                            <li>CHELSEA BOOTS MEN</li>
                            <li>CARMINA X ABSOLUTE BESPOKE</li>
                            <li>TASSEL LOAFER</li>
                            <li>SUNLINED SHOE</li>
                            <li>SOUR BEST SELLERS</li>
                            <li>BLACK DRESS SHOES</li>
                            <li>BLACK CHELSEA BOOTSEEE</li>
                          </ul>
                        </p>
                      </div>
                      <div></div>
                    </section> */}
                  </div>
                  <a
                    href="/"
                    className={`${
                      window.location.pathname === "" ? "" : ""
                    } font-semibold text-sm lg:text-base header-text text-[#212121] hover:text-[#212121]`}
                  >
                    Accessories
                  </a>
                  <a
                    href="/"
                    className={`${
                      window.location.pathname === "" ? "" : ""
                    } font-semibold text-sm lg:text-base header-text text-[#212121] hover:text-[#212121]`}
                  >
                    Affiliates
                  </a>
                </div>
                <div className="input-wrapper2 max-lg:flex  lg:hidden">
                  <button className="icon2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      className="w-4 h-4 md:w-5 md:h-5"
                    >
                      <path
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                      ></path>
                      <path
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        d="M22 22L20 20"
                      ></path>
                    </svg>
                  </button>
                  <input
                    placeholder="Search..."
                    className="input2 text-sm md:text-base h-8 md:h-9 w-28 sm:w-48 md:w-64 xl:w-80"
                    name="text"
                    type="text"
                  />
                </div>
              </div>

              <div className="w-[20%] max-md:py-1 md:py-2 flex align-center justify-center">
                <a href="/">
                  <img
                    className="w-12 lg:w-16 "
                    src="/images/logo3.png"
                    alt=""
                  />
                </a>
              </div>

              <div className="w-[40%]  flex justify-end items-center max-md:gap-0 md:gap-5">
                <div className="input-wrapper max-lg:hidden lg:flex">
                  <button className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      height="20px"
                      width="20px"
                    >
                      <path
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                      ></path>
                      <path
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        d="M22 22L20 20"
                      ></path>
                    </svg>
                  </button>
                  <input
                    placeholder="Search..."
                    className="input"
                    name="text"
                    type="text"
                  />
                </div>

                <div className="relative">
                  <button
                    onClick={() => {
                      navigate(`/default/dashboard`);
                    }}
                    className="cursor-pointer flex items-center  bg-[#fff] hover:bg-yellow-200   duration-300 ease-in-out p-2 rounded-full scale-100 hover:scale-95 "
                  >
                    <img
                      className="w-8 h-8 md:h-9  md:w-9 fill-[#3d3d3d] p-0.5"
                      src="/images/dashboard (1).svg"
                      alt=""
                    />
                  </button>
                </div>
                <button
                  onClick={() => {
                    setPopUp("login");
                  }}
                  className="cursor-pointer flex items-center  bg-[#fff] hover:bg-yellow-200 duration-300 ease-in-out p-2 rounded-full scale-100 hover:scale-95"
                >
                  <img
                    className="w-8 h-8 md:h-9  md:w-9 fill-[#3d3d3d]"
                    src="/images/user.svg"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </nav>
        ) : (
          <nav className="w-[90%] lg:w-[92%]  flex  bg-white  justify-between items-center ">
            {/* menu-items */}

            <div className="w-[12%] max-md:py-1 md:py-2 flex align-center justify-center">
              <a href="/">
                <img className="w-12 md:w-16 " src="/images/logo3.png" alt="" />
              </a>
            </div>
            <div className="w-[48%] flex max-md:justify-center md:justify-start items-center  text-[#212121]">
              <div className="input-wrapper2 ">
                <button className="icon2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="w-4 h-4 md:w-5 md:h-5"
                  >
                    <path
                      strokeLinejoin="round"
                      strokeLinecap="round"
                      d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    ></path>
                    <path
                      strokeLinejoin="round"
                      strokeLinecap="round"
                      d="M22 22L20 20"
                    ></path>
                  </svg>
                </button>
                <input
                  placeholder="Search..."
                  className="input2 text-sm md:text-base h-8 md:h-9 w-28 sm:w-48 md:w-64 xl:w-80"
                  name="text"
                  type="text"
                />
              </div>
            </div>
            <div className="w-[40%]  flex justify-end items-center max-md:gap-0 md:gap-3">
              <button
                ref={cartRef}
                onClick={() => {
                  if (!isAuthenticated) setPopUp("login");
                  else navigate("/cart");
                }}
                className="cursor-pointer relative flex items-center max-md:p-1 md:p-2 bg-[#fff] hover:bg-yellow-200 duration-300 ease-in-out rounded-full   scale-100 hover:scale-95"
              >
                <img
                  src="/images/cart.svg"
                  className="w-8 h-8 md:h-9  md:w-9 "
                  alt=""
                />
                {cartItem.length > 0 && (
                  <>
                    <span
                      className="absolute
                    right-2 bottom-2 bg-yellow-500 border border-black px-1 text-xs rounded-full"
                    >
                      {cartItem.length}
                    </span>
                  </>
                )}
              </button>
              <div className="relative" ref={dropdownRef}>
                <button
                  className="cursor-pointer flex items-center  bg-[#fff] hover:bg-yellow-200   duration-300 ease-in-out max-md:p-1 md:p-2 rounded-full scale-100 hover:scale-95 "
                  onClick={() => {
                    if (show) {
                      setAnimating(true);
                      setTimeout(() => {
                        setShow(false);
                        setAnimating(false);
                      }, 300);
                    } else setShow(true);
                  }}
                >
                  <img
                    className="w-8 h-8 md:h-9  md:w-9 fill-[#3d3d3d] p-0.5"
                    src="/images/user.svg"
                    alt=""
                  />
                </button>
                {/* {show && ( */}
                <>
                  <div
                    className={`w-40 text-black text-sm font-semibold rounded-b-lg absolute shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] -left-[60px] max-lg:top-[51px] lg:top-[66px] overflow-hidden user-animation duration-200 flex flex-col ${
                      animating ? "close-animation" : ""
                    } ${!show ? "hidden" : "block"}`}
                  >
                    <button className="bg-[#ffffff] hover:bg-[#f0f0f0] group duration-300 ease-in-out p-2 border-b-[1px]">
                      <a
                        href={
                          isAuthenticated && type === ""
                            ? "/default/dashboard"
                            : `/${type.toLowerCase()}/dashboard`
                        }
                        className="flex items-center justify-center gap-2 overflow-hidden hover:text-black"
                      >
                        <div className=" w-7 h-7 object-cover flex justify-center items-center ">
                          <img
                            className=""
                            src="/images/acc-dashboard.svg"
                            alt="Dashboard"
                          />
                        </div>
                        <p className="w-2/3 text-left">Dashboard</p>
                      </a>
                    </button>
                    <a
                      href="/account"
                      className="bg-[#ffffff] hover:bg-[#f0f0f0] group duration-300 ease-in-out p-2 border-b-[1px] overflow-hidden flex items-center justify-center  gap-2 hover:text-black"
                    >
                      <div className=" w-7 h-7 object-cover flex justify-center items-center ">
                        <img
                          className=""
                          src="/images/adminImages/settings.svg"
                          alt="Affiliates"
                        />
                      </div>
                      <p className="w-2/3 text-left">Accounts</p>
                    </a>
                    <button
                      onClick={() => logout()}
                      className="bg-[#ffffff]  hover:bg-[#f0f0f0] group duration-300 ease-in-out p-2 border-b-[1px] overflow-hidden flex items-center justify-center  gap-2 hover:text-black"
                    >
                      <div className=" w-7 h-7 object-cover flex justify-center items-center ">
                        <img
                          className=""
                          src="/images/adminImages/logout.svg"
                          alt="logout"
                        />
                      </div>
                      <p className="w-2/3 text-left">Logout</p>
                    </button>
                  </div>
                </>
                {/* )} */}
              </div>
            </div>
          </nav>
        )}

        {PopUp === "login" && (
          <>
            <div className="demo1-container flex min-h-full flex-1 flex-col justify-center items-center px-6 py-12 lg:px-8  ">
              <div
                className=" bg-white p-2 border-0 overflow-y-auto rounded-md pop-up-animation"
                ref={popupRef}
              >
                <button
                  className="p-2 bg-white w-full flex justify-end"
                  onClick={() => {
                    setPopUp("");
                    closePopup();
                  }}
                >
                  <svg className="w-5 h-5" viewBox="0 0 384 512">
                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                  </svg>
                </button>
                <Login message={message} />
              </div>
            </div>
          </>
        )}
      </div>

      {/*sidebar*/}
      {!isAuthenticated && (
        <>
          <div
            className={`fixed inset-0  w-64 h-screen bg-white shadow-lg z-50 transform transition-transform duration-300 ${
              isSidebarOpen ? "translate-x-0" : "-translate-x-full"
            }`}
            ref={sideBarRef}
          >
            <button
              onClick={() => {
                setIsSidebarOpen(false);
              }}
              className="w-full flex items-center justify-end p-4"
            >
              <svg
                fill="none"
                className="w-7 h-7"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                id="fi_2732657"
              >
                <path
                  clipRule="evenodd"
                  d="m5.00073 17.5864c-.3905.3906-.39044 1.0237.00012 1.4142s1.02372.3905 1.41421-.0001l5.58524-5.5862 5.5857 5.5857c.3905.3905 1.0237.3905 1.4142 0s.3905-1.0237 0-1.4142l-5.5858-5.5858 5.5854-5.58638c.3904-.39056.3904-1.02372-.0002-1.41421-.3905-.3905-1.0237-.39044-1.4142.00012l-5.5853 5.58627-5.58572-5.58579c-.39052-.39052-1.02369-.39052-1.41421 0-.39053.39053-.39053 1.02369 0 1.41422l5.58593 5.58587z"
                  fill="rgb(0,0,0)"
                  fillRule="evenodd"
                ></path>
              </svg>
            </button>
            <div className=" flex flex-col justify-between ">
              <div className="flex flex-col items-start justify-start gap-2 w-full ">
                <button className="button-sidebar w-full flex justify-start py-2 type1">
                  <span className="font-semibold z-[1] text-gray-700 text-start px-4">
                    Shoemaking
                  </span>
                </button>
                <button className="button-sidebar w-full flex justify-start py-2  type1">
                  <span className="font-semibold z-[1] text-gray-700 text-start  px-4">
                    Care Guide
                  </span>
                </button>
                <button className="button-sidebar w-full flex justify-start py-2 type1">
                  <span className="font-semibold z-[1] text-gray-700 text-start  px-4">
                    Blogs
                  </span>
                </button>
                <button className="button-sidebar w-full flex justify-start py-2 type1">
                  <span className="font-semibold z-[1] text-gray-700 text-start  px-4">
                    Company Info
                  </span>
                </button>
                <button className="button-sidebar w-full flex justify-start py-2 type1">
                  <span className="font-semibold z-[1] text-gray-700 text-start  px-4">
                    Events
                  </span>
                </button>
                <button className="button-sidebar w-full flex justify-start py-2 type1">
                  <span className="font-semibold z-[1] text-gray-700 text-start  px-4">
                    Virtual Tour
                  </span>
                </button>
              </div>

              <div className="bottom-10 w-full  absolute bg-[#ffffff] group hover:bg-[#f0f0f0] duration-300 ease-in-out p-2 border overflow-hidden flex items-center justify-center  gap-2 ">
                {/* Display selected flag */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="w-full "
                >
                  <img
                    src={
                      currencies.find((cur) => cur.code === selectedCurrency)
                        ?.flag
                    }
                    alt={selectedCurrency}
                    style={{ marginRight: "5px" }}
                    className=" w-8 h-5"
                  />
                  <select
                    value={selectedCurrency}
                    onChange={(e) => {
                      setSelectedCurrency(e.target.value);
                      curr(e.target.value);
                    }}
                    className="w-2/3 absolute bg-[#fff] group-hover:bg-[#f0f0f0] duration-300 ease-in-out left-10 text-left text-xs ring-0 focus:outline-none "
                  >
                    {currencies.map((currency) => (
                      <option
                        key={currency.code}
                        className="pop-up-animation"
                        value={currency.code}
                      >
                        {currency.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-grey-800 w-full  relative bg-[#F5F9FF] overflow-x-hidden">
            {/*bottom bar*/}
            <div className="fixed bottom-0 left-0 w-full bg-white px-2.5 pb-2.5  text-[10px]  z-[20] font-bold border lg:hidden ">
              <div className="w-full flex justify-between">
                {sidebarData?.slice(0, 5).map((section) => (
                  <>
                    {section.id === 4 ? (
                      <div
                        key={section.id}
                        onClick={() => {
                          setIsOptionOpen(!isOptionOpen);
                          if (
                            section.id === 0 ||
                            section.id === 1 ||
                            section.id === 2 ||
                            section.id === 3
                          )
                            setActiveId(4);
                        }}
                        className={`w-[63px]  flex flex-col items-center justify-center rounded-full mt-1 ${
                          section.id === 0 ||
                          section.id === 1 ||
                          section.id === 2 ||
                          section.id === 3
                            ? ""
                            : " "
                        }`}
                      >
                        <svg
                          className="w-6 h-6"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                          id="fi_9344210"
                        >
                          <g id="Layer_2" fill="rgb(0,0,0)" data-name="Layer 2">
                            <path d="m27 17h-22a1 1 0 0 1 0-2h22a1 1 0 0 1 0 2z"></path>
                            <path d="m27 10h-22a1 1 0 0 1 0-2h22a1 1 0 0 1 0 2z"></path>
                            <path d="m27 24h-22a1 1 0 0 1 0-2h22a1 1 0 0 1 0 2z"></path>
                          </g>
                        </svg>
                        <p className="">More</p>
                      </div>
                    ) : (
                      <div
                        key={section.id}
                        onClick={() => {
                          navigate(section.href);
                          setActiveId(section.id);
                          setIsOptionOpen(false);
                        }}
                        className={`flex flex-col items-center justify-center p-1.5 w-[63px] 
          ${
            activeId === section.id
              ? " border-[#FFD61F] bg-[#FFF4B5] duration-500 rounded-b-lg "
              : ""
          } 
          ${
            activeId === section.id
              ? "flex flex-col items-center justify-center p-1.5 w-[63px] border-t-[3px]"
              : "border-t-[3px] border-white rounded-x-lg rounded-b-lg"
          }
        `}
                      >
                        <span className={`w-6 h-6 `}>{section.icon}</span>
                        <p
                          className={`${`text-center leading-[11px] mt-1`} ${
                            activeId === section.id
                              ? " text-black"
                              : "text-gray-500"
                          }`}
                        >
                          {section.title}
                        </p>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>

            {/* Bottom Sidebar popup */}
            {isOptionOpen && (
              <div className=" justify-between items-end z-[19] font-bold lg:hidden w-full h-screen admin-popup-nonloggedin ">
                <div className="fixed bottom-[65px] border-2 border-[#FFD61F] right-6 flex items-start justify-center flex-wrap gap-3 p-2 w-[130px] min-h-[125px] bg-white rounded-t-md admin-popup-animation-genie pb-2">
                  {sidebarData?.slice(4).map((section) => (
                    <div
                      className={`flex flex-col items-center justify-center p-1 w-[90px] ${
                        activeId === section.id
                          ? "border-[#FFD61F] border-b-[2px]  duration-500 "
                          : " border-[#f7f7f7] border-b-[2px]"
                      }`}
                      onClick={() => {
                        setActiveId(section.id);
                        setIsOptionOpen(false);
                      }}
                    >
                      <p className="text-center text-xs font-semibold ">
                        {section.title}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Header;
