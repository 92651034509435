import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import secureLocalStorage from "react-secure-storage";

const OrdersPage = () => {
  const location = useLocation();
  const hash = location.hash.substring(1);
  const { email, type, currency } = CheckAuth({ hash });
  let productionStages = JSON.parse(
    secureLocalStorage.getItem("production_stages")
  );
  let Customize = JSON.parse(secureLocalStorage.getItem("Customize"));
  const [filteredStatus, setFilteredStatus] = useState("All");
  const [filterOptionsVisible, setFilterOptionsVisible] = useState(false);
  const [orders, setOrders] = useState([]);
  const filterRef = useRef(null);
  const navigate = useNavigate();

  const slabDiscount = (quantity, price) => {
    let discount = 0;
    if (quantity >= 40 && quantity < 80) discount = 5;
    else if (quantity >= 80 && quantity < 120) discount = 10;
    else if (quantity >= 120 && quantity < 160) discount = 15;
    else if (quantity >= 160 && quantity < 200) discount = 20;
    else if (quantity >= 200 && quantity < 500) discount = 25;
    else if (quantity >= 500) discount = 30;

    const discount_amount = (discount / 100) * price;
    return discount_amount;
  };

  const calculateTotal_WO_GST = (type, data) => {
    let total = 0;
    let sdc = 0;
    let moq = 0;
    let discount = 0;
    let totalDiscount = 0;
    let quantity = 0;
    let price =
      data.Leather.price +
      data.Construction.price +
      data.Edge.price +
      data.Finish.price +
      data.Lining.price +
      data.Sole.price +
      data.Sole_thickness.price +
      data.Welt.price +
      data.Shoelace.price +
      data.Buckles.price +
      data.Eyelets.price +
      (type === "individual" ? 7000 : 0);
    moq = Customize?.find(
      (data) => data["Article_no"] === data.Article_no
    )?.MOQ;
    Object.entries(data?.Size)?.map(([key, val]) => {
      quantity += val;
    });
    if (type !== "individual") {
      discount = quantity <= moq ? 0 : slabDiscount(quantity, price);
    }
    total += price * quantity;
    totalDiscount += discount * quantity;
    const sdcPrice = type === "individual" || quantity <= moq ? 7000 : 0;
    sdc += sdcPrice * quantity;
    if (type === "individual") {
      return {
        "Base Price": total,
      };
    } else {
      return {
        "Base Price": total,
        "Sample Development Charges": sdc,
        "Bulk Order Discount": totalDiscount,
      };
    }
  };

  useEffect(() => {
    if (productionStages && productionStages !== "") {
      const statusClasses = {
        Sourcing: "text-yellow-500",
        "Under Production": "text-blue-500",
        "Out for Delivery": "text-gray-700",
        Delivered: "text-green-500",
      };

      const modified_orders = productionStages.map((order) => {
        const totalPairs = Object.values(order.Products).reduce(
          (sum, product) => {
            return (
              sum +
              Object.values(product.Size).reduce(
                (sizeSum, count) => sizeSum + count,
                0
              )
            );
          },
          0
        );

        const invoiceValue = Object.values(order.Products).reduce(
          (total, data) => {
            const productTotal = Object.entries(
              calculateTotal_WO_GST(type, data)
            ).reduce(
              (sum, [key, val]) =>
                key === "Bulk Order Discount" ? sum - val : sum + val,
              0
            );
            return total + productTotal;
          },
          0
        );

        return {
          id: order.Order_id,
          totalPairs,
          invoiceValue: `Rs. ${invoiceValue?.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
          status: order.Stage === "Sourcing" ? "Sourcing" : "Under Production",
          statusClass: statusClasses[order.Stage] || "text-gray-500",
          estimatedDelivery: new Date(order.Deadline).toLocaleDateString(
            "en-IN",
            {
              day: "numeric",
              month: "long",
              year: "numeric",
            }
          ),
        };
      });
      setOrders(modified_orders);
    }
  }, [email, JSON.stringify(productionStages)]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setFilterOptionsVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to filter orders based on status
  const filteredOrders = Array.isArray(orders)
    ? orders.filter((order) =>
        filteredStatus === "All" ? true : order.status === filteredStatus
      )
    : [];

  return (
    <div className="min-h-screen flex flex-col items-center py-10 px-5">
      {/* Header Section */}
      <div className="flex items-center justify-between w-full  mb-6">
        <h1 className="text-2xl font-semibold text-black">
          Select an order to continue
        </h1>
        <div className="relative flex items-center">
          <div className="text-xs font-semibold text-[#212121] mr-2">
            Filter:
          </div>
          <button
            className="flex w-40 items-center justify-between text-[#313131] font-semibold text-xs"
            onClick={() => setFilterOptionsVisible((prev) => !prev)}
          >
            {filteredStatus === "All" ? "All" : `${filteredStatus}`}
            <svg
              className="ml-1 w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>

          {filterOptionsVisible && (
            <div
              ref={filterRef}
              className="absolute top-[11px] right-0 border-[1px]  bg-white overflow-hidden mt-2 w-44 px-2 z-50 transition-opacity duration-500 ease-in-out fadein-animation"
            >
              <ul className="py-1 text-xs text-gray-600">
                {[
                  "All",
                  "Sourcing",
                  "Under Production",
                  "Out for Delivery",
                  "Delivered",
                ].map((status) => (
                  <li
                    key={status}
                    onClick={() => {
                      setFilteredStatus(status);
                      setFilterOptionsVisible(false);
                    }}
                    className={`px-2 py-2 hover:text-[#212121] font-medium duration-200 ease-in-out cursor-pointer ${
                      filteredStatus === status
                        ? "text-[#212121]"
                        : "text-[#616161]"
                    }`}
                  >
                    {status}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* Orders Table */}
      <div className="overflow-x-auto w-full">
        <table className="w-full border-collapse border border-gray-300 rounded-lg bg-white shadow">
          <thead className="w-full">
            <tr className="bg-yellow-400 font-semibold text-white w-full">
              <th className="border-[1px] border-gray-200 px-4 py-2 text-left font-semibold w-3/12">
                Order ID
              </th>
              <th className="border-[1px] border-gray-200 px-4 py-2 text-left font-semibold w-2/12">
                Total Pairs
              </th>
              <th className="border-[1px] border-gray-200 px-4 py-2 text-left font-semibold w-2/12">
                Invoice Value
              </th>
              <th className="border-[1px] border-gray-200 px-4 py-2 text-left font-semibold w-2/12">
                Status
              </th>
              <th className="border-[1px] border-gray-200 px-4 py-2 text-left font-semibold w-3/12">
                Estimated Delivery
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order, index) => (
              <tr
                onClick={() => navigate(String(order.id).replaceAll("/", "_"))}
                className={`${
                  index % 2 === 0 ? "bg-[#fff]" : "bg-[#f8f8f8]"
                } hover:bg-[#f1f1f1] cursor-pointer`}
              >
                <td className="border-[1px] border-gray-200 px-4 py-2 text-black">
                  {order.id}
                </td>
                <td className="border-[1px] border-gray-200 px-4 py-2 text-black">
                  {order.totalPairs} Pairs
                </td>
                <td className="border-[1px] border-gray-200 px-4 py-2 text-black">
                  {order.invoiceValue}
                </td>
                <td
                  className={`border-[1px] border-gray-200 px-4 py-2 font-medium ${order.statusClass}`}
                >
                  {order.status}
                </td>
                <td className="border-[1px] border-gray-200 px-4 py-2 text-black">
                  {order.estimatedDelivery}
                </td>
              </tr>
            ))}
            {filteredOrders.length === 0 && (
              <tr>
                <td
                  colSpan="5"
                  className="border border-gray-300 px-4 py-2 text-center text-gray-500"
                >
                  No orders found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrdersPage;
