import React, { useState } from "react";
import { LuArrowUpRight } from "react-icons/lu";
import { IoClose } from "react-icons/io5";

function OpenReplacement({ onClose, selectedImage, fileInputRef, setRequestSent }) {
  const [selectOther, setSelectOther] = useState(false);
  const Issues_API = [
    {
      id: 0,
      reason: "Received a defective product or damage Product.",
    },
    {
      id: 1,
      reason: "Persistent odor or difficulty cleaning stains",
    },
    {
      id: 2,
      reason: "Broken or faulty zippers",
    },
  ];
  return (
    <div className="popup-container">
      <div className="p-3 rounded-2xl  bg-white w-full md:max-w-[420px] pop-up-animation mx-2 relative">
        <h2 className="text-[15px] font-semibold border-b flex items-center justify-between pb-2 px-2">
          Replacement Request
          <span
            onClick={() => onClose()}
            className="bg-[#ffffff] hover:bg-red-300 border-[1px] border-zinc-400 hover:border-red-500 shadow-sm hover:shadow p-1 rounded-full text-black hover:scale-95 duration-300 cursor-pointer absolute -right-2 -top-2 z-10"
          >
            <IoClose className="text-xl" />
          </span>
        </h2>

        {/* Replacement issues */}
        <div className="flex flex-wrap gap-2 items-center mt-3">
          {Issues_API.map((data) => (
            <button
              key={data.id}
              className="bg-sky-50 px-2 py-1 rounded-full text-xs"
            >
              {data.reason}
            </button>
          ))}
          <button
            onClick={() => setSelectOther(!selectOther)}
            className="bg-sky-50 px-2 py-1 rounded-full text-xs"
          >
            other
          </button>
        </div>
        <div
          className={`duration-500 transition-all ease-in-out overflow-hidden p-0.5`}
        >
          <textarea
            name=""
            placeholder="Write here…"
            className={`border w-full rounded-lg placeholder:text-sm p-2 text-sm outline-none hover:ring-1 hover:ring-[#FFD61F] duration-500 mt-2 transition-all ease-in-out overflow-hidden ${
              selectOther ? "h-[80px] opacity-100" : "hidden h-0 opacity-0"
            }`}
            id=""
          ></textarea>
          {/* Review images */}
          <div
            className={`overflow-x-scroll no-scrollbar shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-lg flex items-center gap-2.5 ${
              selectOther ? "mt-0" : "mt-2"
            }`}
          >
            {selectedImage.length > 0 ? (
              selectedImage.map((image, index) => (
                <div className="p-1">
                  <img
                    key={index}
                    src={image}
                    className="w-20 h-20 object-cover rounded-lg"
                    alt={`Image not found`}
                  />
                </div>
              ))
            ) : (
              <></>
            )}
            <div
              onClick={() => fileInputRef.current.click()}
              className={`flex flex-col cursor-pointer p-1 ${
                selectedImage.length > 0
                  ? "items-start w-fit"
                  : " items-center w-full"
              }`}
            >
              <div className="flex flex-col items-center mx-auto">
                <span className="group-hover:scale-95 duration-300">
                  <svg
                    className="w-11 h-11 fill-gray-400 "
                    id="fi_8191595"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      id="image-plus"
                      d="m21.75 11v6a3.383 3.383 0 0 1 -3.75 3.75h-12a3.383 3.383 0 0 1 -3.75-3.75v-10a3.383 3.383 0 0 1 3.75-3.75h7a.75.75 0 0 1 0 1.5h-7c-1.577 0-2.25.673-2.25 2.25v9.25l2.54-2.54a1.008 1.008 0 0 1 1.42 0l.94.94a.5.5 0 0 0 .7 0l4.94-4.94a1.008 1.008 0 0 1 1.42 0l4.54 4.54v-3.25a.75.75 0 0 1 1.5 0zm-13.757-3.25a1.253 1.253 0 1 0 .007 0zm9.007-2h1.25v1.25a.75.75 0 0 0 1.5 0v-1.25h1.25a.75.75 0 0 0 0-1.5h-1.25v-1.25a.75.75 0 0 0 -1.5 0v1.25h-1.25a.75.75 0 0 0 0 1.5z"
                    ></path>
                  </svg>
                </span>
                <span className="text-[13px] font-semibold text-gray-500">
                  {selectedImage.length > 0 ? (
                    <>Add More</>
                  ) : (
                    <>Click here to upload</>
                  )}
                </span>
              </div>
              <input
                type="file"
                accept="image/*"
                //   ref={fileInputRef}
                className="hidden w-[2px]"
                // onChange={handleFileChange} // Handle file selection
              />
            </div>
          </div>
        </div>
        <button
          onClick={() => {onClose(); setRequestSent()}}
          className="bg-[#FFD61F] duration-300 px-3 py-1.5 rounded-full text-xs xl:text-[13px] font-semibold text-[#000000] flex items-center justify-center gap-1 w-[100%] mx-auto mt-4"
        >
          Submit Request
          <span className="text-lg">
            <LuArrowUpRight />
          </span>
        </button>
      </div>
    </div>
  );
}

export default OpenReplacement;
