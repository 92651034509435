import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import SideBar from "../../components/Sidebar/SideBar";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import ProductListing from "../../admin/ProductListing/ProductListing";
import Upper from "../../admin/MaterialCost/Upper";
import Sole from "../../admin/MaterialCost/Sole";
import Accessories from "../../admin/MaterialCost/Accessories.jsx";
import RequestForQuote from "../../admin/Logistics/RequestForQuote.jsx";
import LogisticsPayment from "../../admin/Logistics/LogisticsPayment";
import Enquiry from "../../components/Connect/AIPage1.jsx";
import ProductionStages from "../Production Stage/ProductionStage.jsx";
import Orderhistory from "../../components/OrderHistory/Orderhistory.jsx";
import AdminLedger from "../../admin/OrderHistory/AdminLedger.jsx";
import ProductionStepper from "../Production Stage/Production-stepper.jsx";
import EventScheduler from "../../admin/Eventscheduler/EventScheduler.jsx";

function Dashboard() {
  const location = useLocation();
  const hash = location.hash.substring(1);
  const { isAuthenticated, type } = CheckAuth({ hash });
  const [activeImage, setActiveImage] = useState(1);
  const [textVisible, setTextVisible] = useState(true);
  const [popUp, setPopUp] = useState("");
  const navigate = useNavigate();
  const { user } = useParams();

  useEffect(() => {
    setTextVisible(false);
    const timer = setTimeout(() => {
      setTextVisible(true);
    }, 200); // Match the duration of the fade-out (adjust as needed)

    return () => clearTimeout(timer);
  }, [activeImage]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImage((prev) => (prev === 3 ? 1 : prev + 1));
    }, 6000);
    return () => clearInterval(interval);
  }, []);

  const handleImageClick = (imageIndex) => {
    setActiveImage(imageIndex);
  };

  const textForImage = {
    1: "Digitally create your shoe by choosing each component's material and build to perfectly match your price preference.",
    2: "Connect your web-store to our database and fulfil orders for your Brand directly from BUWCH's Inventory.",
    3: "Virtually review the manufacturing of your specific order through real-time tracking, videos and feedback of each stage.",
  };

  const titleForImage = {
    1: "CUSTOMIZE 3D",
    2: "ECOMMERCE API",
    3: "PRODUCTION STAGES",
  };

  const handleMenuSelect = (menuItem, subMenuItem) => {
    const path =
      menuItem.toLowerCase() === subMenuItem.toLowerCase()
        ? `/${user}/dashboard/${menuItem.toLowerCase() || ""}`.trim()
        : `/${user}/dashboard/${menuItem.toLowerCase()}/${
            subMenuItem.toLowerCase() || ""
          }`
            .trim()
            .toLocaleLowerCase();
    navigate(path);
  };

  return (
    <div
      className="background-glass bg-gray-200 min-h-screen"
      // style={{ backgroundImage: "url('/images/34.png')" }}
    >
      <Header popupState={popUp} closePopup={() => setPopUp("")} />
      <div className="after-header">
        <div className="after-subheader  flex items-start justify-between relative  overflow-x-hidden">
          <SideBar
            onSelectMenuItem={(item, subItem) => {
              handleMenuSelect(item, subItem);
            }}
          />

          {/* Main Content */}
          <div className="w-full lg:min-w-[70%] 2xl:min-w-[70%]  max-md:p-2 max-md:pt-0 md:p-4 ">
            <div className="w-full border transparent bg-[#ffffff]  rounded-xl shadow-md max-md:p-2 md:p-4 min-h-[calc(100vh-7rem)] lg:h-[calc(100vh-7rem)] overflow-y-scroll no-scrollbar">
              {type !== "" ? (
                <>
                  <Routes>
                    <Route path="material cost/upper" element={<Upper />} />
                    <Route path="material cost/sole" element={<Sole />} />
                    <Route
                      path="material cost/accessories"
                      element={<Accessories />}
                    />
                    <Route
                      path="product listing"
                      element={<ProductListing />}
                    />
                    <Route
                      path="shipping/request-for-quote"
                      element={<RequestForQuote />}
                    />
                    <Route
                      path="shipping/logistics payment"
                      element={<LogisticsPayment />}
                    />
                    {/* Fallback or default route */}
                    <Route
                      path="*"
                      element={
                        <div className="flex justify-center items-center h-full w-full overflow-hidden">
                          <img src="/images/adminImages/UC.webp" />
                        </div>
                      }
                    />
                    <Route
                      path="order history/delivered shipment"
                      element={<Orderhistory />}
                    />

                    <Route
                      path="order history/ledger & invoice"
                      element={<AdminLedger />}
                    />
                    <Route
                      path="production stages"
                      element={<ProductionStages />}
                    />
                    <Route path="connect/enquiry" element={<Enquiry />} />
                    <Route
                      path="production stages/:orderId"
                      element={<ProductionStepper />}
                    /><Route
                      path="/event scheduler"
                      element={<EventScheduler />}
                    />
                  </Routes>
                </>
              ) : (
                <>
                  <div className="flex justify-center items-center h-full overflow-hidden">
                    <div className="flex flex-col justify-center items-center h-full overflow-hidden md:scale-90 2xl:scale-100">
                      <div
                        className={`max-w-2xl ${
                          textVisible ? "fade-in " : "fade-out "
                        }`}
                      >
                        <p className="text-lg md:text-2xl text-yellow-400 text-center font-bold pb-2">
                          {titleForImage[activeImage]}
                        </p>
                        <p className="text-sm md:text-lg font-semibold text-center ">
                          {textForImage[activeImage]}
                        </p>
                      </div>
                      <div className="bg-white border-b-8 border-yellow-400 w-full overflow-hidden 2xl:px-20">
                        <div className="object-cover w-full h-96 md:h-full flex justify-center gap-x-10 overflow-hidden">
                          {/* image 1 */}
                          <img
                            className={`object-cover  w-48 xl:w-64 max-md:left-48 max-md:relative transition-all duration-500 ease-in-out cursor-pointer ${
                              activeImage === 1
                                ? "translate-y-12 opacity-100 z-[5] "
                                : "translate-y-40 opacity-50 md:opacity-40 z-0 "
                            }`}
                            src="/images/1.png"
                            alt=""
                            onClick={() => handleImageClick(1)}
                          />
                          {/* image 2 */}
                          <img
                            className={`object-cover w-48 xl:w-64 max-md:relative transition-all duration-500 ease-in-out cursor-pointer ${
                              activeImage === 2
                                ? "translate-y-12 opacity-100  z-[5]"
                                : "translate-y-40 md:opacity-40 z-10"
                            }`}
                            src="/images/2.png"
                            alt=""
                            onClick={() => handleImageClick(2)}
                          />
                          {/* image 3 */}
                          <img
                            className={`object-cover w-48 xl:w-64 max-md:right-48 max-md:relative transition-all duration-500 ease-in-out cursor-pointer ${
                              activeImage === 3
                                ? "translate-y-12 opacity-100   z-[5]"
                                : "translate-y-40 opacity-50 md:opacity-40  z-0"
                            }`}
                            src="/images/3.png"
                            alt=""
                            onClick={() => handleImageClick(3)}
                          />
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          navigate("/register");
                        }}
                        className="button-register max-md:mt-6 md:mt-10 border-4 border-gray-300 hover:border-yellow-200 duration-300 max-md:scale-75"
                      >
                        <span className="button__icon-wrapper">
                          <svg
                            viewBox="0 0 14 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="button__icon-svg"
                            width="10"
                          >
                            <path
                              d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                              fill="currentColor"
                            ></path>
                          </svg>

                          <svg
                            viewBox="0 0 14 15"
                            fill="none"
                            width="10"
                            xmlns="http://www.w3.org/2000/svg"
                            className="button__icon-svg button__icon-svg--copy"
                          >
                            <path
                              d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                        Get Started
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
