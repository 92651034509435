import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "react-feather";
import Header from "../../components/Header/Header.jsx";
import AIPage1 from "../../components/Connect/AIPage1.jsx";
import AIPage2 from "../../components/Connect/AIPage2.jsx";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth.jsx";
import "./Home.css";
function Home() {
  const location = useLocation();
  const hash = location.hash.substring(1);
  const { isAuthenticated } = CheckAuth({ hash });
  const [curr, setCurr] = useState(0);
  const [openChat, setOpenChat] = useState(false);
  const [openOption, setOpenOption] = useState(true);

  const slides = [
    {
      id: 0,
      vid: "./videos/vd.mp4",
    },
    {
      id: 1,
      vid: "./videos/vd2.mp4",
    },
    {
      id: 2,
      vid: "./videos/vd3.mp4",
    },
    {
      id: 3,
      vid: "./videos/vd4.mp4",
    },
    {
      id: 4,
      vid: "./videos/vd5.mp4",
    },
    {
      id: 5,
      vid: "./videos/vd6.mp4",
    },
    {
      id: 6,
      vid: "./videos/vd7.mp4",
    },
  ];

  const slides2 = [
    {
      id: 0,
      vid: "./videos/raw vid/vd.mp4",
    },
    {
      id: 1,
      vid: "./videos/raw vid/vd2.mp4",
    },
    {
      id: 2,
      vid: "./videos/raw vid/vd3.mp4",
    },
    {
      id: 3,
      vid: "./videos/raw vid/vd4.mp4",
    },
    // {
    //   id: 4,
    //   vid: "./videos/raw vid/vd5.mp4",
    // },
    {
      id: 5,
      vid: "./videos/raw vid/vd6.mp4",
    },
    {
      id: 6,
      vid: "./videos/raw vid/vd7.mp4",
    },
  ];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurr((prev) => (prev + 1) % slides.length);
    }, 5000); // Change slide every 3 seconds

    return () => clearInterval(slideInterval);
  }, [slides.length]);

  return (
    <>
      <Header />
      <div className="relative">
        <div className="w-full h-screen bg-white relative flex items-center justify-center px-4">
          {/* Blurry Gradient Background */}
          <div className="absolute h-[250px] inset-0 bg-gradient-to-r from-yellow-100 via-red-100 to-purple-100 blur-2xl opacity-50 z-0"></div>

          {/* Content Container */}
          <div className="w-full flex justify-center items-center flex-col text-center space-y-4 relative z-10">
            {/* Responsive Image */}
            <img
              src="./images/underconstructions.svg"
              className="w-[90%] max-w-[500px]"
              alt="Under Construction"
            />
            {/* Heading */}
            <h2 className="text-3xl md:text-3xl font-black text-gray-700">
              The Future of Shoe Sourcing is Under Construction.
            </h2>
            {/* Description */}
            <p className="text-base md:text-lg w-[90%] md:w-[60%]">
              We're not just building a website; we're crafting an experience.
              An exceptional platform designed to revolutionize how you source
              premium Indian leather footwear. This level of innovation takes
              time, but the wait will be worth it.
            </p>
          </div>
        </div>

        {/* Enquire */}
        <div className="sticky left-[90%] xl:left-[93%] bottom-12 w-fit hidden lg:block">
          <button
            onClick={() => setOpenChat(!openChat)}
            className="bg-[#FFD61F] p-1 rounded-full group z-[1000] outline-0  h-[65px] w-[65px] hover:scale-105 flex items-center justify-center aspect-square cursor-pointer transition-transform active:scale-90 bg-[linear-gradient(45deg,#FFD61F,#FFD61F)] duration-200 "
          >
            <svg
              className="w-12 h-12 animate-pulse"
              id="fi_17062495"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
            >
              <path d="m15.05 9.59.33-1.88c.13-.74-.07-1.48-.55-2.06s-1.18-.9-1.93-.9h-1.79c-.75 0-1.45.33-1.93.9s-.68 1.32-.55 2.06l.33 1.88c.16.9.79 1.62 1.66 1.9.45.15.92.22 1.38.22s.93-.07 1.39-.22c.87-.28 1.5-1.01 1.66-1.9zm-4.21 1.19c-.6-.19-1.04-.7-1.15-1.32l-.33-1.88c-.09-.52.05-1.04.39-1.44s.83-.63 1.36-.63h1.79c.52 0 1.02.23 1.36.63s.48.93.39 1.44l-.33 1.88c-.11.62-.55 1.13-1.15 1.32-.76.24-1.56.24-2.31 0z"></path>
              <path d="m11.36 17.22c-.09.36-.27.93-.48 1.59h1c-.26-.77-.43-1.27-.51-1.59z"></path>
              <path d="m10.77 12.32 1.19 1.63 1.19-1.64c-.06 0-.12-.02-.19-.02h-1.92c-.09 0-.18.02-.27.03z"></path>
              <path d="m18.99 20.57v-3.73c0-1.35-.8-2.57-2.03-3.11l-2.55-1.12c-.11-.05-.24-.08-.35-.11l.42 2.82s-.01.09-.05.12c-.02.02-.05.02-.07.02s-.04 0-.05-.01l-2.35-1.13-2.26 1.13s-.04.01-.06.01c-.03 0-.05 0-.07-.02-.04-.03-.06-.07-.05-.12l.34-2.8c-.09.03-.18.05-.27.09l-2.55 1.12c-1.24.54-2.03 1.77-2.03 3.11v3.73h-2.01v.75h18v-.75zm-5.39-4.14h.76v.68h-.76zm0 1.13h.76v2.75h-.76zm-2.71-.91h.99l1.3 3.66h-.83l-.3-.84h-1.34l-.29.84h-.78l1.24-3.66z"></path>
              <path d="m7.07 9.01h.64s.37 0 .37 0v-1.66c0-2.16 1.76-3.91 3.91-3.91s3.92 1.76 3.92 3.91v1.66h.37s.64 0 .64 0c.33 0 .6-.27.6-.6v-1.47c0-.33-.27-.6-.6-.6h-.38c-.46-2.09-2.32-3.65-4.55-3.65s-4.08 1.57-4.55 3.65h-.38c-.33 0-.6.27-.6.6v1.47c0 .33.27.6.6.6z"></path>
            </svg>
          </button>

          {/* Open bar */}
          {openChat && (
            <div
              className={`absolute bottom-[80px] min-h-[700px] max-w-[420px] right-0 overflow-hidden rounded-xl shadow-[rgba(0,_0,_0,_0.24)_0px_5px_20px] fadeInEquire-animation `}
            >
              <div className="bg-[#ffffff] border flex flex-col justify-between rounded-t-xl overflow-hidden">
                {/* Enquire */}
                <>
                  {/* AI Chat */}
                  <div className="">
                    <AIPage1 />
                  </div>
                </>
                <>
                  <div
                    className={`absolute z-40 top-0 left-0 ${
                      openOption ? "translate-x-[110%]" : "translate-x-[0%]"
                    } duration-300`}
                  >
                    {" "}
                    <AIPage2 />
                  </div>
                </>
              </div>

              <div className="flex justify-between p-2 border-t-2 border-[#FFD61F] sticky bottom-0 z-[10] left-0 w-full bg-[#F9F9F9] rounded-b-xl">
                <button
                  onClick={() => {
                    setOpenOption(true);
                  }}
                  className={`border-[2px] rounded-lg w-[50%] flex flex-col items-center justify-center cursor-pointer duration-200 py-1 ${
                    openOption
                      ? "border-[#FFD61F] bg-[#FFD61F] scale-95 duration-300"
                      : "border-zinc-300 fill-zinc-600 text-zinc-600"
                  }`}
                >
                  <svg
                    className="w-9 h-9"
                    id="fi_17062495"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 1"
                  >
                    <path d="m15.05 9.59.33-1.88c.13-.74-.07-1.48-.55-2.06s-1.18-.9-1.93-.9h-1.79c-.75 0-1.45.33-1.93.9s-.68 1.32-.55 2.06l.33 1.88c.16.9.79 1.62 1.66 1.9.45.15.92.22 1.38.22s.93-.07 1.39-.22c.87-.28 1.5-1.01 1.66-1.9zm-4.21 1.19c-.6-.19-1.04-.7-1.15-1.32l-.33-1.88c-.09-.52.05-1.04.39-1.44s.83-.63 1.36-.63h1.79c.52 0 1.02.23 1.36.63s.48.93.39 1.44l-.33 1.88c-.11.62-.55 1.13-1.15 1.32-.76.24-1.56.24-2.31 0z"></path>
                    <path d="m11.36 17.22c-.09.36-.27.93-.48 1.59h1c-.26-.77-.43-1.27-.51-1.59z"></path>
                    <path d="m10.77 12.32 1.19 1.63 1.19-1.64c-.06 0-.12-.02-.19-.02h-1.92c-.09 0-.18.02-.27.03z"></path>
                    <path d="m18.99 20.57v-3.73c0-1.35-.8-2.57-2.03-3.11l-2.55-1.12c-.11-.05-.24-.08-.35-.11l.42 2.82s-.01.09-.05.12c-.02.02-.05.02-.07.02s-.04 0-.05-.01l-2.35-1.13-2.26 1.13s-.04.01-.06.01c-.03 0-.05 0-.07-.02-.04-.03-.06-.07-.05-.12l.34-2.8c-.09.03-.18.05-.27.09l-2.55 1.12c-1.24.54-2.03 1.77-2.03 3.11v3.73h-2.01v.75h18v-.75zm-5.39-4.14h.76v.68h-.76zm0 1.13h.76v2.75h-.76zm-2.71-.91h.99l1.3 3.66h-.83l-.3-.84h-1.34l-.29.84h-.78l1.24-3.66z"></path>
                    <path d="m7.07 9.01h.64s.37 0 .37 0v-1.66c0-2.16 1.76-3.91 3.91-3.91s3.92 1.76 3.92 3.91v1.66h.37s.64 0 .64 0c.33 0 .6-.27.6-.6v-1.47c0-.33-.27-.6-.6-.6h-.38c-.46-2.09-2.32-3.65-4.55-3.65s-4.08 1.57-4.55 3.65h-.38c-.33 0-.6.27-.6.6v1.47c0 .33.27.6.6.6z"></path>
                  </svg>
                  <span className="text-[12px] font-semibold text-black">
                    AI Chat
                  </span>
                </button>

                <button
                  onClick={() => {
                    setOpenOption(false);
                  }}
                  className={`border-[2px] rounded-lg w-[50%] flex flex-col items-center justify-center cursor-pointer duration-200 py-1 ${
                    openOption
                      ? "border-zinc-300 fill-zinc-600 text-zinc-600"
                      : "border-[#FFD61F] bg-[#FFD61F] scale-95 duration-300 "
                  }`}
                >
                  <svg
                    className="w-7 h-7"
                    version="1.1"
                    id="fi_471664"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsHlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                  >
                    <g>
                      <g>
                        <path
                          d="M256,0C114.509,0,0,114.496,0,256c0,141.489,114.496,256,256,256c141.491,0,256-114.496,256-256
			C512,114.509,397.504,0,256,0z M256,476.279c-121.462,0-220.279-98.816-220.279-220.279S134.538,35.721,256,35.721
			c121.463,0,220.279,98.816,220.279,220.279S377.463,476.279,256,476.279z"
                        ></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M248.425,323.924c-14.153,0-25.61,11.794-25.61,25.946c0,13.817,11.12,25.948,25.61,25.948
			c14.49,0,25.946-12.131,25.946-25.948C274.371,335.718,262.577,323.924,248.425,323.924z"
                        ></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M252.805,127.469c-45.492,0-66.384,26.959-66.384,45.155c0,13.142,11.12,19.208,20.218,19.208
			c18.197,0,10.784-25.948,45.155-25.948c16.848,0,30.328,7.414,30.328,22.915c0,18.196-18.871,28.642-29.991,38.077
			c-9.773,8.423-22.577,22.24-22.577,51.22c0,17.522,4.718,22.577,18.533,22.577c16.511,0,19.881-7.413,19.881-13.817
			c0-17.522,0.337-27.631,18.871-42.121c9.098-7.076,37.74-29.991,37.74-61.666S295.937,127.469,252.805,127.469z"
                        ></path>
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                  <span className="text-[12px] font-semibold text-black">
                    Support
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Home;
