import React from "react";
import Header from "../../components/Header/Header";
import { TbPointFilled } from "react-icons/tb";

function ReplacementRefund() {
  return (
    <>
      <Header />
      <div className="after-header pb-5 max-w-[1600px] mx-auto">
        <div className="w-[90%] mx-auto">
          <div className="w-full flex flex-wrap justify-start mt-4">
            <div className="w-full p-4">
              <div className="text-[#212121] font-medium">
                <p className="text-center font-semibold text-2xl md:text-3xl  text-yellow-500">
                  Replacement & Refund
                </p>
                <p className="mt-4 text-sm">
                  We strive to delight each one of our valued customers with
                  exceptional service. We have a fair return, replacement and
                  refund policy that protects the bona fide interests of both
                  the Clients as well as all the goods and service providers
                  down the supply-chain.
                  <br />
                  <br />
                  Please read the below terms and conditions carefully since we
                  have a well-balanced and documented policy that protects your
                  purchase and also protects us from customers who intend to
                  abuse returns policy. Our Business users are not eligible to
                  claim refunds or replacements of delivered shipments under any
                  other circumstances. Returning or exchanging for reasons other
                  than manufacturing defects is a privilege offered to the
                  Individual customers only. BUWCH Lifestyles and Management Pvt
                  Ltd reserves the right to blacklist any customers that are
                  found to abuse returns policy.
                </p>
                <p className="mt-3 text-sm">
                  Generate Return Request:{" "}
                  <span className="text-blue-500 hover:underline">
                    <a href="#">click here</a>
                  </span>
                </p>
                <p className="mt-3 text-sm">
                  Loyalty Points Balance:{" "}
                  <span className="text-blue-500 hover:underline">
                    <a href="#">click here</a>
                  </span>
                </p>
                <p className="mt-3 text-sm">
                  Period of Return Initiation: 15 days without exception
                </p>
                <p className="mt-3 text-sm">
                  Absolutely no refunds or replacements on customized,
                  made-to-order or personalized items.
                </p>
                <p className="text-sm  tracking-wide mt-4 pl-4">
                  <ol class="list-decimal">
                    <li className="mt-3">
                      Only items in perfect & sellable condition that show no
                      signs of wear and tear, with original packaging, tags and
                      accessories can be returned.
                    </li>
                    <li className="mt-3">
                      In the event of Loyalty Points in the User’s Wallet being
                      used to pay the whole or a part of the Invoice amount, the
                      same will NOT be credited back to the Wallet post the
                      return of the product. Only the cash amount paid by the
                      User from their Bank accounts/credit card will be
                      refunded.
                    </li>
                    <li className="mt-3">
                      For shoes, please ensure that you try on products on
                      carpeted surfaces to avoid scratching the soles. Scratched
                      soles are non-returnable.
                    </li>
                    <li className="mt-3">
                      Please test the fitting of shoes without wrinkling the
                      leather; especially the toe-line. Wrinkled products are
                      non-returnable.
                    </li>
                    <li className="mt-3">
                      Used and worn products cannot be returned under any
                      circumstances.
                    </li>
                    <li className="mt-3">
                      Items can also be returned at our experience centre in
                      Greater Noida, U.P. (India), in which case no charges will
                      be liable on the transportation from the User’s address to
                      the Factory.
                    </li>
                    <li className="mt-3">
                      No returns and refunds are applicable on promotional or
                      fire-sale items.
                    </li>
                    <li className="mt-3">
                      The natural beauty of leather means that no two pieces of
                      leather are exactly alike. As such, natural markings,
                      wrinkles and grain variations are not considered defects;
                      and will not be considered as a valid reason for
                      return/replacement.
                    </li>
                    <li className="mt-3">
                      Products bought online can have a subtle hue, shine and
                      colour difference between the website photographs/videos
                      (or 3D renders) and the actual physical product. This is
                      also due to varied screen settings like brightness,
                      saturation, inherent colour rendering and light/shadow
                      settings on our virtual reality engine. This cannot be
                      considered as a manufacturing defect and a valid reason
                      for return/replacement.
                    </li>
                    <li className="mt-3">
                      Since some of our most premium products are handmade and
                      handcrafted, each piece is slightly different from the
                      other. This is the natural characteristic of the artisanal
                      properties of shoes and cannot be considered a defect.
                      Please understand that artisanal products cannot be
                      “exact” each time - since these are not end-to-end mass
                      produced with machines. Although some hardware is used for
                      the lasting and pattern process, each shoe is primarily
                      handmade by an artisan - and this uniqueness is what gives
                      each product the soul it deserves.
                    </li>
                    <li className="mt-3">
                      If the product is not received in good condition for
                      reasons attributable to the logistics providers, Users are
                      entitled to request return of the product, at no cost to
                      customers. Returns of items that are faulty or in poor
                      condition shall not be considered a right of withdrawal.
                    </li>
                    <li className="mt-3">
                      Products that are customized, made-to-order, or
                      personalized are not eligible for return, replacement, or
                      refund under any circumstances Except for Manufacturing
                      defects.
                    </li>
                    <li className="mt-3">
                      Business Users may initiate returns strictly in cases
                      where manufacturing defects or raw material quality issues
                      are substantiated. Upon approval, a Credit Note equivalent
                      to the refund amount shall be issued via the GST portal,
                      in compliance with applicable tax regulations.
                    </li>
                    <li className="mt-3">
                      Business Users may opt for third-party quality inspections
                      at their own expense. Physical inspections of products
                      prior to dispatch shall be conducted exclusively at the
                      factory premises of BUWCH Lifestyles and Management Pvt
                      Ltd.
                    </li>
                    <li className="mt-3">
                      In cases where the Customer or their authorized
                      representative opts out of a physical quality inspection,
                      they must formally notify BUWCH Lifestyles and Management
                      Pvt Ltd. via email at{" "}
                      <span className="text-blue-500 hover:underline">
                        <a href="mailto:support@buwch.com">support@buwch.com</a>
                      </span>{" "}
                      after receiving an update on their order status through
                      the PRODUCTION STAGES portal.
                    </li>
                    <li className="mt-3">
                      The company issues to the Customer with high-resolution
                      images and video footage of the selected products, chosen
                      through a randomized quality verification process. Any
                      approval provided by the Customer based on these materials
                      shall be deemed final and binding, thereby waiving any
                      subsequent claims for return, replacement, or refund.
                    </li>
                    <li className="mt-3">
                      Customers are entitled to a one-time complimentary size
                      exchange per pair of shoes. To minimize sizing
                      discrepancies, Customers are required to utilize the
                      size-conversion tools available on the Company’s website
                      before confirming their order.
                    </li>
                  </ol>
                </p>
                <h6 className="font-bold  mt-4">
                  Any returned item received by us that does not meet the
                  above-mentioned conditions will not be accepted and will be
                  returned to the customer at their expense. No amount will be
                  reimbursed in that case.
                </h6>
                <h6 className="font-bold  mt-4">
                  Anti Money-Laundering Policy
                </h6>
                <p className="text-sm  tracking-wide mt-4 pl-4">
                  <ol class="list-decimal">
                    <li className="mt-3">
                      Purchases made with Cash-on-delivery facility can only be
                      refunded in a bank account where the name of the customer
                      on the invoice and the name on the bank account matches.
                      This is to comply with anti-money-laundering regulations.
                    </li>
                    <li className="mt-3">
                      Under no circumstances can the refund be processed in a
                      different name, to a different person or to another
                      person’s bank account or credit card.
                    </li>
                    <li className="mt-3">
                      A refund may only be made in the same currency and
                      country/region as the original purchase.
                    </li>
                    <li className="mt-3">
                      Where possible, and at the discretion of BUWCH Lifestyles
                      and Management Pvt Ltd, we will try to reverse any refunds
                      to the same Bank account/credit card using which the
                      original purchase was made.
                    </li>
                    <li className="mt-3">
                      Refund will be initiated in 1 business day and will be
                      credited in the original mode-of-payment of the User
                      subject to settlement timelines of the payment
                      provider/gateway.
                    </li>
                  </ol>
                </p>
                <h6 className="font-bold  mt-4">
                  Q. What is the process of initiating returns/replacement?
                </h6>
                <p className="text-sm  tracking-wide mt-4 pl-4">
                  <ol class="list-decimal">
                    <li className="mt-3">
                      If you happen to receive defective or damaged product(s),
                      notify us on{" "}
                      <span className="text-blue-500 hover:underline">
                        <a href="mailto:support@buwch.com">support@buwch.com</a>
                      </span>{" "}
                      or WhatsApp at{" "}
                      <span className="text-blue-500 hover:underline">
                        <a href="https://wa.me/919953140287" target="_blank">
                          +919953140287
                        </a>
                      </span>{" "}
                      , within 24 hours of receiving the goods along with the
                      pictures/videos. If no pictures of damage are shared with
                      us, we will not be able to initiate an investigation into
                      defective delivery.
                    </li>
                    <li className="mt-3">
                      Customers must visit the DISPATCHED SHIPMENTS page and
                      follow the instructions provided on the portal to initiate
                      a return request
                    </li>
                    <li className="mt-3">
                      Once you have initiated a return request, please email us
                      photographs of your product at{" "}
                      <span className="text-blue-500 hover:underline">
                        <a href="mailto:support@buwch.com">support@buwch.com</a>
                      </span>{" "}
                      for us to ascertain the condition of the product(s) and
                      packaging. Please include your Order_id and Username.
                      Please note that if the photographs show signs of wear and
                      the products look in unsellable condition, we will be
                      unable to process your refund/replacement, and refund
                      shall be withheld.
                    </li>
                    <li className="mt-3">
                      BUWCH Lifestyles and Management Pvt Ltd will intimate the
                      User by registered Email and WhatsApp if the
                      return/replacement of a product is not allowable due to
                      damage or usage on the side of the customer. The User is
                      requested to dispatch the product only after receiving
                      confirmation message from our side regarding its
                      returnability.
                    </li>
                    <li className="mt-3">
                      Please ensure that there is someone home to handover the
                      return. Three (3) attempts are generally made to pick up a
                      return. If the return is not handed over after 3 attempts,
                      no more returns will be made and the return request will
                      be rejected.
                    </li>
                    <li className="mt-3">
                      Once we receive the product at our Factory premises, we
                      will inspect it to ensure that there is no damage to the
                      product or packaging, and that the product has been
                      returned in absolutely unused condition. The
                      pictures/videos of the same will be sent by Email and
                      WhatsApp to the User immediately upon receipt by us.
                    </li>
                  </ol>
                </p>
                <h6 className="font-bold  mt-4">
                  Q. How long will it take to process my refund or replacement?
                </h6>
                <p className="text-sm  tracking-wide mt-4 pl-4">
                  <ol class="list-decimal">
                    <li className="mt-3">
                      Response to a return request: 24 hours
                    </li>
                    <li className="mt-3">Return pickup: 3-5 days</li>
                    <li className="mt-3">
                      Reverse shipping time to our Factory premises: 15-20 days
                    </li>
                    <li className="mt-3">Quality check: 24 hours</li>
                    <li className="mt-3">
                      Refund amount debited from our end: 1 day
                    </li>
                    <li className="mt-3">
                      Credit to customers original mode-of-payment: 3 days
                    </li>
                    <li className="mt-3">
                      Total returns timeline: 10-15 days depending on the
                      shipping/reverse pickup address.
                    </li>
                  </ol>
                </p>
                <h6 className="font-bold  mt-4">
                  Q. Who pays for the return & replacement shipping?
                </h6>
                <p className="text-sm  tracking-wide mt-4 pl-4">
                  <ol class="list-decimal">
                    <li className="mt-3">
                      Any VAT, import duties, tariffs, or taxes at the
                      destination must be borne by the customer upon receipt of
                      the fresh product, and any reversed duty drawbacks
                      resulting from the return of the original product shall
                      also be the responsibility of the customer.
                    </li>
                    <li className="mt-3">
                      Any claims regarding manufacturing defects or substandard
                      quality of the shoes must be substantiated by the Business
                      User. BUWCH Lifestyles and Management Pvt Ltd reserves the
                      right to deny a full refund if the products are damaged
                      during the Business User's testing process, or due to
                      improper handling or storage. In cases where the
                      photographic or video evidence provided is disputed, BUWCH
                      Lifestyles and Management Pvt Ltd may request a physical
                      inspection of the shipment at the Client's premises by our
                      representatives for verification of the extent of damage
                      and to determine the cause.
                    </li>
                    <li className="mt-3">
                      For replacements necessitated by reasons other than
                      manufacturing defects or substandard raw materials, any
                      associated logistics or transportation costs will be the
                      responsibility of the Business User.
                    </li>
                    <li className="mt-3">
                      For individual user replacement requests, BUWCH Lifestyles
                      and Management Pvt Ltd will provide one free replacement
                      shipment to the user's address. Subsequent returns or
                      replacements, beyond the initial free replacement for
                      reasons other than manufacturing defects or quality issues
                      attributable to BUWCH Lifestyles and Management, will be
                      the responsibility of the customer, including any
                      associated shipping costs.
                    </li>
                    <li className="mt-3">
                      Made-to-order or customized products are not eligible for
                      replacement or refund under any circumstances. These
                      products will not be shipped until the customer provides
                      explicit approval following a quality check.
                    </li>
                    <li className="mt-3">
                      Individual users are entitled to a free replacement or a
                      full refund for shoes/accessories if submitted images or
                      videos clearly demonstrate a manufacturing defect or the
                      use of inferior raw materials that deviate from the
                      agreed-upon Job Sheet.
                    </li>
                    <li className="mt-3">
                      Individual users may request a refund, less the cost of
                      return shipping to BUWCH Lifestyles and Management Pvt
                      Ltd's factory or experience center if they are
                      dissatisfied with their purchase for any reason. Standard
                      refund policies, as outlined in this document, will apply.
                    </li>
                    <li className="mt-3">
                      For all refund requests, pickup from the doorstep of the
                      User may be arranged BUWCH Lifestyles and Management Pvt
                      Ltd through our logistics partners. the associated
                      shipping charges will be deducted from the refund amount.
                      Alternatively, users may ship the products back to the
                      factory at their own expense.
                    </li>
                    <li className="mt-3">
                      The Company is not liable for refunds to business users
                      after quality checks have been approved by third-party
                      agencies, the customer's authorized representatives upon
                      physical inspection, or following email/WhatsApp approval
                      of randomized shipment photographs/videos.
                    </li>
                  </ol>
                </p>
                <h6 className="font-bold  mt-4">
                  PRECAUTIONARY GUIDELINES AND BEST PRACTICES FOR WAREHOUSING
                </h6>
                <p className="text-sm  tracking-wide mt-4 pl-4">
                  Fungus, particularly mould and mildew, can wreak havoc on
                  leather shoes stored in a warehouse. BUWCH Lifestyles and
                  Management Pvt Ltd requests and advises all customers to
                  follow the principles underlined below to prevent damage to
                  their shoes. Any damage through fungus growth, mildew or
                  discolouration arising from improper storage of the shipment
                  post-delivery, will not be considered our liability.
                  <ol class="list-decimal mt-3">
                    <li className="mt-3">
                      Control Humidity:
                      <br />
                      Maintain low humidity: The most crucial factor is keeping
                      relative humidity below 60%. Mold cannot thrive in dry
                      environments. <br />
                      Dehumidifiers: Use industrial-grade dehumidifiers to
                      extract excess moisture from the air. <br />
                      Hygrometers: Monitor humidity levels with hygrometers
                      placed strategically throughout the warehouse. <br />
                      Ventilation: Ensure proper airflow to prevent stagnant,
                      moisture-laden air. Fans, ventilation systems, and even
                      opening windows when weather permits can help. <br />
                      Address leaks: Immediately repair any leaks in the roof,
                      pipes, or walls to prevent water damage and subsequent
                      mould growth.
                    </li>
                    <li className="mt-3">
                      Maintain Cleanliness:
                      <br />
                      Regular cleaning: Implement a routine cleaning schedule to
                      remove dust, dirt, and potential mould spores.
                      <br />
                      Spill cleanup: Clean up spills immediately to prevent
                      moisture buildup.
                      <br />
                      Dry storage: Ensure goods are dry before storing them. Wet
                      items can introduce moisture into the warehouse
                      environment.
                    </li>
                    <li className="mt-3">
                      Organize Efficiently:
                      <br />
                      Avoid clutter: Cluttered storage blocks airflow, creating
                      pockets of moisture. Maintain organized aisles and storage
                      areas.
                      <br />
                      Spacing: Don't stack items too closely or against walls.
                      Allow for air circulation around all stored goods.
                      <br />
                      Pallets: Use pallets to raise goods off the floor,
                      preventing them from absorbing moisture.
                    </li>
                    <li className="mt-3">
                      Packaging Strategies:
                      <br />
                      Desiccants: Include desiccant packets (silica gel,
                      bentonite clay) in packaging to absorb excess moisture.
                      <br />
                      Plastic wrap: Wrap cloth goods and other vulnerable items
                      in plastic or shrink wrap for added protection.
                      <br />
                      Dry packaging: Ensure packaging is dry before use to avoid
                      introducing moisture.
                    </li>
                    <li className="mt-3">
                      Temperature Control:
                      <br />
                      Avoid extreme temperatures: Extreme temperature
                      fluctuations can cause condensation, leading to mould
                      growth. Maintain a stable temperature within the
                      warehouse.
                      <br />
                    </li>
                    <li className="mt-3">
                      Regular Inspections:
                      <br />
                      Routine checks: Regularly inspect stored goods for signs
                      of mould or mildew. Early detection is key to preventing
                      widespread fungus contamination.
                      <br />
                      Address issues promptly: If you find mould or fungus
                      growth in any pair, clean it immediately and address the
                      source of the moisture.
                    </li>
                  </ol>
                  <ol className="list-disc mt-4">
                    <li className="mt-3">
                      Additional Tips:
                      <br />
                      Ensure proper drainage around the warehouse to prevent
                      water from pooling, which can lead to growth of pests and
                      harmful fungi.
                      <br />
                      Pest control: Insects can introduce moisture and mould
                      spores. Implement pest control measures to keep them away.
                    </li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReplacementRefund;
