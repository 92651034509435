import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import secureLocalStorage from "react-secure-storage";
import CheckAuth from "./CheckAuth";
import isEqual from "lodash/isEqual";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const hash = "";
  const { type } = CheckAuth({ hash });
  let chatmessages = JSON.parse(secureLocalStorage.getItem("chat_messages"));
  const [openChat2, setOpenChat2] = useState(false);
  const [messages, setMessages] = useState(chatmessages ? chatmessages : []);
  const socketRef = useRef(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (type !== "") {
      const ws = new WebSocket(`ws://127.0.0.1:8000/ws/chat/`);

      ws.onopen = () => {
        console.log("WebSocket Connected!");
        setIsConnected(true);
        socketRef.current = ws;
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log(data);
        if (data.type === "read" && data.Message_uuid) {
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.Message_uuid === data.Message_uuid
                ? { ...msg, Read: true }
                : msg
            )
          );
        } else if (data.type === "reaction" && data.Message_uuid) {
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.Message_uuid === data.Message_uuid
                ? {
                    ...msg,
                    Reaction: Array.from(
                      new Set([...(msg.Reaction || []), ...data.Reaction])
                    ),
                  }
                : msg
            )
          );
        } else {
          if (data.Direction && type === "admin") return;
          else if (!data.Direction && type !== "admin") return;
          setMessages((prevMessages) => [
            ...prevMessages,
            { ...data, Read: false },
          ]);
        }
      };

      ws.onerror = (error) => {
        console.error("WebSocket Error:", error);
      };

      ws.onclose = () => {
        console.log("WebSocket Disconnected");
        setIsConnected(false);
      };

      socketRef.current = ws;
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
    };
  }, [type]);

  useEffect(() => {
    if (openChat2 && socketRef.current) {
      messages?.forEach((msg) => {
        const isReceivedMessage =
          (type === "admin" && !msg.Direction) ||
          (type !== "admin" && msg.Direction);

        if (
          isReceivedMessage &&
          !msg.Read &&
          socketRef.current &&
          socketRef.current.readyState === WebSocket.OPEN
        ) {
          socketRef.current.send(
            JSON.stringify({
              type: "read",
              Message_uuid: msg.Message_uuid,
            })
          );
        }
      });
    }
  }, [socketRef.current, messages, openChat2]);

  useEffect(() => {
    if (messages && messages?.length > 0) {
      const existingChats = chatmessages || {};
      if (!isEqual(existingChats, messages)) {
        secureLocalStorage.setItem("chat_messages", JSON.stringify(messages));
      }
    }
  }, [messages]);

  // Function to send messages
  const sendMessage = (messageData) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(messageData));
      setMessages((prevMessages) => [
        ...prevMessages,
        { ...messageData, Read: false },
      ]);
    } else {
      console.error("WebSocket is not connected");
    }
  };

  // Function to send reactions
  const sendReaction = (message_uuid, reaction) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      let updatedReactions = [];

      setMessages((prevMessages) =>
        prevMessages.map((msg) => {
          if (msg.Message_uuid === message_uuid) {
            updatedReactions = Array.from(
              new Set([...(msg.Reaction || []), reaction])
            );
            return { ...msg, Reaction: updatedReactions };
          }
          return msg;
        })
      );

      socketRef.current.send(
        JSON.stringify({
          type: "reaction",
          Message_uuid: message_uuid,
          Reaction: updatedReactions,
        })
      );
    } else {
      console.error("WebSocket is not connected");
    }
  };

  return (
    <WebSocketContext.Provider
      value={{
        messages,
        sendReaction,
        sendMessage,
        isConnected,
        setOpenChat2,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
