import React, { useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { LuArrowUpRight } from "react-icons/lu";

function ReviewPopup({ onClose, onOpen, selectedImage, handleFileChange,fileInputRef, setConfermationReview, triggerConfetti }) {
  const [openRating, setOpenRating] = useState(false);
  const [rating, setRating] = useState([]);

  return (
    <div className="popup-container">
      <div className="p-3 rounded-2xl  bg-white w-full md:max-w-[500px] pop-up-animation mx-2 relative">
        <h2 className="text-[15px] font-semibold border-b flex items-center justify-between pb-2 px-2">
          Write Review
          <span
            onClick={() => onClose()}
            className="bg-[#ffffff] hover:bg-red-300 border-[1px] border-zinc-400 hover:border-red-500 shadow-sm hover:shadow p-1 rounded-full text-black hover:scale-95 duration-300 cursor-pointer absolute -right-2 -top-2 z-10"
          >
            <IoClose className="text-xl" />
          </span>
        </h2>

        <div>
          <div
            onClick={() => setOpenRating(!openRating)}
            className={`pt-2 flex items-start justify-between p-0.5`}
          >
            <div className="flex cursor-pointer gap-5">
              {/* image */}
              <img
                src="/images/boot2.jpg"
                className="w-[66PX] h-[66PX] border rounded-lg"
                alt=""
              />
              <div className="flex flex-col gap-0.5">
                <h3 className="text-sm font-bold">
                  HORSEBIT LOAFERS 80746 XIM
                </h3>
                {/* Five star */}
                <div className="  flex justify-start items-center">
                  <div className="rating z-50 flex gap-1">
                    {Array.from({ length: 5 }, (_, index) => {
                      const value = 5 - index; // Create ratings from 5 to 1
                      return (
                        <React.Fragment key={value}>
                          <input
                            value={value}
                            name="rating"
                            id={`star${value}`}
                            type="radio"
                            onChange={(e) => {
                              setRating((prevState) => {
                                return {
                                  ...prevState,
                                  rating: e.target.value,
                                };
                              });
                              setOpenRating(true); // Open image upload & textarea section when a star is clicked
                            }}
                            className="hidden"
                          />
                          <label
                            title={`${value} stars`}
                            htmlFor={`star${value}`}
                          >
                            <svg
                              strokeLinejoin="round"
                              strokeLinecap="round"
                              strokeWidth="2"
                              stroke="#000000"
                              fill="none"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                              className="svgOne w-5 h-5 md:w-6 md:h-6"
                            >
                              <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                            </svg>
                            <svg
                              strokeLinejoin="round"
                              strokeLinecap="round"
                              strokeWidth="2"
                              stroke="#000000"
                              fill="none"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                              className="svgTwo "
                            >
                              <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                            </svg>
                          </label>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
                <p className="text-xs font-medium text-gray-400 flex items-center gap-1 hover:text-[#FEC23D] duration-300 group">
                  Write a detailed review
                  <span>
                    <svg
                      className="w-[9px] h-[9px] fill-gray-400 group-hover:fill-[#FEC23D] suration-300 group-hover:translate-x-1 duration-500"
                      version="1.1"
                      id="fi_271228"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsHlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 492.004 492.004"
                      xmlSpace="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
			c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
			c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
			c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                          ></path>
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </span>
                </p>
              </div>
            </div>
            <span className="cursor-pointer">
              <svg
                className={`w-5 h-5 duration-500  ${
                  openRating ? "-rotate-90" : "rotate-90"
                }`}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                id="fi_2722985"
              >
                <g id="_15" data-name="15">
                  <path d="m9 19a1 1 0 0 1 -.71-1.71l5.3-5.29-5.3-5.29a1 1 0 0 1 1.42-1.42l6 6a1 1 0 0 1 0 1.41l-6 6a1 1 0 0 1 -.71.3z"></path>
                </g>
              </svg>
            </span>
          </div>
          {/* Drop down */}
          <div
            className={`duration-500 transition-all ease-in-out overflow-hidden p-0.5 ${
              openRating ? "h-[170px] opacity-100" : "h-0 opacity-0 "
            }`}
          >
            <textarea
              name=""
              placeholder="Write Your Reviews or/Suggestions here…"
              className="min-h-20 border w-full rounded-lg placeholder:text-sm p-2 text-sm outline-none hover:ring-1 hover:ring-[#FFD61F] duration-300 mt-2"
              id=""
            ></textarea>
            {/* Review images */}
            <div
              className={`overflow-x-scroll no-scrollbar shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-lg flex items-center gap-2.5`}
            >
              {selectedImage.length > 0 ? (
                selectedImage.map((image, index) => (
                  <div className="p-1">
                    <img
                      key={index}
                      src={image}
                      className="w-20 h-20 object-cover rounded-lg"
                      alt={`Image not found`}
                    />
                  </div>
                ))
              ) : (
                <></>
              )}
              <div
                onClick={() => fileInputRef?.current?.click()}
                className={`flex flex-col cursor-pointer p-1 ${
                  selectedImage.length > 0
                    ? "items-start w-fit"
                    : " items-center w-full"
                }`}
              >
                <div className="flex flex-col items-center mx-auto">
                  <span className="group-hover:scale-95 duration-300">
                    <svg
                      className="w-11 h-11 fill-gray-400 "
                      id="fi_8191595"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id="image-plus"
                        d="m21.75 11v6a3.383 3.383 0 0 1 -3.75 3.75h-12a3.383 3.383 0 0 1 -3.75-3.75v-10a3.383 3.383 0 0 1 3.75-3.75h7a.75.75 0 0 1 0 1.5h-7c-1.577 0-2.25.673-2.25 2.25v9.25l2.54-2.54a1.008 1.008 0 0 1 1.42 0l.94.94a.5.5 0 0 0 .7 0l4.94-4.94a1.008 1.008 0 0 1 1.42 0l4.54 4.54v-3.25a.75.75 0 0 1 1.5 0zm-13.757-3.25a1.253 1.253 0 1 0 .007 0zm9.007-2h1.25v1.25a.75.75 0 0 0 1.5 0v-1.25h1.25a.75.75 0 0 0 0-1.5h-1.25v-1.25a.75.75 0 0 0 -1.5 0v1.25h-1.25a.75.75 0 0 0 0 1.5z"
                      ></path>
                    </svg>
                  </span>
                  <span className="text-[13px] font-semibold text-gray-500">
                    {selectedImage.length > 0 ? (
                      <>Add More</>
                    ) : (
                      <>Click here to upload</>
                    )}
                  </span>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  //   ref={fileInputRef}
                  className="hidden w-[2px]"
                  onChange={handleFileChange} // Handle file selection
                />
              </div>
            </div>
          </div>

          {/* Submit button */}
          <button
            onClick={() => {
              onClose();
              setConfermationReview();
              triggerConfetti();
            }}
            className="bg-[#FFD61F] duration-300 px-3 py-1.5 rounded-full text-xs xl:text-[13px] font-semibold text-[#000000] flex items-center justify-center gap-1 w-[190px] hover:w-[100%] mx-auto mt-4"
          >
            Submit Review
            <span className="text-lg">
              <LuArrowUpRight />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReviewPopup;
