import React from "react";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth.jsx";
import DebtorandCreditor from "../../components/AdminComponent/DebtorandCreditor.jsx";
import Supplier from "./Supplier.jsx";
import BusinessUser from "./BusinessUser.jsx";
import { useLocation } from "react-router-dom";

function AdminLedger() {
  const location = useLocation();
  const hash = location.hash.substring(1);
  const { type } = CheckAuth({ hash });
  return (
    <div>
      {console.log(type)}
      <>
        {type === "admin" ? (
          <DebtorandCreditor />
        ) : type === "supplier" ? (
          <Supplier />
        ) : type === "businessuser" ? (
          <BusinessUser />
        ) : (
          <>No data found</>
        )}
      </>
    </div>
  );
}

export default AdminLedger;
