import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { useLocation } from "react-router-dom";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";

const Branding = ({ orderId, onNext }) => {
  const location = useLocation();
  const hash = location.hash.substring(1);
  const { email, type, currency } = CheckAuth({ hash });
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [brandingData, setBrandingData] = useState({});
  const [uploadData, setUploadData] = useState({
    images: [null, null, null, null],
  });
  const [showPopup, setShowPopup] = useState(null);
  let productionStages = JSON.parse(
    secureLocalStorage.getItem("production_stages")
  );

  const alternateImages = {
    dye: "/images/Tongue-un.svg",
    sole: "/images/Sole-un.svg",
    box: "/images/Box-un.svg",
    tongue: "/images/Tongue-un.svg",
  };

  useEffect(() => {
    const transformedData = [];

    productionStages.forEach((order) => {
      if (order.Branding) {
        Object.entries(order.Branding).forEach(([key, value]) => {
          // Determine the type of image (e.g., Dye, Sole, Box, Tongue)
          const imageType = key.toLowerCase(); // Convert to lowercase for matching
          const alternateImage =
            alternateImages[imageType] || "/images/default-alternate.jpeg"; // Fallback to a default image if no match

          // Use the provided image or the alternate image if missing
          const imageUrl = value.Image || alternateImage;
          transformedData.push({
            url: imageUrl || `/images/${key.toLowerCase()}.jpeg`,
            label: key,
            guidelines: [
              "Make sure the image is clear and well-lit.",
              "Accepted formats: JPG, PNG, GIF.",
              "Maximum file size: 5MB.",
              "Ensure the image has no watermark or logos.",
            ],
          });
        });
      }
    });

    console.log(transformedData);

    setLoading(false);

    setBrandingData(transformedData);
  }, [email, JSON.stringify(brandingData)]);

  // Custom guidelines for each image input section
  const guidelines = [
    {
      title: "Image 1 Guidelines",
      items: [
        "Make sure the image is clear and well-lit.",
        "Accepted formats: JPG, PNG.",
        "Maximum file size: 3MB.",
        "Ensure the image has no watermark or logos.",
      ],
    },
    {
      title: "Image 2 Guidelines",
      items: [
        "Image should be taken from a front angle.",
        "Accepted formats: PNG, GIF.",
        "Maximum file size: 4MB.",
        "Ensure the image background is neutral.",
      ],
    },
    {
      title: "Image 3 Guidelines",
      items: [
        "Ensure proper resolution for a high-quality image.",
        "Accepted formats: JPG, PNG.",
        "Maximum file size: 5MB.",
        "Ensure the image is not blurry.",
      ],
    },
    {
      title: "Image 4 Guidelines",
      items: [
        "Ensure proper resolution for a high-quality image.",
        "Accepted formats: JPG, PNG.",
        "Maximum file size: 5MB.",
        "Ensure the image is not blurry.",
      ],
    },
  ];

  const handlePopupClose = () => {
    setShowPopup(null);
  };

  const handlePopupOpen = (index) => {
    setShowPopup(index); // Open the popup for the selected image
  };

  const handleViewClick = (item, type) => {
    setSelectedItem({ ...item, type }); // Add the type explicitly to the selected item
    setModalOpen(true);
  };

  const handleUploadChange = (e, type, index) => {
    const file = e.target.files[0];
    const previewUrl = URL.createObjectURL(file);

    if (type === "video") {
      setUploadData((prev) => ({ ...prev, video: { file, previewUrl } }));
    } else if (type === "image") {
      const updatedImages = [...uploadData.images];
      updatedImages[index] = { file, previewUrl };
      setUploadData((prev) => ({ ...prev, images: updatedImages }));
    }
  };

  const handleUploadSubmit = () => {
    alert("Upload successful! Check console for details.");
  };

  const handleMediaDelete = (type, index = null) => {
    if (type === "video") {
      setUploadData((prev) => ({ ...prev, video: null })); // Remove the video
    } else if (type === "image") {
      setUploadData((prev) => {
        const updatedImages = [...prev.images];
        updatedImages[index] = null; // Remove the image at the specified index
        return { ...prev, images: updatedImages };
      });
    }
  };

  const [isApproved, setIsApproved] = useState();

  const handleApprove = (index) => {
    setIsApproved((prev) =>
      prev?.map((approved, i) => (i === index ? true : false))
    );
  };

  if (loading) {
    return (
      <div className="text-center p-4 w-full flex items-center justify-center">
        <img
          className="w-6 h-6 animate-pulse"
          src="/images/branding-active.svg"
          alt="Loading"
        />
      </div>
    );
  }

  if (!brandingData) {
    return (
      <div className="text-center p-4 text-red-500 text-sm font-semibold">
        Failed to load content.
      </div>
    );
  }

  return (
    <div>
      {/* Display Section */}
      <div className="flex items-center gap-4 max-md:p-1 md:p-4 rounded-lg md:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] w-full md:w-max">
        {/* Media Containers */}
        <div className="flex flex-wrap items-center max-md:justify-center md:justify-start max-md:gap-2 md:gap-4  w-full">
          {/* Image Containers */}
          {brandingData.map((image, index) => (
            <div
              key={index}
              className="relative  cursor-pointer w-[48%] max-md:min-w-32 max-md:max-w-36 md:w-36 xl:w-44"
            >
              <div className=" text-[11px] font-medium">{image?.label}</div>
              <img
                src={image?.url}
                alt={image?.label}
                className="object-cover w-full md:w-36 xl:w-44 h-32 md:h-36 xl:h-44 rounded"
                onClick={() => handleViewClick(image)}
              />

              <div className="w-full relative pt-2 flex flex-col gap-y-2">
                <button
                  onClick={() => handleApprove(index)}
                  className="flex overflow-hidden items-center text-xs 2xl:text-sm font-medium focus-visible:outline-none focus-visible:ring-1  text-gray-50  bg-black/90  max-md:py-1 md:py-2 w-full whitespace-pre md:flex group relative  justify-between max-md:px-1.5 md:px-3 gap-2  transition-all duration-300 ease-out ring-1 ring-yellow-400/70 hover:ring-yellow-400 max-md:ring-offset-1 md:ring-offset-2 "
                >
                  <span className="absolute right-0 -mt-12 h-32 w-8 translate-x-12 rotate-12 bg-[#ffffff] opacity-20 transition-all duration-1000 ease-out group-hover:-translate-x-[180px]"></span>
                  <div className="flex items-center">
                    <span className="ml-1 text-white text-xs md:text-sm">
                      Approve
                    </span>
                  </div>
                  <div className="ml-2 flex items-center gap-1 text-sm md:flex">
                    {/* <img className="w-6 h-6 stroke-white fill-white" src="/images/adminImages/Customize 3D.svg" alt="" /> */}
                    <svg
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      stroke-linejoin="round"
                      stroke-miterlimit="2"
                      viewBox="0 0 24 24"
                      className="w-4 h-4 md:w-5 md:h-5 fill-gray-200 group-hover:fill-yellow-400 duration-500 ease-in-out "
                      xmlns="http://www.w3.org/2000/svg"
                      id="fi_9599152"
                    >
                      <g id="Icon">
                        <path d="m10.732 1.775c.336-.336.792-.525 1.268-.525s.932.189 1.268.525l1.445 1.445c.066.066.16.097.253.082l2.018-.319c.47-.075.95.041 1.335.32.384.28.642.701.717 1.17l.319 2.018c.015.093.073.173.157.216l1.82.927c.424.216.745.592.892 1.044s.108.945-.108 1.368l-.927 1.821c-.043.084-.043.182 0 .266l.927 1.821c.216.423.255.916.108 1.368s-.468.828-.892 1.044l-1.82.927c-.084.043-.142.123-.157.216l-.319 2.018c-.075.469-.333.89-.717 1.17-.385.279-.865.395-1.335.32l-2.018-.319c-.093-.015-.187.016-.253.082l-1.445 1.445c-.336.336-.792.525-1.268.525s-.932-.189-1.268-.525l-1.445-1.445c-.066-.066-.16-.097-.253-.082l-2.018.319c-.47.075-.95-.041-1.335-.32-.384-.28-.642-.701-.717-1.17l-.319-2.018c-.015-.093-.073-.173-.157-.216l-1.82-.927c-.424-.216-.745-.592-.892-1.044s-.108-.945.108-1.368l.927-1.821c.043-.084.043-.182 0-.266l-.927-1.821c-.216-.423-.255-.916-.108-1.368s.468-.828.892-1.044l1.82-.927c.084-.043.142-.123.157-.216l.319-2.018c.075-.469.333-.89.717-1.17.385-.279.865-.395 1.335-.32l2.018.319c.093.015.187-.016.253-.082zm-2.762 10.755 2.333 2.334c.293.293.768.293 1.061 0l4.666-4.667c.293-.293.293-.768 0-1.061-.292-.292-.768-.292-1.06 0l-4.137 4.137-1.803-1.803c-.292-.293-.768-.293-1.06 0-.293.292-.293.768 0 1.06z"></path>
                      </g>
                    </svg>
                  </div>
                </button>
                <button
                  className="border-2 text-xs md:text-sm flex items-center justify-between  max-md:px-1.5 md:px-3 overflow-hidden group border-black/80  w-full   max-md:py-1 md:py-2  font-medium hover:bg-black/90 duration-500 ease-in-out hover:text-white hover:font-normal max-xl:mb-2 2xl:mb-4 "
                  onClick={() => handleApprove(index)}
                >
                  Reject
                  <span className="pl-2">
                    <svg
                      viewBox="0 0 64 64"
                      className="w-4 h-4 md:w-5 md:h-5  group-hover:stroke-yellow-400 stroke-black stroke-2 fill-black/80 group-hover:fill-yellow-400 duration-500 group-hover:translate-x-1"
                      xmlns="http://www.w3.org/2000/svg"
                      id="fi_3177384"
                    >
                      <g id="Send">
                        <path d="m63 32a1.0009 1.0009 0 0 1 -.6025.918l-60 26a1 1 0 0 1 -1.2637-1.418l11.79-20.44a1.0011 1.0011 0 0 1 .502-.4317l11.8301-4.6283-11.83-4.6279a1.0011 1.0011 0 0 1 -.502-.4317l-11.7901-20.4404a1 1 0 0 1 1.2637-1.418l60 26a1.0009 1.0009 0 0 1 .6025.918z"></path>
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
              {/* <div className="w-full relative pt-2 flex flex-col gap-y-2">
                <button
                  onClick={() => handleApprove(index)}
                  href="#"
                  className="flex justify-between overflow-hidden items-center text-sm font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-[#FFD61F] text-white  hover:bg-[#FFD61F]/90 h-9 px-3 py-2 max-w-52 whitespace-pre md:flex group relative w-full gap-2 rounded-md transition-all duration-300 ease-out hover:ring-2 hover:ring-[#FFD61F] hover:ring-offset-2 group"
                >
                  <span className="absolute right-0 -mt-12 h-32 w-8 translate-x-12 rotate-12 bg-white opacity-20 transition-all duration-1000 ease-out group-hover:-translate-x-60"></span>
                  <div className="flex items-center gap-2">
                    <span className="ml-1 text-black font-semibold">
                      Approve
                    </span>
                  </div>
                  <div className="flex items-center gap-1 text-sm md:flex">
                    <svg
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      stroke-linejoin="round"
                      stroke-miterlimit="2"
                      viewBox="0 0 24 24"
                      className="w-5 h-5 text-[#292929] group-hover:text-[#000000]  transition-all duration-300"
                      xmlns="http://www.w3.org/2000/svg"
                      id="fi_9599152"
                    >
                      <g id="Icon">
                        <path d="m10.732 1.775c.336-.336.792-.525 1.268-.525s.932.189 1.268.525l1.445 1.445c.066.066.16.097.253.082l2.018-.319c.47-.075.95.041 1.335.32.384.28.642.701.717 1.17l.319 2.018c.015.093.073.173.157.216l1.82.927c.424.216.745.592.892 1.044s.108.945-.108 1.368l-.927 1.821c-.043.084-.043.182 0 .266l.927 1.821c.216.423.255.916.108 1.368s-.468.828-.892 1.044l-1.82.927c-.084.043-.142.123-.157.216l-.319 2.018c-.075.469-.333.89-.717 1.17-.385.279-.865.395-1.335.32l-2.018-.319c-.093-.015-.187.016-.253.082l-1.445 1.445c-.336.336-.792.525-1.268.525s-.932-.189-1.268-.525l-1.445-1.445c-.066-.066-.16-.097-.253-.082l-2.018.319c-.47.075-.95-.041-1.335-.32-.384-.28-.642-.701-.717-1.17l-.319-2.018c-.015-.093-.073-.173-.157-.216l-1.82-.927c-.424-.216-.745-.592-.892-1.044s-.108-.945.108-1.368l.927-1.821c.043-.084.043-.182 0-.266l-.927-1.821c-.216-.423-.255-.916-.108-1.368s.468-.828.892-1.044l1.82-.927c.084-.043.142-.123.157-.216l.319-2.018c.075-.469.333-.89.717-1.17.385-.279.865-.395 1.335-.32l2.018.319c.093.015.187-.016.253-.082zm-2.762 10.755 2.333 2.334c.293.293.768.293 1.061 0l4.666-4.667c.293-.293.293-.768 0-1.061-.292-.292-.768-.292-1.06 0l-4.137 4.137-1.803-1.803c-.292-.293-.768-.293-1.06 0-.293.292-.293.768 0 1.06z"></path>
                      </g>
                    </svg>
                  </div>
                </button>
                <button
                  onClick={() => handleApprove(index)}
                  href="#"
                  className="flex justify-between overflow-hidden items-center text-sm font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-[#fafafa]  border-2 hover:bg-[#e9e9e9] h-9 px-3 py-2 max-w-52 whitespace-pre md:flex group relative w-full gap-2 rounded-md transition-all duration-300 ease-out hover:ring-2 hover:ring-[#e9e9e9] hover:ring-offset-2 group"
                >
                  <span className="absolute right-0 -mt-12 h-32 w-8 translate-x-12 rotate-12 bg-white opacity-20 transition-all duration-1000 ease-out group-hover:-translate-x-60"></span>
                  <div className="flex items-center gap-2">
                    <span className="ml-1 text-black font-semibold">
                      Reject
                    </span>
                  </div>
                  <div className="flex items-center gap-1 text-sm md:flex">
                    <svg
                      viewBox="0 0 64 64"
                      className="w-5 h-5 text-[#292929]   transition-all duration-300"
                      xmlns="http://www.w3.org/2000/svg"
                      id="fi_3177384"
                    >
                      <g id="Send">
                        <path d="m63 32a1.0009 1.0009 0 0 1 -.6025.918l-60 26a1 1 0 0 1 -1.2637-1.418l11.79-20.44a1.0011 1.0011 0 0 1 .502-.4317l11.8301-4.6283-11.83-4.6279a1.0011 1.0011 0 0 1 -.502-.4317l-11.7901-20.4404a1 1 0 0 1 1.2637-1.418l60 26a1.0009 1.0009 0 0 1 .6025.918z"></path>
                      </g>
                    </svg>
                  </div>
                </button>
              </div> */}
            </div>
          ))}
        </div>
      </div>

      {/* Modal for viewing images or video */}
      {modalOpen && selectedItem && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            className={`bg-white p-6 rounded-lg  pop-up-animation w-[400px]`}
          >
            <div className="text-lg font-semibold text-center mb-3">
              {selectedItem.label}

              <button
                className="float-right"
                onClick={() => setModalOpen(false)}
                aria-label="Close"
              >
                <svg
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  className="hover:fill-red-600 hover:bg-red-300 rounded-full duration-300 ease-in-out hover:scale-90 w-4 lg:w-6 h-4 lg:h-6"
                >
                  <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
                  <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
                  <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
                </svg>
              </button>
            </div>
            <div className="space-y-4">
              <img
                src={selectedItem.url}
                alt={selectedItem.label}
                className="w-full object-cover rounded"
              />
            </div>
          </div>
        </div>
      )}

      {/* Upload Section */}
      <div className="flex flex-col justify-center   max-md:p-2 md:p-4 mt-6 rounded-lg shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] w-full md:w-max">
        {/* Image Uploads */}
        <h3 className="text-sm md:text-base font-semibold pb-2 max-md:text-center">Upload Branding Images</h3>
        <div className="flex flex-wrap items-center max-md:justify-center md:justify-start max-md:gap-2 md:gap-4  w-full">
          {uploadData.images?.map((image, index) => (
            <div
              key={index}
              className="relative w-[48%] max-md:min-w-32 max-md:max-w-36 md:w-36 xl:w-44  h-32 md:h-36 xl:h-44  border rounded overflow-hidden cursor-pointer"
            >
              {image ? (
                <div className="h-full w-full relative">
                  <img
                    src={image.previewUrl}
                    alt={`Uploaded Image ${index + 1}`}
                    className="object-cover h-full w-full rounded "
                  />
                  {/* delete  */}
                  <button
                    className="absolute top-1 right-1 z-40  px-1 py-1 flex items-center border-[1px]  border-gray-200 bg-gray-50 hover:border-red-200  hover:bg-gray-100   justify-center  overflow-hidden text-black duration-300 group ease-in-out text-xs md:text-sm font-semibold cursor-pointer rounded-full"
                    onClick={() => handleMediaDelete("image", index)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="fi_3405244"
                      data-name="Layer 2"
                      className="w-4 h-4  fill-[#000] group-hover:fill-[#ff4444] duration-300 ease-in-out"
                      viewBox="0 0 24 24"
                    >
                      <path d="M19,7a1,1,0,0,0-1,1V19.191A1.92,1.92,0,0,1,15.99,21H8.01A1.92,1.92,0,0,1,6,19.191V8A1,1,0,0,0,4,8V19.191A3.918,3.918,0,0,0,8.01,23h7.98A3.918,3.918,0,0,0,20,19.191V8A1,1,0,0,0,19,7Z"></path>
                      <path d="M20,4H16V2a1,1,0,0,0-1-1H9A1,1,0,0,0,8,2V4H4A1,1,0,0,0,4,6H20a1,1,0,0,0,0-2ZM10,4V3h4V4Z"></path>
                      <path d="M11,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z"></path>
                      <path d="M15,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z"></path>
                    </svg>
                  </button>
                </div>
              ) : (
                <div className="relative flex items-center w-full h-full justify-center ">
                  <div
                    className="flex flex-col items-center justify-center gap-y-1 z-0 w-full h-full cursor-pointer"
                    onClick={() => handlePopupOpen(index)}
                  >
                    <img
                      src="/images/addimage.svg"
                      className="w-9 h-9 cursor-pointer"
                      alt=""
                    />
                    <p className="text-[10px] font-medium ">Upload Image</p>
                  </div>
                  {showPopup === index && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                      <div class="pop-up-animation [--shadow:rgba(60,64,67,0.3)_0_1px_2px_0,rgba(60,64,67,0.15)_0_2px_6px_2px] w-4/5 h-auto rounded-2xl bg-white [box-shadow:var(--shadow)] max-w-[300px]">
                        <div class="flex flex-col items-center justify-between pt-9 px-6 pb-6 relative ">
                          <span class="relative mx-auto -mt-16 mb-4">
                            <img
                              src="/images/image-tnc.svg"
                              className="w-16 h-16"
                              alt=""
                            />
                          </span>

                          <h5 class="text-sm font-semibold mb-2 text-left mr-auto text-zinc-700">
                            {guidelines[index].title}
                          </h5>

                          <p class="w-full mb-4 text-xs text-justify">
                            {guidelines[index].items?.map((item, i) => (
                              <p key={i}>{item}</p>
                            ))}
                          </p>
                          <div className="flex items-end justify-between w-full">
                            <button
                              onClick={handlePopupClose}
                              class="mb-2 text-sm mr-auto text-zinc-600 cursor-pointer font-medium transition-colors hover:text-yellow-400 hover:underline underline-offset-2"
                            >
                              Cancel
                            </button>
                            <button
                              class="relative font-semibold fcursor-pointer py-2 px-8 w-max break-keep text-sm rounded-lg transition-colors duration-300 ease-in-out text-gray-700 hover:text-gray-800 bg-yellow-400 hover:bg-yellow-500"
                              type="button"
                            >
                              Upload
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  handleUploadChange(e, "image", index)
                                }
                                className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer z-10 "
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <button
        className="mt-4 px-4 py-2 bg-yellow-500 text-white font-semibold rounded"
        onClick={handleUploadSubmit}
      >
        Upload Media
      </button>
    </div>
  );
};

export default Branding;
