"use client";

import React, { useEffect, useState } from "react";

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Select = ({ name, value, label, options = [], onChange, className }) => (
  <div className={`relative ${className}`}>
    {/* {label && (
      <label htmlFor={name} className="mb-2 block font-medium text-slate-800">
        {label}
      </label>
    )} */}
    <select
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      className="cursor-pointer rounded-lg border border-gray-300 bg-white p-1.5 min-w-[140px] text-[13px] font-medium outline-none text-gray-900 hover:bg-gray-100 sm:rounded-xl"
      required
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
   
  </div>
);

const Calendar = ({ onClick }) => {
  const today = new Date();
  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth());
  const [slideDirection, setSlideDirection] = useState("none"); // "left", "right", or "none"

  // Create year options
  const generateYearOptions = () => {
    const currentYear = today.getFullYear();
    const yearOptions = [];
    for (let i = currentYear - 5; i <= currentYear + 5; i++) {
      yearOptions.push({ name: String(i), value: String(i) });
    }
    return yearOptions;
  };

  // Create month options
  const generateMonthOptions = () => {
    return monthNames.map((monthName, idx) => ({
      name: monthName,
      value: String(idx),
    }));
  };

  const yearOptions = generateYearOptions();
  const monthOptions = generateMonthOptions();

  const handleMonthChange = (event) => {
    const newMonth = parseInt(event.target.value, 10);

    // Determine slide direction
    if (newMonth < month) {
      setSlideDirection("right");
    } else if (newMonth > month) {
      setSlideDirection("left");
    }

    setMonth(newMonth);
  };

  const handleYearChange = (event) => {
    const newYear = parseInt(event.target.value, 10);

    // Determine slide direction
    if (newYear < year) {
      setSlideDirection("right");
    } else if (newYear > year) {
      setSlideDirection("left");
    }

    setYear(newYear);
  };

  const handleTodayClick = () => {
    // Determine slide direction
    if (
      today.getFullYear() < year ||
      (today.getFullYear() === year && today.getMonth() < month)
    ) {
      setSlideDirection("right");
    } else if (
      today.getFullYear() > year ||
      (today.getFullYear() === year && today.getMonth() > month)
    ) {
      setSlideDirection("left");
    }

    setMonth(today.getMonth());
    setYear(today.getFullYear());
  };

  const handlePrevMonth = () => {
    setSlideDirection("right");
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const handleNextMonth = () => {
    setSlideDirection("left");
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const handleDayClick = (day) => {
    if (onClick) {
      onClick(day, month, year);
    }
  };

  const generateCalendar = () => {
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);
    const daysInMonth = lastDayOfMonth.getDate();
    const startDayOfWeek = firstDayOfMonth.getDay();

    // Get days from previous month
    const prevMonth = month === 0 ? 11 : month - 1;
    const prevMonthYear = month === 0 ? year - 1 : year;
    const prevMonthLastDay = new Date(
      prevMonthYear,
      prevMonth + 1,
      0
    ).getDate();

    const calendarGrid = [];
    const weeks = [];

    // Add days from previous month
    for (let i = 0; i < startDayOfWeek; i++) {
      const prevMonthDay = prevMonthLastDay - startDayOfWeek + i + 1;
      calendarGrid.push({
        day: prevMonthDay,
        isCurrentMonth: false,
        month: prevMonth,
        year: prevMonthYear,
      });
    }

    // Add days from current month
    for (let day = 1; day <= daysInMonth; day++) {
      calendarGrid.push({
        day,
        isCurrentMonth: true,
        month,
        year,
      });
    }

    // Add days from next month
    const nextMonth = month === 11 ? 0 : month + 1;
    const nextMonthYear = month === 11 ? year + 1 : year;
    const remainingCells = 7 - (calendarGrid.length % 7);
    if (remainingCells < 7) {
      for (let i = 1; i <= remainingCells; i++) {
        calendarGrid.push({
          day: i,
          isCurrentMonth: false,
          month: nextMonth,
          year: nextMonthYear,
        });
      }
    }

    // Group days into weeks
    for (let i = 0; i < calendarGrid.length; i += 7) {
      weeks.push(calendarGrid.slice(i, i + 7));
    }

    return weeks;
  };

  const animationClass =
    slideDirection === "left"
      ? "animate-slide-left"
      : slideDirection === "right"
      ? "animate-slide-right"
      : "";

  return (
    <div className="rounded-2xl bg-white text-slate-800 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] overflow-hidden  max-h-[500px]">
      <div className="sticky top-0 z-50 w-full bg-white">
        <div className="flex w-full flex-wrap items-center justify-between gap-6 p-3">
          <div className="flex flex-wrap gap-2 sm:gap-3">
            <div className="flex gap-3">
              <Select
                name="month"
                value={String(month)}
                options={monthOptions}
                onChange={handleMonthChange}
              />
              <Select
                name="year"
                value={String(year)}
                options={yearOptions}
                onChange={handleYearChange}
              />
            </div>
          </div>
          {/* Add event & Today */}
          <div className="flex items-center gap-3">
            <button
              onClick={handleTodayClick}
              type="button"
              className="rounded-xl border border-gray-300 bg-white py-1.5 px-2 text-[13px] font-medium text-gray-900 hover:bg-gray-100 duration-300"
            >
              Today
            </button>
            <button
              type="button"
              className="whitespace-nowrap rounded-lg bg-gradient-to-r bg-[#0EA5E9] hover:bg-sky-500 duration-300 py-1.5 px-2 text-center text-[13px] font-medium text-[#ffffff] focus:outline-none sm:rounded-xl"
            >
              + Add Event
            </button>
          </div>
          {/* <div className="flex w-fit items-center justify-between">
            <button
              onClick={handlePrevMonth}
              className="rounded-full border border-slate-300 p-1 transition-colors hover:bg-slate-100 sm:p-2"
            >
              <svg
                className="h-5 w-5 text-slate-800"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m15 19-7-7 7-7"
                />
              </svg>
            </button>
            <h1 className="min-w-16 text-center text-lg font-semibold sm:min-w-20 sm:text-xl">
              {monthNames[month]} {year}
            </h1>
            <button
              onClick={handleNextMonth}
              className="rounded-full border border-slate-300 p-1 transition-colors hover:bg-slate-100 sm:p-2"
            >
              <svg
                className="h-5 w-5 text-slate-800"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m9 5 7 7-7 7"
                />
              </svg>
            </button>
          </div> */}
        </div>
        <div className="grid w-full grid-cols-7 justify-between text-gray-600">
          {daysOfWeek.map((day, index) => (
            <div
              key={index}
              className="w-full border-y border-slate-200 py-1.5 bg-[#F1F7F9] text-sm text-center font-semibold"
            >
              {day}
            </div>
          ))}
        </div>
      </div>
      <div className={`w-full ${animationClass} px-2.5`}>
        {generateCalendar().map((week, weekIndex) => (
          <div className="flex gap-2 mt-2 w-full" key={`week-${weekIndex}`}>
            {week.map(
              (
                { day, isCurrentMonth, month: dayMonth, year: dayYear },
                dayIndex
              ) => {
                const isToday =
                  today.getDate() === day &&
                  today.getMonth() === dayMonth &&
                  today.getFullYear() === dayYear;

                return (
                  <div
                    key={`${dayMonth}-${day}-${dayIndex}`}
                    onClick={() => isCurrentMonth && handleDayClick(day)}
                    className={`relative z-10 m-0 group min-h-16 min-w-16 grow flex flex-col items-center justify-center
                        ${isCurrentMonth ? "cursor-pointer" : "cursor-default"} 
                        ${isCurrentMonth ? "hover:z-20 hover:border-cyan-400" : ""} 
                        rounded-xl border font-medium transition-all
                        sm:rounded-2xl sm:border-2 lg:rounded-3xl 
                        ${!isCurrentMonth ? "bg-gray-100" : ""}`}
                  >
                    <span
                      className={`absolute left-1 top-1 flex h-5 w-5 items-center justify-center 
                      rounded-full text-sm sm:h-6 sm:w-6 lg:left-2 lg:top-2 lg:h-8 lg:w-8 
                      ${
                        isToday
                          ? "bg-[#FFF4B5] font-semibold text-[#000000]"
                          : isCurrentMonth
                          ? "text-slate-800"
                          : "text-gray-400"
                      }`}
                    >
                      {day}
                    </span>
                    {isCurrentMonth && (
                      <button
                        type="button"
                        className="absolute right-2 top-2 rounded-full opacity-0 transition-all focus:opacity-100 group-hover:opacity-100"
                      >
                        <svg
                          className="h-8 w-8 scale-90 text-[#0EA5E9] transition-all hover:scale-100 group-focus:scale-100"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                );
              }
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
