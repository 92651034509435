import React from "react";
import { useLocation } from "react-router-dom";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";

function AIStudio() {
  const location = useLocation();
  const hash = location.hash.substring(1);
  const {} = CheckAuth({ hash });
  return <div>AIStudio</div>;
}

export default AIStudio;
