import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { useLocation } from "react-router-dom";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";

// Mock Data API
const mockApi = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        thumbnail: {
          url: "/images/test-tan.webp",
          label: "ART-001",
        },
        video: {
          label: "Texture video",
          thumbnail: "/images/sole-pt.mp4",
          url: "/images/sole-pt.mp4",
        },
        images: [
          { url: "/images/shoe-1-s1.jpeg", label: "Step 1" },
          { url: "/images/shoe-1-s2.jpeg", label: "Step 2" },
          { url: "/images/shoe-1-sole-b.jpeg", label: "Step 3" },
        ],
      });
    }, 500);
  });
};

const SolePasting = ({ orderId, onNext }) => {
  const location = useLocation();
  const hash = location.hash.substring(1);
  const { email, type, currency } = CheckAuth({ hash });
  const [mediaContent, setMediaContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [solePastingData, setSolePastingData] = useState({});
  const [showPopup, setShowPopup] = useState(null);
  let productionStages = JSON.parse(
    secureLocalStorage.getItem("production_stages")
  );
  const [uploadData, setUploadData] = useState({
    video: null,
    images: [null, null, null],
  });

  useEffect(() => {
    setLoading(true);
    const transformedData = [];
    productionStages
      .filter(
        (order) =>
          order.Order_id.toLowerCase() ===
          orderId.replaceAll("_", "/").toLowerCase()
      )
      .forEach((stage) => {
        Object.entries(stage?.Products)?.forEach(([productId, productData]) => {
          if (productData.Sole_pasting === undefined) {
            transformedData[productId] = {};
          } else {
            Object.entries(productData?.Sole_pasting || {}).forEach(
              ([key, value]) => {
                if (!transformedData[productId]) {
                  transformedData[productId] = {};
                }

                if (key.toLowerCase().includes("image")) {
                  if (!transformedData[productId]["images"]) {
                    transformedData[productId]["images"] = [];
                  }

                  transformedData[productId]["images"].push({
                    url: value || `/images/${key.toLowerCase()}.jpeg`,
                    label: key,
                    guidelines: [
                      "Make sure the image is clear and well-lit.",
                      "Accepted formats: JPG, PNG, GIF.",
                      "Maximum file size: 5MB.",
                      "Ensure the image has no watermark or logos.",
                    ],
                  });
                } else {
                  transformedData[productId]["video"] = {
                    url: value || `/images/${key.toLowerCase()}.jpeg`,
                    label: key,
                    guidelines: [
                      "Make sure the image is clear and well-lit.",
                      "Accepted formats: JPG, PNG, GIF.",
                      "Maximum file size: 5MB.",
                      "Ensure the image has no watermark or logos.",
                    ],
                  };
                }
              }
            );

            transformedData[productId]["thumbnail"] = {
              url: "/images/test-tan.webp",
              label: productData.Article_no,
            };
          }
        });
      });

    setLoading(false);
    setSolePastingData(transformedData);
  }, [
    email,
    JSON.stringify(productionStages),
    JSON.stringify(solePastingData),
  ]);

  // Custom guidelines for each image input section
  const guidelines = [
    {
      title: "Image 1 Guidelines",
      items: [
        "Make sure the image is clear and well-lit.",
        "Accepted formats: JPG, PNG.",
        "Maximum file size: 3MB.",
        "Ensure the image has no watermark or logos.",
      ],
    },
    {
      title: "Image 2 Guidelines",
      items: [
        "Image should be taken from a front angle.",
        "Accepted formats: PNG, GIF.",
        "Maximum file size: 4MB.",
        "Ensure the image background is neutral.",
      ],
    },
    {
      title: "Image 3 Guidelines",
      items: [
        "Ensure proper resolution for a high-quality image.",
        "Accepted formats: JPG, PNG.",
        "Maximum file size: 5MB.",
        "Ensure the image is not blurry.",
      ],
    },
  ];

  const handlePopupClose = () => {
    setShowPopup(null);
  };

  const handlePopupOpen = (index) => {
    setShowPopup(index); // Open the popup for the selected image
  };

  const handleViewClick = (item, type) => {
    setSelectedItem({ ...item, type }); // Add the type explicitly to the selected item
    setModalOpen(true);
    console.log("Selected Item:", selectedItem);
  };

  const handleUploadChange = (e, type, index) => {
    const file = e.target.files[0];
    const previewUrl = URL.createObjectURL(file);

    if (type === "video") {
      setUploadData((prev) => ({ ...prev, video: { file, previewUrl } }));
    } else if (type === "image") {
      const updatedImages = [...uploadData.images];
      updatedImages[index] = { file, previewUrl };
      setUploadData((prev) => ({ ...prev, images: updatedImages }));
    }
  };

  const handleUploadSubmit = () => {
    console.log("Uploaded data:", uploadData);
    alert("Upload successful! Check console for details.");
    // Add logic to save/upload the mediaContent to server/API
  };

  const handleMediaDelete = (type, index = null) => {
    if (type === "video") {
      setUploadData((prev) => ({ ...prev, video: null })); // Remove the video
    } else if (type === "image") {
      setUploadData((prev) => {
        const updatedImages = [...prev.images];
        updatedImages[index] = null; // Remove the image at the specified index
        return { ...prev, images: updatedImages };
      });
    }
  };

  if (loading) {
    return (
      <div className="text-center p-4 w-full flex items-center justify-center">
        <img
          className="w-6 h-6 animate-pulse"
          src="/images/soles-active.svg"
          alt="Loading"
        />
      </div>
    );
  }

  if (!solePastingData) {
    return (
      <div className="text-center p-4 text-red-500 text-sm font-semibold">
        Failed to load content.
      </div>
    );
  }

  return (
    <div>
      {/* Display Section */}
      {Object.entries(solePastingData).map(([key, val], index) => (
        <>
          <div
            key={key}
            className="flex md:flex-row max-md:flex-col items-center max-md:gap-0 md:gap-4 max-md:p-2 md:p-4 rounded-lg shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] w-full md:w-max max-md:max-w-80 mt-3 max-md:pt-4"
          >
            {/* Thumbnail */}
            <div className="flex flex-col items-start justify-center w-28 h-28">
              <h3 className="font-semibold text-gray-800 text-center md:text-left text-sm w-full h-5 ">
                {val?.thumbnail?.label.replace(/_/g, " ")}
              </h3>
              <img
                src={val?.thumbnail?.url}
                alt={val?.thumbnail?.label}
                className="w-full  object-cover rounded"
              />
            </div>

            {/* Arrow */}
            <div className="w-[80%] md:w-16 h-0.5 bg-yellow-500 max-md:mb-4"></div>

            {/* Media Containers */}
            <div className="flex flex-wrap items-center max-md:justify-center md:justify-start max-md:gap-2 md:gap-4 w-full">
              {/* Video Container */}
              <div
                className="relative    rounded overflow-hidden group cursor-pointer"
                onClick={() =>
                  handleViewClick(
                    {
                      url: val?.video.url,
                      label: "Video Preview",
                    },
                    "video"
                  )
                }
              >
                <div className=" text-[11px] font-medium">
                  {val?.video?.label}
                </div>
                <img
                  src="/images/Sole-pasting.svg"
                  alt="Video Thumbnail"
                  className="lg:w-32 md:w-24 md:h-24 w-32 h-32 lg:h-32 object-cover opacity-90 bg-black rounded"
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <button className="w-14 h-14 rounded-full shadow-md group-hover:scale-90 duration-300 ease-in-out">
                    <img
                      className="shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                      src="/images/play-button.svg"
                      alt="Play Video"
                    />
                  </button>
                </div>
              </div>

              {/* Image Containers */}
              {val?.images?.map((image, index) => (
                <div
                  key={index}
                  className="relative  cursor-pointer"
                  onClick={() => handleViewClick(image)}
                >
                  <div className=" text-[11px] font-medium">{image?.label}</div>
                  <img
                    src={image?.url}
                    alt={image?.label}
                    className="object-cover lg:w-32 md:w-24 md:h-24 w-32 h-32 lg:h-32 rounded"
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      ))}

      {/* Modal for viewing images or video */}
      {modalOpen && selectedItem && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            className={`bg-white p-6 rounded-lg  pop-up-animation ${
              selectedItem?.type === "video" ? "w-[400px]" : "w-[500px]"
            }`}
          >
            <div className="text-lg font-semibold text-center mb-3">
              {selectedItem.label}

              <button
                className="float-right"
                onClick={() => setModalOpen(false)}
                aria-label="Close"
              >
                <svg
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  className="hover:fill-red-600 hover:bg-red-300 rounded-full duration-300 ease-in-out hover:scale-90 w-4 lg:w-6 h-4 lg:h-6"
                >
                  <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
                  <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
                  <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
                </svg>
              </button>
            </div>
            <div className="space-y-4">
              {selectedItem?.type === "video" ? (
                <video
                  src={selectedItem.url}
                  controls
                  className="w-full rounded object-cover"
                />
              ) : (
                <img
                  src={selectedItem.url}
                  alt={selectedItem.label}
                  className="w-full object-cover rounded"
                />
              )}
            </div>
          </div>
        </div>
      )}

      {/* Upload Section */}
      {Object.entries(solePastingData).map(([key, val], index) => (
        <div className="flex md:flex-row max-md:flex-col items-center max-md:gap-0 md:gap-4 max-md:p-2 md:p-4 rounded-lg shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] w-full md:w-max max-md:max-w-80 mt-3 max-md:pt-4">
          {/* Thumbnail */}
          <div className="flex flex-col items-start justify-center w-28 h-28">
            <h3 className="font-semibold text-gray-800 text-center md:text-left text-sm w-full h-5 ">
              {val?.thumbnail?.label.replace(/_/g, " ")}
            </h3>
            <img
              src={val?.thumbnail?.url}
              alt={val?.thumbnail?.label}
              className="w-full  object-cover rounded"
            />
          </div>
          {/* Arrow */}
          <div className="w-[80%] md:w-16 h-0.5 bg-yellow-500 max-md:mb-4"></div>
          {/* Video Upload */}
          {/* Image Uploads */}
          <div className="flex flex-wrap items-center max-md:justify-center md:justify-start max-md:gap-2 md:gap-4 w-full">
            <div className="flex flex-col items-start justify-center lg:w-32 md:w-24 md:h-24 w-32 h-32 lg:h-32 border rounded overflow-hidden">
              {uploadData.video ? (
                <div className="h-full w-full relative">
                  <video
                    src={uploadData.video.previewUrl}
                    controls
                    className="w-full h-full object-cover rounded "
                  ></video>
                  {/* delete  */}
                  <button
                    className="absolute top-1 right-1   px-1 py-1 flex items-center border-[1px]  border-gray-200 bg-gray-50 hover:border-red-200  hover:bg-gray-100   justify-center  overflow-hidden text-black duration-300 group ease-in-out text-xs md:text-sm font-semibold cursor-pointer rounded-full"
                    onClick={() => handleMediaDelete("video")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="fi_3405244"
                      data-name="Layer 2"
                      className="w-4 h-4  fill-[#000] group-hover:fill-[#ff4444] duration-300 ease-in-out"
                      viewBox="0 0 24 24"
                    >
                      <path d="M19,7a1,1,0,0,0-1,1V19.191A1.92,1.92,0,0,1,15.99,21H8.01A1.92,1.92,0,0,1,6,19.191V8A1,1,0,0,0,4,8V19.191A3.918,3.918,0,0,0,8.01,23h7.98A3.918,3.918,0,0,0,20,19.191V8A1,1,0,0,0,19,7Z"></path>
                      <path d="M20,4H16V2a1,1,0,0,0-1-1H9A1,1,0,0,0,8,2V4H4A1,1,0,0,0,4,6H20a1,1,0,0,0,0-2ZM10,4V3h4V4Z"></path>
                      <path d="M11,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z"></path>
                      <path d="M15,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z"></path>
                    </svg>
                  </button>
                </div>
              ) : (
                <div className="relative flex items-center w-full h-full justify-center ">
                  <input
                    type="file"
                    accept="video/*"
                    onChange={(e) => handleUploadChange(e, "video")}
                    className="mt-2 opacity-0 cursor-pointer z-10 w-full h-full"
                  />
                  <div className="absolute flex flex-col items-center justify-center gap-y-1 z-0">
                    <img
                      src="/images/addvideo.svg"
                      className="w-9 h-9 cursor-pointer"
                      alt=""
                    />
                    <p className="text-[10px] font-medium ">Upload Video</p>
                  </div>
                </div>
              )}
            </div>

            {uploadData.images.map((image, index) => (
              <div
                key={index}
                className="relative lg:w-32 md:w-24 md:h-24 w-32 h-32 lg:h-32 border rounded overflow-hidden cursor-pointer"
              >
                {image ? (
                  <div className="h-full w-full relative">
                    <img
                      src={image.previewUrl}
                      alt={`Uploaded Image ${index + 1}`}
                      className="object-cover h-full w-full rounded "
                    />
                    {/* delete  */}
                    <button
                      className="absolute top-1 right-1 z-40  px-1 py-1 flex items-center border-[1px]  border-gray-200 bg-gray-50 hover:border-red-200  hover:bg-gray-100   justify-center  overflow-hidden text-black duration-300 group ease-in-out text-xs md:text-sm font-semibold cursor-pointer rounded-full"
                      onClick={() => handleMediaDelete("image", index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="fi_3405244"
                        data-name="Layer 2"
                        className="w-4 h-4  fill-[#000] group-hover:fill-[#ff4444] duration-300 ease-in-out"
                        viewBox="0 0 24 24"
                      >
                        <path d="M19,7a1,1,0,0,0-1,1V19.191A1.92,1.92,0,0,1,15.99,21H8.01A1.92,1.92,0,0,1,6,19.191V8A1,1,0,0,0,4,8V19.191A3.918,3.918,0,0,0,8.01,23h7.98A3.918,3.918,0,0,0,20,19.191V8A1,1,0,0,0,19,7Z"></path>
                        <path d="M20,4H16V2a1,1,0,0,0-1-1H9A1,1,0,0,0,8,2V4H4A1,1,0,0,0,4,6H20a1,1,0,0,0,0-2ZM10,4V3h4V4Z"></path>
                        <path d="M11,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z"></path>
                        <path d="M15,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z"></path>
                      </svg>
                    </button>
                  </div>
                ) : (
                  <div className="relative flex items-center w-full h-full justify-center ">
                    <div
                      className="flex flex-col items-center justify-center gap-y-1 z-0 w-full h-full cursor-pointer"
                      onClick={() => handlePopupOpen(index)}
                    >
                      <img
                        src="/images/addimage.svg"
                        className="w-9 h-9 cursor-pointer"
                        alt=""
                      />
                      <p className="text-[10px] font-medium ">Upload Image</p>
                    </div>
                    {showPopup === index && (
                      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div class="pop-up-animation [--shadow:rgba(60,64,67,0.3)_0_1px_2px_0,rgba(60,64,67,0.15)_0_2px_6px_2px] w-4/5 h-auto  rounded-2xl bg-white [box-shadow:var(--shadow)] max-w-[300px]">
                          <div class="flex flex-col items-center justify-between pt-9 px-6 pb-6 relative ">
                            <span class="relative mx-auto -mt-16 mb-4">
                              <img
                                src="/images/image-tnc.svg"
                                className="w-16 h-16"
                                alt=""
                              />
                            </span>

                            <h5 class="text-sm font-semibold mb-2 text-left mr-auto text-zinc-700">
                              {guidelines[index].title}
                            </h5>

                            <p class="w-full mb-4 text-xs text-justify">
                              {guidelines[index].items.map((item, i) => (
                                <p key={i}>{item}</p>
                              ))}
                            </p>
                            <div className="flex items-end justify-between w-full">
                              <button
                                onClick={handlePopupClose}
                                class="mb-2 text-sm mr-auto text-zinc-600 cursor-pointer font-medium transition-colors hover:text-yellow-400 hover:underline underline-offset-2"
                              >
                                Cancel
                              </button>
                              <button
                                class="relative font-semibold fcursor-pointer py-2 px-8 w-max break-keep text-sm rounded-lg transition-colors duration-300 ease-in-out text-gray-700 hover:text-gray-800 bg-yellow-400 hover:bg-yellow-500"
                                type="button"
                              >
                                Upload
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleUploadChange(e, "image", index)
                                  }
                                  className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer z-10 "
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}

      <button
        className="mt-4 px-4 py-2 bg-yellow-500 text-white font-semibold rounded"
        onClick={handleUploadSubmit}
      >
        Upload Media
      </button>
    </div>
  );
};

export default SolePasting;
