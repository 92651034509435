import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Sourcing from "./Sourcing.jsx";
import Branding from "./Branding.jsx";
import UpperStitching from "./UpperStitching.jsx";
import SolePasting from "./SolePasting.jsx";
import Finishing from "./Finishing.jsx";
import Packaging from "./Packaging.jsx";
import ReadytoShip from "./ReadytoShip.jsx";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import secureLocalStorage from "react-secure-storage";
import crypto from "crypto-js";
import ChatPopup from "./ChatPopup.jsx";
import { useLocation } from "react-router-dom";

const ProductionStepper = () => {
  let productionStages = JSON.parse(
    secureLocalStorage.getItem("production_stages")
  );
  let userData = JSON.parse(secureLocalStorage.getItem("kycData"));
  let Customize = JSON.parse(secureLocalStorage.getItem("Customize"));
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [lastCompletedStep, setLastCompletedStep] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const location = useLocation();
  const hash = location.hash.substring(1);
  const { email, type, currency } = CheckAuth({ hash });
  const [orders, setOrders] = useState([]);
  const [jobSheets, setJobSheets] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(
    jobSheets?.[0]?.title || 0
  );
  const { orderId } = useParams();

  const slabDiscount = (quantity, price) => {
    let discount = 0;
    if (quantity >= 40 && quantity < 80) discount = 5;
    else if (quantity >= 80 && quantity < 120) discount = 10;
    else if (quantity >= 120 && quantity < 160) discount = 15;
    else if (quantity >= 160 && quantity < 200) discount = 20;
    else if (quantity >= 200 && quantity < 500) discount = 25;
    else if (quantity >= 500) discount = 30;

    const discount_amount = (discount / 100) * price;
    return discount_amount;
  };

  const calculateTotal_GST = (type, data, stateCode) => {
    let total = 0;
    let percent = 0;
    let igst = 0;
    let cgst = 0;
    let sgst = 0;
    let sdc = 0;
    let moq = 0;
    let discount = 0;
    let totalDiscount = 0;
    let quantity = 0;
    let price =
      data.Leather.price +
      data.Construction.price +
      data.Edge.price +
      data.Finish.price +
      data.Lining.price +
      data.Sole.price +
      data.Sole_thickness.price +
      data.Welt.price +
      data.Shoelace.price +
      data.Buckles.price +
      data.Eyelets.price +
      (type === "individual" ? 7000 : 0);
    moq = Customize?.find(
      (data) => data["Article_no"] === data.Article_no
    )?.MOQ;
    Object.entries(data?.Size)?.map(([key, val]) => {
      quantity += val;
    });
    if (type !== "individual") {
      discount = quantity <= moq ? 0 : slabDiscount(quantity, price);
    }
    if (price > 1000) percent = 0.18;
    else percent = 0.12;

    if (type === "individual") {
      cgst += price * (percent / 2) * quantity;
      sgst += price * (percent / 2) * quantity;
    } else {
      if (stateCode) {
        if (stateCode === "07") {
          cgst += price * (percent / 2) * quantity;
          sgst += price * (percent / 2) * quantity;
        } else {
          igst += price * percent * quantity;
        }
      }
    }
    total += price * quantity;
    totalDiscount += discount * quantity;
    const sdcPrice = type === "individual" || quantity <= moq ? 7000 : 0;
    sdc += sdcPrice * quantity;
    if (type === "individual") {
      return {
        "Base Price": total,
        IGST: igst,
        CGST: cgst,
        SGST: sgst,
      };
    } else {
      return {
        "Base Price": total,
        "Sample Development Charges": sdc,
        "Bulk Order Discount": totalDiscount,
        IGST: igst,
        CGST: cgst,
        SGST: sgst,
      };
    }
  };

  const handleNextStep = () => {
    setCompletedSteps((prev) => [...new Set([...prev, currentStep])]);
    setLastCompletedStep(currentStep);
    setCurrentStep(currentStep + 1);
  };

  const steps =
    type === "individual"
      ? [
          {
            id: 1,
            label: "Sourcing",
            component: (
              <Sourcing orderId={orderId} onNext={() => handleNextStep()} />
            ),
            icon: "/images/sourcing.svg",
            activeIcon: "/images/sourcing-active.svg",
          },
          {
            id: 2,
            label: "Upper Stitching",
            component: (
              <UpperStitching
                orderId={orderId}
                onNext={() => handleNextStep()}
              />
            ),
            icon: "/images/leather.svg",
            activeIcon: "/images/leather-active.svg",
          },
          {
            id: 3,
            label: "Sole Pasting",
            component: (
              <SolePasting orderId={orderId} onNext={() => handleNextStep()} />
            ),
            icon: "/images/soles.svg",
            activeIcon: "/images/soles-active.svg",
          },
          {
            id: 4,
            label: "Finishing",
            component: (
              <Finishing orderId={orderId} onNext={() => handleNextStep()} />
            ),
            icon: "/images/finishing.svg",
            activeIcon: "/images/finishing-active.svg",
          },
          {
            id: 5,
            label: "Packaging",
            component: (
              <Packaging orderId={orderId} onNext={() => handleNextStep()} />
            ),
            icon: "/images/package.svg",
            activeIcon: "/images/package-active.svg",
          },
          {
            id: 6,
            label: "Ready to Ship",
            component: (
              <ReadytoShip orderId={orderId} onNext={() => handleNextStep()} />
            ),
            icon: "/images/delivery.svg",
            activeIcon: "/images/delivery-active.svg",
          },
        ]
      : [
          {
            id: 1,
            label: "Sourcing",
            component: (
              <Sourcing orderId={orderId} onNext={() => handleNextStep()} />
            ),
            icon: "/images/sourcing.svg",
            activeIcon: "/images/sourcing-active.svg",
          },
          {
            id: 2,
            label: "Branding",
            component: (
              <Branding orderId={orderId} onNext={() => handleNextStep()} />
            ),
            icon: "/images/branding.svg",
            activeIcon: "/images/branding-active.svg",
          },
          {
            id: 3,
            label: "Upper Stitching",
            component: (
              <UpperStitching
                orderId={orderId}
                onNext={() => handleNextStep()}
              />
            ),
            icon: "/images/leather.svg",
            activeIcon: "/images/leather-active.svg",
          },
          {
            id: 4,
            label: "Sole Pasting",
            component: (
              <SolePasting orderId={orderId} onNext={() => handleNextStep()} />
            ),
            icon: "/images/soles.svg",
            activeIcon: "/images/soles-active.svg",
          },
          {
            id: 5,
            label: "Finishing",
            component: (
              <Finishing orderId={orderId} onNext={() => handleNextStep()} />
            ),
            icon: "/images/finishing.svg",
            activeIcon: "/images/finishing-active.svg",
          },
          {
            id: 6,
            label: "Packaging",
            component: (
              <Packaging orderId={orderId} onNext={() => handleNextStep()} />
            ),
            icon: "/images/package.svg",
            activeIcon: "/images/package-active.svg",
          },
          {
            id: 7,
            label: "Ready to Ship",
            component: (
              <ReadytoShip orderId={orderId} onNext={() => handleNextStep()} />
            ),
            icon: "/images/delivery.svg",
            activeIcon: "/images/delivery-active.svg",
          },
        ];

  useEffect(() => {
    if (productionStages && productionStages !== "") {
      setOrders(productionStages);
      const order = productionStages.find(
        (order) =>
          order.Order_id.toLowerCase() ===
          orderId.replaceAll("_", "/").toLowerCase()
      );
      const modified_jobSheet = Object.values(order.Products).map((data) => ({
        title: data.Article_no,
        sizes: data?.Size,
        totalPrice: Object.entries(
          calculateTotal_GST(
            type,
            data,
            userData?.contact_details?.gst.slice(0, 2)
          )
        ).reduce((sum, [key, val]) => {
          return key === "Bulk Order Discount" ? sum - val : sum + val;
        }, 0),
        details: {
          Upper: {
            Leather: data?.Leather?.type || "NA",
            Color: data?.color_leather || "NA",
            Finish: data?.Finish?.type || "NA",
            Lining: data?.Lining?.type || "NA",
          },
          Sole: {
            Type: data?.Sole?.type || "NA",
            Thickness: data?.Sole_thickness?.type || "NA",
            Construction: data?.Construction?.type || "NA",
            Welt: data?.Welt?.type || "NA",
            Edge: data?.Edge?.type || "NA",
          },
          Trims: {
            Eyelets: data?.Eyelets?.type || "NA",
            Shoelace: data?.Shoelace?.type || "NA",
            Buckles: data?.Buckles?.type || "NA",
          },
        },
      }));
      setJobSheets(modified_jobSheet);
      setOpenAccordion(jobSheets?.[0]?.title);
      if (type !== "individual") {
        if (order.Stage === "Sourcing") {
          setCurrentStep(1);
          setLastCompletedStep(null);
          setCompletedSteps([]);
        } else if (order.Stage === "Branding") {
          setCurrentStep(2);
          setLastCompletedStep(1);
          setCompletedSteps([1]);
        } else if (order.Stage === "Upper Stitching") {
          setCurrentStep(3);
          setLastCompletedStep(2);
          setCompletedSteps([1, 2]);
        } else if (order.Stage === "Sole Pasting") {
          setCurrentStep(4);
          setCompletedSteps([1, 2, 3]);
          setLastCompletedStep(3);
        } else if (order.Stage === "Finishing") {
          setCurrentStep(5);
          setCompletedSteps([1, 2, 3, 4]);
          setLastCompletedStep(4);
        } else if (order.Stage === "Packaging") {
          setCurrentStep(6);
          setCompletedSteps([1, 2, 3, 4, 5]);
          setLastCompletedStep(5);
        } else if (order.Stage === "Ready to Ship") {
          setCurrentStep(7);
          setCompletedSteps([1, 2, 3, 4, 5, 6]);
          setLastCompletedStep(6);
        }
      } else {
        if (order.Stage === "Sourcing") {
          setCurrentStep(1);
          setLastCompletedStep(null);
          setCompletedSteps([]);
        } else if (order.Stage === "Upper Stitching") {
          setCurrentStep(2);
          setLastCompletedStep(1);
          setCompletedSteps([1]);
        } else if (order.Stage === "Sole Pasting") {
          setCurrentStep(3);
          setLastCompletedStep(2);
          setCompletedSteps([1, 2]);
        } else if (order.Stage === "Finishing") {
          setCurrentStep(4);
          setCompletedSteps([1, 2, 3]);
          setLastCompletedStep(3);
        } else if (order.Stage === "Packaging") {
          setCurrentStep(5);
          setCompletedSteps([1, 2, 3, 4]);
          setLastCompletedStep(4);
        } else if (order.Stage === "Ready to Ship") {
          setCurrentStep(6);
          setCompletedSteps([1, 2, 3, 4, 5]);
          setLastCompletedStep(5);
        }
      }
    }
  }, [email, JSON.stringify(productionStages)]);

  const handleGoToStep = (step) => {
    if (step <= currentStep || completedSteps.includes(step - 1)) {
      setCurrentStep(step);
    }
  };

  useEffect(() => {
    setIsAnimating(false);
  }, [currentStep]);

  const toggleAccordion = (id) => {
    setOpenAccordion((prev) => (prev === id ? null : id));
  };


  const [showLeftFade, setShowLeftFade] = useState(false);
  const [showRightFade, setShowRightFade] = useState(false);
  const containerRef = useRef(null);

  // Check if content is scrollable and update fade states
  const checkScroll = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setShowLeftFade(scrollLeft > 0);
      setShowRightFade(scrollLeft < scrollWidth - clientWidth);
    }
  };

  // Initial check and add scroll listener
  useEffect(() => {
    checkScroll();
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", checkScroll);
      // Also check on window resize
      window.addEventListener("resize", checkScroll);

      return () => {
        container.removeEventListener("scroll", checkScroll);
        window.removeEventListener("resize", checkScroll);
      };
    }
  }, []);

  return (
    <div className="max-md:p-2 md:p-6  min-h-screen ">
      {/* Stepper UI */}
      <div className="relative  md:mb-20 mt-4">
        {/* Left fade */}
        {showLeftFade && (
          <div className="absolute left-0 top-0 bottom-0 w-20 bg-gradient-to-r from-white via-white/60 to-transparent pointer-events-none z-10" />
        )}

        {/* Scrollable content */}
        <div
          ref={containerRef}
          className="flex items-start  max-md:justify-start md:justify-center  max-md:w-full max-md:h-24 max-md:overflow-x-scroll no-scrollbar max-md:pl-2"
        >
          {steps.map((step, index) => (
            <div
              key={step.id}
              className="flex items-center justify-center cursor-pointer "
              onClick={() => handleGoToStep(step.id)}
              role="button"
              aria-label={`Go to ${step.label}`}
            >
              {/* Step Circle */}
              <div
                className={`flex items-center justify-center w-10 md:w-12 lg:w-16 xl:w-20 2xl:w-24 px-2 rounded-full relative ${
                  completedSteps.includes(step.id)
                    ? "  text-[#ffc300] font-semibold"
                    : currentStep === step.id
                    ? "  text-[#343434] font-semibold"
                    : "text-[#343434]  "
                }`}
              >
                <div
                  className={`w-5 md:w-6 lg:w-8  h-5 md:h-6 lg:h-8  2xl:w-10 2x:h-10 ${
                    lastCompletedStep === step.id ? "blink-animation" : ""
                  }`}
                >
                  {completedSteps.includes(step.id) ? (
                    <img src={step.activeIcon} alt={`${step.label} active`} />
                  ) : (
                    <img src={step.icon} alt={`${step.label} inactive`} />
                  )}
                </div>
                {/* Step Label */}
                <div
                  className={`absolute top-12 w-14  md:w-20 lg:w-24 xl:w-28 2xl:w-32 text-center text-[10px] xl:text-xs max-lg:tracking-tighter leading-3  ${
                    lastCompletedStep === step.id ? "blink-animation" : ""
                  }`}
                >
                  {step.label}
                </div>
              </div>

              {/* Connector */}
              {index < steps.length - 1 && (
                <div
                  className={`h-[1px] md:h-[2px] relative w-8  md:w-12 lg:w-16 xl:w-20 2xl:w-24  border-dashed border-[1px] border-gray-400 ${
                    completedSteps.includes(step.id) ? "border-none " : ""
                  }`}
                >
                  <div
                    className={`absolute h-[2px] md:h-[3px]  w-1  transition-all ease-in-out duration-1000 rounded-full ${
                      completedSteps.includes(step.id)
                        ? " w-8  md:w-12 lg:w-16 xl:w-20 2xl:w-24 bg-[#ffc300]"
                        : ""
                    }`}
                  ></div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Right fade */}
        {showRightFade && (
          <div className="absolute right-0 top-0 bottom-0 w-20 bg-gradient-to-l from-white via-white/60 to-transparent pointer-events-none z-10" />
        )}
      </div>

      {/* Step Content */}
      <div
        className={`  bg-white  flex gap-x-5 transition-transform duration-1000 ${
          isAnimating ? "fade-section" : ""
        }`}
      >
        {" "}
        <div className="rounded-lg border max-md:p-2 md:p-4 w-full">
          {steps[currentStep - 1]?.component || (
            <div className="text-center text-gray-500">Step not found</div>
          )}
        </div>
        {/* <div className="job-sheet-container p-4 w-[400px] bg-white  rounded-lg border ">
          <h2 className="text-sm text-center font-bold mb-4">Job Sheet</h2>
          {jobSheets.map((sheet) => (
            <div
              key={sheet.title}
              className={`accordion-item text-xs border rounded-md mb-2 ${
                openAccordion === sheet.title ? "bg-white" : ""
              }`}
            >
              <div
                className="accordion-header flex justify-between items-center p-3 cursor-pointer"
                onClick={() => toggleAccordion(sheet.title)}
              >
                <h3 className="text-xs font-medium">{sheet.title}</h3>
                <span className="text-sm">
                  <img
                    src="/images/next.svg"
                    className={`w-4 h-4 transition-all duration-700 ease-in-out ${
                      openAccordion === sheet.title ? "-rotate-90" : "rotate-90"
                    }`}
                    alt=""
                  />
                </span>
              </div>
              <div
                className={`accordion-content overflow-y-scroll no-scrollbar text-xs transition-all duration-700 ease-in-out  ${
                  openAccordion === sheet.title ? "h-[300px] " : "h-0  "
                }`}
              >
                <div className="flex flex-col justify-start items-start p-3">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="border-[1px] border-gray-200">Size</th>
                        <th className="border-[1px] border-gray-200">
                          Quantity
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(sheet.sizes).map(([k, v]) => (
                        <tr key={k}>
                          <td className="text-center border-[1px] border-gray-200">
                            {`${k.split("_")[1]} ${k.split("_")[0]}`}
                          </td>
                          <td className="text-center border-[1px] border-gray-200">
                            {v}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <h3 className="text-xs font-medium pt-2">
                    Total Price: Rs.{" "}
                    {sheet.totalPrice?.toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </h3>
                </div>
                <div className=" px-2 py-2 text-xs space-y-0.5 ">
                  <div className="flex items-center justify-start gap-x-2 font-semibold text-gray-900">
                    <img
                      src="/images/leather-active.svg"
                      className="w-4  h-4 "
                      alt=""
                    />
                    Upper
                  </div>
                  {Object.entries(sheet.details.Upper).map(([key, value]) => (
                    <p key={key} className="flex items-end">
                      <div className="pl-2 text-xs w-28">{key}</div>
                      <div className="font-medium pl-2 text-black">{value}</div>
                    </p>
                  ))}
                </div>
                <div className=" px-2 py-2 text-xs space-y-0.5 ">
                  <div className="flex items-center justify-start gap-x-2 font-semibold text-gray-900">
                    <img
                      src="/images/soles-active.svg"
                      className="w-4  h-4 "
                      alt=""
                    />
                    Sole
                  </div>
                  {Object.entries(sheet.details.Sole).map(([key, value]) => (
                    <p key={key} className="flex items-end">
                      <div className="pl-2 text-xs w-28">{key}</div>
                      <div className="font-medium pl-2 text-black">{value}</div>
                    </p>
                  ))}
                </div>
                <div className=" px-2 py-2 text-xs space-y-0.5 ">
                  <div className="flex items-center justify-start gap-x-2 font-semibold text-gray-900">
                    <img
                      src="/images/leather-active.svg"
                      className="w-4  h-4 "
                      alt=""
                    />
                    Trims
                  </div>
                  {Object.entries(sheet.details.Trims).map(([key, value]) => (
                    <p key={key} className="flex items-end">
                      <div className="pl-2 text-xs w-28">{key}</div>
                      <div className="font-medium pl-2 text-black">{value}</div>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div> */}
      </div>

      <ChatPopup orderId={orderId} />
    </div>
  );
};

export default ProductionStepper;
