import React from "react";
import { IoClose } from "react-icons/io5";

function RequestSent({ onClose }) {
  return (
    <div className="popup-container">
      <div className="p-5 rounded-2xl  bg-white w-full md:max-w-[400px] pop-up-animation mx-2 relative">
        <span
          onClick={() => onClose()}
          className="bg-[#ffffff] hover:bg-red-300 border-[1px] border-zinc-400 hover:border-red-500 shadow-sm hover:shadow p-1 rounded-full text-black hover:scale-95 duration-300 cursor-pointer absolute -right-2 -top-2 z-10"
        >
          <IoClose className="text-xl" />
        </span>
        <div className="flex flex-col justify-between items-center">
          <span>
            <svg
              className="w-12 h-12"
              clip-rule="evenodd"
              fill-rule="evenodd"
              stroke-linejoin="round"
              stroke-miterlimit="2"
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
              id="fi_10828604"
            >
              <g transform="translate(-144 -48)">
                <g id="Icon">
                  <g transform="matrix(1.023 0 0 1.023 -298.093 -394.093)">
                    <circle
                      cx="455.5"
                      cy="455.5"
                      fill="#15803D"
                      r="21.5"
                    ></circle>
                  </g>
                  <g transform="translate(-288 -382)">
                    <path
                      d="m447.109 452.336c-.81-.541-1.892-.418-2.562.29-.669.707-.732 1.795-.147 2.574l6 8c.362.482.922.776 1.525.799.602.022 1.183-.228 1.58-.682l14-16c.691-.789.654-1.978-.084-2.724s-1.926-.794-2.723-.112l-12.849 11.014s-4.74-3.159-4.74-3.159z"
                      fill="#fafafa"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <h2 className="text-green-700 font-semibold">Request Sent</h2>
        </div>
        <p className="text-sm text-gray-600 text-center mt-2">
          You will get a response from our team on your registered mail id
          withins <span className="font-semibold text-[#000000]">72 hrs.</span>{" "}
        </p>
      </div>
    </div>
  );
}

export default RequestSent;
