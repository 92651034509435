import { useState, useRef, useEffect } from "react";
import Images from "./Images";
import LeatherSelection from "./LeatherSelection";
import Sole from "./Product-sole";
import Others from "./Others";
import "./ProductListing.css";
import CarouselCard from "./CarouselCard";
import secureLocalStorage from "react-secure-storage";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import UploadGLBFile from "./Customize.jsx";
import { useLocation } from "react-router-dom";

const statuses = [
  { status: "Status", color: "#FFFFFF" }, // Orange
  { status: "Pending", color: "#FFA500" }, // Orange
  { status: "Approved", color: "#008000" }, // Green
  { status: "Rejected", color: "#FF0000" }, // Red
  { status: "Unlisted", color: "#808080" }, // Gray
  { status: "Archived", color: "#000" }, // Black
];

const transformCustomizeToProductLocal = (customize, productId) => {
  const matchingEntry = customize.find((entry) => {
    return entry?.Article_no === productId;
  });
  let productLocal = null;
  if (!matchingEntry) {
    return null;
  } else {
    productLocal = {
      Article_no: matchingEntry.Article_no,
      Leather: matchingEntry.Leather,
      Sole: matchingEntry.Sole,
      Edge: matchingEntry.Edge,
      Lining: matchingEntry.Lining,
      Eyelets: matchingEntry.Eyelets,
      Buckle: matchingEntry.Buckle,
      Shoelace: matchingEntry.Shoelace,
    };
  }
  return productLocal;
};

const ProductListing = () => {
  const location = useLocation();
  const hash = location.hash.substring(1);
  const { email, type, phone } = CheckAuth({ hash });
  let materialCost = JSON.parse(secureLocalStorage.getItem("Material_cost"));
  let Customize = JSON.parse(secureLocalStorage.getItem("Customize"));
  let Catalogue = JSON.parse(secureLocalStorage.getItem("Catalogue"));
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [activeMode, setActiveMode] = useState("view");
  const [cardData, setCardData] = useState([]);
  const [cardData2, setCardData2] = useState([]);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [deletedItem, setDeletedItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isOpenAdmin, setIsOpenAdmin] = useState(false);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState("Boots");
  const [selectedAdmin, setSelectedAdmin] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("Status");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [relistType, setRelistType] = useState("");
  const dropdownRef = useRef(null);
  const adminRef = useRef(null);
  const statusRef = useRef(null);
  const [subCategory, setSubCategory] = useState();
  const [editListingId, setEditListingId] = useState(null);
  const [showPopup, setShowPopup] = useState("");
  const [resendPhoneTimer, setResendPhoneTimer] = useState(90);
  const [otp, setOtp] = useState();
  const [userOtp, setUserOtp] = useState();
  const [flipped, setFlipped] = useState(false);
  const cardRef = useRef(null);
  const sidebarRef = useRef(null);

  const transformCustomizeToCardData = (Customize, Catalogue) => {
    const customizeArticles = new Set(
      Customize?.map((item) => item?.Article_no)
    );
    const catalogueArticles = new Set(
      Catalogue?.map((item) => item?.Article_no)
    );

    const commonArticles = Array.from(
      new Set([...customizeArticles, ...catalogueArticles])
    );

    // Transform data
    const transformedData = commonArticles
      .map((article) => {
        const customizeItems = Customize.filter(
          (item) =>
            item?.Article_no === article &&
            item?.Article_no?.toLowerCase().includes(
              selectedStyle?.toLowerCase()
            )
        );
        const catalogueItems = Catalogue.filter(
          (item) =>
            item?.Article_no === article &&
            item?.Article_no?.toLowerCase().includes(
              selectedStyle?.toLowerCase()
            )
        );
        const combinations = [];

        customizeItems.forEach((item) => {
          combinations.push({
            type: "Customize",
            img: "/images/boot-s.jpg",
            edit: item.Edit || false,
            Status: item.Status,
            Message: item.Reason || "",
            color:
              statuses.find((s) => s.status === item.Status)?.color || "#fff",
          });
        });

        catalogueItems.forEach((item) => {
          if (item.Status !== "") {
            combinations.push({
              type: "Catalogue",
              img: item.images.Main,
              Status: item.Status,
              Message: item.Reason || "",
              color:
                statuses.find((s) => s.status === item.Status)?.color || "#fff",
              user: item.Supplier_names,
              email: item.Supplier_emails,
              edit: item.Edit || false,
            });
          }
        });

        if (combinations?.length > 0) {
          return {
            [article]: combinations,
          };
        }
        return null;
      })
      .filter(Boolean); // Remove null entries from the array

    return transformedData;
  };

  const transformCustomizeToCardData2 = (Customize, Catalogue) => {
    const deletedCatalogueArticles = Array.from(
      new Set(Catalogue?.map((item) => item.Article_no))
    );

    // Transform data
    const transformedData = deletedCatalogueArticles
      .map((article) => {
        const catalogueItems = Catalogue.filter(
          (item) =>
            item?.Article_no === article &&
            item?.Article_no?.toLowerCase().includes(
              selectedStyle?.toLowerCase()
            )
        );
        const combinations = [];

        catalogueItems.forEach((item) => {
          if (item.Status === "") {
            combinations.push({
              type: "Catalogue",
              img: item.images.Main,
              Status: item.Status,
              Message: item.Reason || "",
              color:
                statuses.find((s) => s.status === item.Status)?.color || "#fff",
              user: item.Supplier_names,
              email: item.Supplier_emails,
              edit: item.Edit || false,
            });
          }
        });

        if (combinations?.length > 0) {
          return {
            [article]: combinations,
          };
        }
        return null;
      })
      .filter(Boolean); // Remove null entries from the array

    return transformedData;
  };

  useEffect(() => {
    fetch(`https://buwchlifestyles.com/api/get-all-users/`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to send OTP");
        }
        return response.json();
      })
      .then((response) => {
        const suppliers = response.data
          .filter(
            (user) =>
              user?.usertype?.toLowerCase() === "admin" ||
              user?.usertype?.toLowerCase() === "supplier"
          )
          .map((user) => ({ email: user.email, username: user.username }));
        setAllSuppliers(suppliers);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, [relistType]);

  useEffect(() => {
    let updatedCardData = transformCustomizeToCardData(Customize, Catalogue);
    setCardData(updatedCardData);
    let updatedCardData2 = transformCustomizeToCardData2(Customize, Catalogue);
    setCardData2(updatedCardData2);
  }, [selectedStyle, JSON.stringify(Catalogue)]);

  useEffect(() => {
    if (editListingId !== null) {
      transformCustomizeToProductLocal(Customize, editListingId);
    }
  }, [editListingId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      if (adminRef.current && !adminRef.current.contains(event.target)) {
        setIsOpenAdmin(false);
      }
      if (statusRef.current && !statusRef.current.contains(event.target)) {
        setIsOpenStatus(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to close the card when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setFlipped(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    Customize = JSON.parse(secureLocalStorage.getItem("Customize"));
    Catalogue = JSON.parse(secureLocalStorage.getItem("Catalogue"));
    let updatedCardData = transformCustomizeToCardData(Customize, Catalogue);
    setCardData(updatedCardData);
    let updatedCardData2 = transformCustomizeToCardData2(Customize, Catalogue);
    setCardData2(updatedCardData2);
  }, [currentStep, activeMode]);

  const handleNextStep = (val, id) => {
    setEditListingId(id);
    if (val === selectedStyle) {
      setCompletedSteps((prev) => [...new Set([...prev, currentStep])]);
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentStep((prevStep) => (prevStep < 5 ? prevStep + 1 : prevStep));
        setIsTransitioning(false);
      }, 300);
    } else if (val === "home") {
      setActiveMode("view");
      setCompletedSteps([]);
      setCurrentStep(1);
      setFlipped(false);
    }
  };

  const goToStep = (step) => {
    if (step <= currentStep || completedSteps.includes(step - 1)) {
      setIsTransitioning(true); // Start transition
      setTimeout(() => {
        setCurrentStep(step);
        setIsTransitioning(false); // End transition after animation duration
      }, 300); // Match this timeout to the CSS animation duration
    }
  };

  const renderStepContent = () => {
    const stepContent =
      type === "admin"
        ? {
            1: (
              <LeatherSelection
                onNext={handleNextStep}
                style={selectedStyle}
                ProductId={editListingId}
                materialCost={materialCost}
                subCategory={subCategory}
                action={
                  isNaN(editListingId?.split("_")?.pop()[0]) ? "" : activeMode
                }
              />
            ),
            2: (
              <Sole
                onNext={handleNextStep}
                style={selectedStyle}
                ProductId={editListingId}
                materialCost={materialCost}
                subCategory={subCategory}
                action={
                  isNaN(editListingId?.split("_")?.pop()[0]) ? "" : activeMode
                }
              />
            ),
            3: (
              <Others
                onNext={handleNextStep}
                style={selectedStyle}
                ProductId={editListingId}
                materialCost={materialCost}
                subCategory={subCategory}
                action={
                  isNaN(editListingId?.split("_")?.pop()[0]) ? "" : activeMode
                }
              />
            ),
            4: (
              <UploadGLBFile
                onNext={handleNextStep}
                style={selectedStyle}
                ProductId={editListingId}
                materialCost={materialCost}
                subCategory={subCategory}
                action={
                  isNaN(editListingId?.split("_")?.pop()[0]) ? "" : activeMode
                }
              />
            ),
            5: (
              <Images
                onNext={handleNextStep}
                style={selectedStyle}
                ProductId={editListingId}
                materialCost={materialCost}
                subCategory={subCategory}
                action={
                  isNaN(editListingId?.split("_")?.pop()[0]) ? "" : activeMode
                }
              />
            ),
          }
        : {
            1: (
              <LeatherSelection
                onNext={handleNextStep}
                style={selectedStyle}
                ProductId={editListingId}
                materialCost={materialCost}
                subCategory={subCategory}
                action={
                  isNaN(editListingId?.split("_")?.pop()[0]) ? "" : activeMode
                }
              />
            ),
            2: (
              <Sole
                onNext={handleNextStep}
                style={selectedStyle}
                ProductId={editListingId}
                materialCost={materialCost}
                subCategory={subCategory}
                action={
                  isNaN(editListingId?.split("_")?.pop()[0]) ? "" : activeMode
                }
              />
            ),
            3: (
              <Others
                onNext={handleNextStep}
                style={selectedStyle}
                ProductId={editListingId}
                materialCost={materialCost}
                subCategory={subCategory}
                action={
                  isNaN(editListingId?.split("_")?.pop()[0]) ? "" : activeMode
                }
              />
            ),
            4: (
              <Images
                onNext={handleNextStep}
                style={selectedStyle}
                ProductId={editListingId}
                materialCost={materialCost}
                subCategory={subCategory}
                action={
                  isNaN(editListingId?.split("_")?.pop()[0]) ? "" : activeMode
                }
              />
            ),
          };
    return stepContent[currentStep];
  };

  const subCategories =
    selectedStyle === "Boots"
      ? [
          "Captoe",
          "Wingtip",
          "Jodhpur",
          "Chukka",
          "Chelsea",
          "Work",
          "Balmoral",
          "Zipper",
          "Brogue",
        ]
      : selectedStyle === "Sneakers"
      ? ["Sneakers_01", "Sneakers_02", "Sneakers_03", "Sneakers_04"]
      : selectedStyle === "Oxford"
      ? ["Captoe", "Brogue", "Wingtip", "Plaintoe", "Wholecut"]
      : selectedStyle === "Derby"
      ? [
          "Brogue",
          "Captoe",
          "Longwing",
          "Wingtip",
          "Plaintoe_blucher",
          "Norwegian",
        ]
      : selectedStyle === "Monk"
      ? ["Single_strap", "Double_strap"]
      : selectedStyle === "Exotic"
      ? ["Alligator", "Crocodile", "Lizzard", "Piccary"]
      : selectedStyle === "Loafers" && [
          "Driving",
          "Dress_slipper",
          "String",
          "Tassel",
          "Penny",
          "Horsebit",
        ];

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  // Calculate paginated items
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedCategories = subCategories?.slice(startIndex, endIndex);

  // Check if there's a next page
  const hasNextPage = endIndex < subCategories?.length;
  const hasPreviousPage = currentPage > 0;

  const handleNext = () => {
    if (hasNextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (hasPreviousPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Function to close the card when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setFlipped(false);
      }
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const style = [
    "Boots",
    "Oxford",
    "Derby",
    "Monk",
    "Loafers",
    "Sneakers",
    "Exotic",
  ];

  const admins2 = new Set();
  Object.entries(cardData).map(([key, data]) =>
    Object.entries(data).map(([key2, data2]) =>
      data2[0]?.user?.map((names) => {
        return admins2.add(names);
      })
    )
  );

  const admins = Array.from(new Set(["All", ...admins2]));

  const filteredCardData = Object.entries(cardData).flatMap(([k, v]) =>
    Object.entries(v).flatMap(
      ([key, value]) =>
        Object.values(value)
          .filter((item) => {
            if (selectedStatus === "Status") {
              if (item.type === "Customize") {
                return true;
              }
              if (item.type === "Catalogue") {
                if (type === "admin") {
                  return (
                    (selectedAdmin === "All" ||
                      item.user?.includes(selectedAdmin)) &&
                    (selectedStatus === "Status" ||
                      item.Status === selectedStatus)
                  );
                } else if (type === "supplier") {
                  return (
                    item.email?.includes(email) &&
                    (selectedStatus === "Status" ||
                      item.Status === selectedStatus)
                  );
                }
              }
              return false;
            } else {
              if (item.type === "Catalogue") {
                if (type === "admin") {
                  return (
                    (selectedAdmin === "All" ||
                      item.user?.includes(selectedAdmin)) &&
                    (selectedStatus === "Status" ||
                      item.Status === selectedStatus)
                  );
                } else if (type === "supplier") {
                  return (
                    item.email?.includes(email) &&
                    (selectedStatus === "Status" ||
                      item.Status === selectedStatus)
                  );
                }
              }
            }
            return false;
          })
          .map((item) => ({ key, value: item })) // Map the filtered items
    )
  );

  // merge after filtering the data
  const mergedCardData = filteredCardData.reduce((acc, curr) => {
    const { key, value } = curr;
    // Check if the key already exists in the accumulator
    const existingEntry = acc.find((item) => Object.keys(item)[0] === key);

    if (existingEntry) {
      // If the key exists, push the value to the array
      existingEntry[key].push(value);
    } else {
      // If the key doesn't exist, create a new entry
      acc.push({ [key]: [value] });
    }

    return acc;
  }, []);

  const filteredCardData2 = Object.entries(cardData2).flatMap(([k, v]) =>
    Object.entries(v).flatMap(([key, value]) =>
      Object.values(value)
        .filter((item) => {
          if (item.type === "Catalogue") {
            return (
              selectedAdmin === "All" || item.user?.includes(selectedAdmin)
            );
          }
          return false;
        })
        .map((item) => ({ key, value: item }))
    )
  );

  // merge after filtering the data
  const mergedCardData2 = filteredCardData2.reduce((acc, curr) => {
    const { key, value } = curr;
    const existingEntry = acc.find((item) => Object.keys(item)[0] === key);
    if (existingEntry) {
      existingEntry[key].push(value);
    } else {
      acc.push({ [key]: [value] });
    }
    return acc;
  }, []);

  const [activeDropdown, setActiveDropdown] = useState(null);
  // Toggle dropdown visibility
  const toggleDropdown = (key) => {
    setActiveDropdown((prev) => (prev === key ? null : key));
  };

  const handleResendPhoneTimer = () => {
    setResendPhoneTimer(90);
    const timerInterval = setInterval(() => {
      setResendPhoneTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
    }, 90000);
  };

  const showResendCountdown = resendPhoneTimer > 0 && resendPhoneTimer < 90;

  const sendPhoneOtp = (email, key) => {
    if (key === "Delete") {
      setRelistType(key);
    } else {
      const index = Customize.findIndex((item) => item?.Article_no === key);
      if (index !== -1) {
        setRelistType(Customize[index].Status);
      }
    }
    fetch(`https://buwchlifestyles.com/api/register/send-phone-otp/`, {
      method: "POST",
      body: JSON.stringify({
        phone: phone,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to send OTP");
        }
        return response.json();
      })
      .then((response) => {
        setShowPopup(email);
        setOtp(response.otp);
        handleResendPhoneTimer();
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const handleDeleteClick = () => {
    if (otp === userOtp) {
      fetch(`https://buwchlifestyles.com/api/unlist-product/`, {
        method: "POST",
        body: JSON.stringify({
          article_no: editListingId,
          email: email,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          if (data.status === "success") {
            secureLocalStorage.setItem(
              "Customize",
              JSON.stringify(data.customize)
            );
            secureLocalStorage.setItem(
              "Catalogue",
              JSON.stringify(data.catalogue)
            );
            Customize = JSON.parse(secureLocalStorage.getItem("Customize"));
            Catalogue = JSON.parse(secureLocalStorage.getItem("Catalogue"));
            alert("Product unlisted successfully.");
            setShowPopup("");
            setActiveMode("view");
            setEditListingId(null);
            // code
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    } else {
      alert("Otp is incorrect.");
    }
  };

  const handleRelist = () => {
    if (otp === userOtp) {
      fetch(`https://buwchlifestyles.com/api/relist-product/`, {
        method: "POST",
        body: JSON.stringify({
          article_no: editListingId,
          supplier: selectedSupplier,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          if (data.status === "success") {
            secureLocalStorage.setItem(
              "Customize",
              JSON.stringify(data.customize)
            );
            secureLocalStorage.setItem(
              "Catalogue",
              JSON.stringify(data.catalogue)
            );
            Customize = JSON.parse(secureLocalStorage.getItem("Customize"));
            Catalogue = JSON.parse(secureLocalStorage.getItem("Catalogue"));
            alert(`Product relisted successfully to ${selectedSupplier}.`);
            setShowPopup("");
            setRelistType("");
            setActiveMode("view");
            setEditListingId(null);
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    } else {
      alert("Otp is incorrect.");
    }
  };

  const handleDeletedRelist = () => {
    if (otp === userOtp) {
      fetch(`https://buwchlifestyles.com/api/relist-deleted-product/`, {
        method: "POST",
        body: JSON.stringify({
          product: deletedItem,
          supplier: selectedSupplier,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          if (data.status === "success") {
            secureLocalStorage.setItem(
              "Customize",
              JSON.stringify(data.customize)
            );
            secureLocalStorage.setItem(
              "Catalogue",
              JSON.stringify(data.catalogue)
            );
            Customize = JSON.parse(secureLocalStorage.getItem("Customize"));
            Catalogue = JSON.parse(secureLocalStorage.getItem("Catalogue"));
            alert(`Product relisted successfully to ${selectedSupplier}.`);
            setShowPopup("");
            setRelistType("");
            setActiveMode("view");
            setEditListingId(null);
            setDeletedItem(null);
            // code
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    } else {
      alert("Otp is incorrect.");
    }
  };

  if (activeMode === "view" || activeMode === "delete") {
    return (
      <div className="">
        <div className=" w-full mt-1 max-md:gap-4 flex flex-row  justify-between items-center max-lg:border-b">
          <div className=" max-w-sm  w-full md:w-80   flex justify-between  gap-1 items-center  ">
            <button
              className={`text-xs md:text-sm bg-[#fff]   duration-300 ease-in-out  font-semibold text-[#212121]  border-[1px]  group max-lg:rounded-md lg:rounded-lg max-md:px-3 md:px-3 max-md:py-1 md:py-2 flex items-center  gap-2 ${
                activeMode === "delete"
                  ? "text-yellow-400 border-yellow-400"
                  : "hover:border-yellow-400 hover:text-yellow-400"
              }`}
              onClick={() => {
                setActiveMode((prev) =>
                  prev === "delete" ? "view" : "delete"
                );
              }}
            >
              Archive
              <svg
                id="fi_16866354"
                viewBox="0 0 36 36"
                xmlns="http://www.w3.org/2000/svg"
                className={`w-5 h-5 fill-[#212121] group-hover:fill-[#facc15] duration-300 ease-in-out ${
                  activeMode === "delete" ? "fill-[#facc15]" : ""
                }`}
                data-name="Layer 22"
              >
                <path d="m6 23c.55 0 1-.45 1-1v-18c0-.55.45-1 1-1h12v6c0 1.65 1.35 3 3 3h6v10c0 .55.45 1 1 1s1-.45 1-1v-11s-.01-.04-.01-.06c-.01-.09-.02-.17-.05-.25-.01-.02-.01-.05-.02-.07 0-.01-.01-.02-.02-.03-.05-.11-.11-.21-.19-.3l-9-9c-.09-.08-.19-.14-.3-.19 0-.01-.02-.02-.03-.02-.02 0-.04 0-.07-.02-.08-.03-.16-.04-.25-.05-.02 0-.04-.01-.06-.01h-13c-1.65 0-3 1.35-3 3v18c0 .55.45 1 1 1zm16-18.59 5.59 5.59h-4.59c-.55 0-1-.45-1-1zm-13 11.59c0-.55.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1h-10c-.55 0-1-.45-1-1zm0-5c0-.55.45-1 1-1h7c.55 0 1 .45 1 1s-.45 1-1 1h-7c-.55 0-1-.45-1-1zm26 15v6c0 1.65-1.35 3-3 3h-28c-1.65 0-3-1.35-3-3v-6c0-1.1.9-2 2-2h7.38c.76 0 1.45.42 1.79 1.11l1.17 2.34c.17.34.51.55.9.55h7.52c.39 0 .73-.21.9-.55l1.17-2.34c.34-.69 1.03-1.11 1.79-1.11h7.38c1.1 0 2 .9 2 2z"></path>
              </svg>
            </button>
          </div>
          <div className="w-full flex justify-end items-center   p-2 lg:hidden  ">
            <button
              className="flex items-center text-[#313131] font-semibold text-sm "
              onClick={() => setIsFilterOpen(true)}
            >
              Filter
              <svg
                fill="none"
                viewBox="0 0 24 24"
                className=" w-7 h-7 p-1"
                xmlns="http://www.w3.org/2000/svg"
                id="fi_8017777"
              >
                <path
                  clipRule="evenodd"
                  d="m18 12c2.2091 0 4-1.7909 4-4 0-2.20914-1.7909-4-4-4-1.8638 0-3.4299 1.27477-3.874 3h-11.126c-.55228 0-1 .44772-1 1 0 .55229.44772 1 1 1h11.126c.4441 1.7252 2.0102 3 3.874 3zm-2-4c0 1.10457.8954 2 2 2s2-.89543 2-2-.8954-2-2-2-2 .89543-2 2zm-14 8c0-2.2091 1.79086-4 4-4 1.86384 0 3.42994 1.2748 3.87398 3h11.12602c.5523 0 1 .4477 1 1s-.4477 1-1 1h-11.12602c-.44404 1.7252-2.01014 3-3.87398 3-2.20914 0-4-1.7909-4-4zm6 0c0-1.1046-.89543-2-2-2s-2 .8954-2 2 .89543 2 2 2 2-.8954 2-2z"
                  fill="rgb(0,0,0)"
                  fillRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

          <div
            ref={sidebarRef}
            className={`fixed top-0 right-0 h-screen w-64 bg-white shadow-lg z-50 transform transition-transform duration-700 ease-in-out ${
              isFilterOpen ? "translate-x-0" : "translate-x-full"
            } lg:hidden`}
          >
            <div className="flex items-center justify-between p-4 border-b">
              <h2 className="text-sm font-semibold flex items-center">
                Filter
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  className="ml-1 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_8017777"
                >
                  <path
                    clipRule="evenodd"
                    d="m18 12c2.2091 0 4-1.7909 4-4 0-2.20914-1.7909-4-4-4-1.8638 0-3.4299 1.27477-3.874 3h-11.126c-.55228 0-1 .44772-1 1 0 .55229.44772 1 1 1h11.126c.4441 1.7252 2.0102 3 3.874 3zm-2-4c0 1.10457.8954 2 2 2s2-.89543 2-2-.8954-2-2-2-2 .89543-2 2zm-14 8c0-2.2091 1.79086-4 4-4 1.86384 0 3.42994 1.2748 3.87398 3h11.12602c.5523 0 1 .4477 1 1s-.4477 1-1 1h-11.12602c-.44404 1.7252-2.01014 3-3.87398 3-2.20914 0-4-1.7909-4-4zm6 0c0-1.1046-.89543-2-2-2s-2 .8954-2 2 .89543 2 2 2 2-.8954 2-2z"
                    fill="rgb(0,0,0)"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </h2>
              <button
                className=""
                onClick={() => setIsFilterOpen(false)}
                aria-label="Close"
              >
                <svg
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_1828945"
                  className=" hover:fill-red-600 hover:bg-red-300 rounded-full duration-300 ease-in-out hover:scale-90  w-4 lg:w-5 h-4 lg:h-5"
                >
                  <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
                  <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
                  <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
                </svg>
              </button>
            </div>
            <div className="w-full mt-3">
              <div key="StyleDropdown" className="w-full border-b px-4 py-2">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleDropdown("StyleDropdown")}
                >
                  <button className=" font-medium text-[#212121] duration-500 ease-in-out">
                    Select Style
                  </button>
                  <svg
                    className={`w-4 h-5 transition-transform duration-700 ease-in-out ${
                      activeDropdown === "StyleDropdown" ? "rotate-180" : ""
                    }`}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_2722987"
                  >
                    <g id="_16" data-name="16">
                      <path d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"></path>
                    </g>
                  </svg>
                </div>
                <div
                  className={`overflow-hidden transition-all duration-700 ease-in-out ${
                    activeDropdown === "StyleDropdown" ? "max-h-40" : "max-h-0"
                  }`}
                >
                  <div className="flex flex-col justify-center gap-2 my-3">
                    {style.map((key) => (
                      <label
                        key={key}
                        className="flex items-center font-medium uppercase text-sm cursor-pointer"
                      >
                        <div className="checkbox-wrapper-31 scale-75 mr-2">
                          <input
                            type="checkbox"
                            value={key}
                            onChange={() => {
                              setSelectedStyle(key);
                              toggleDropdown("");
                            }}
                            checked={selectedStyle === key}
                          />
                          <svg viewBox="0 0 35.6 35.6">
                            <circle
                              className="background"
                              cx="17.8"
                              cy="17.8"
                              r="17.8"
                            ></circle>
                            <circle
                              className="stroke"
                              cx="17.8"
                              cy="17.8"
                              r="14.37"
                            ></circle>
                            <polyline
                              className="check"
                              points="11.78 18.12 15.55 22.23 25.17 12.87"
                            ></polyline>
                          </svg>
                        </div>

                        {key}
                      </label>
                    ))}
                  </div>
                </div>
              </div>
              {type === "admin" && (
                <div key="AdminDropdown" className="w-full border-b px-4 py-2">
                  {/* <label htmlFor=""></label> */}
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() => toggleDropdown("AdminDropdown")}
                  >
                    <button className="underline-animation-light font-medium text-[#212121] duration-500 ease-in-out">
                      Supplier Name
                    </button>
                    <svg
                      className={`w-4 h-5 transition-transform duration-700 ease-in-out ${
                        activeDropdown === "AdminDropdown" ? "rotate-180" : ""
                      }`}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      id="fi_2722987"
                    >
                      <g id="_16" data-name="16">
                        <path d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"></path>
                      </g>
                    </svg>
                  </div>
                  <div
                    className={`overflow-hidden transition-all duration-700 ease-in-out ${
                      activeDropdown === "AdminDropdown"
                        ? "max-h-40"
                        : "max-h-0"
                    }`}
                  >
                    <div className="flex flex-col justify-center gap-2 my-3">
                      <label className="flex items-center font-medium uppercase text-sm cursor-pointer">
                        <div className="checkbox-wrapper-31 scale-75 mr-2">
                          <input
                            type="checkbox"
                            value={"All"}
                            onChange={() => {
                              setSelectedAdmin("All");
                              toggleDropdown("");
                            }}
                            checked={selectedAdmin === "All"}
                          />
                          <svg viewBox="0 0 35.6 35.6">
                            <circle
                              className="background"
                              cx="17.8"
                              cy="17.8"
                              r="17.8"
                            ></circle>
                            <circle
                              className="stroke"
                              cx="17.8"
                              cy="17.8"
                              r="14.37"
                            ></circle>
                            <polyline
                              className="check"
                              points="11.78 18.12 15.55 22.23 25.17 12.87"
                            ></polyline>
                          </svg>
                        </div>
                        All
                      </label>
                      {allSuppliers.map((supplier) => (
                        <label
                          key={supplier}
                          className="flex items-center font-medium uppercase text-sm cursor-pointer"
                        >
                          <div className="checkbox-wrapper-31 scale-75 mr-2">
                            <input
                              type="checkbox"
                              value={supplier.username}
                              onChange={() => {
                                setSelectedAdmin(supplier.username);
                                toggleDropdown("");
                              }}
                              checked={selectedAdmin === supplier.username}
                            />
                            <svg viewBox="0 0 35.6 35.6">
                              <circle
                                className="background"
                                cx="17.8"
                                cy="17.8"
                                r="17.8"
                              ></circle>
                              <circle
                                className="stroke"
                                cx="17.8"
                                cy="17.8"
                                r="14.37"
                              ></circle>
                              <polyline
                                className="check"
                                points="11.78 18.12 15.55 22.23 25.17 12.87"
                              ></polyline>
                            </svg>
                          </div>
                          {supplier.username}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              <div key="StatusDropdown" className="w-full border-b px-4 py-2">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleDropdown("StatusDropdown")}
                >
                  <button className=" font-medium text-[#212121] duration-500 ease-in-out">
                    Status
                  </button>
                  <svg
                    className={`w-4 h-5 transition-transform duration-700 ease-in-out ${
                      activeDropdown === "StatusDropdown" ? "rotate-180" : ""
                    }`}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_2722987"
                  >
                    <g id="_16" data-name="16">
                      <path d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"></path>
                    </g>
                  </svg>
                </div>
                <div
                  className={`overflow-hidden transition-all duration-700 ease-in-out ${
                    activeDropdown === "StatusDropdown" ? "max-h-40" : "max-h-0"
                  }`}
                >
                  <div className="flex flex-col justify-center gap-2 my-3">
                    {statuses.map(({ status, color }) => (
                      <label
                        key={status}
                        className="flex items-center font-medium uppercase text-sm cursor-pointer"
                      >
                        <div className="checkbox-wrapper-31 scale-75 mr-2">
                          <input
                            type="checkbox"
                            value={status}
                            onChange={() => {
                              setSelectedStatus(status);
                              toggleDropdown("");
                            }}
                            checked={selectedStatus === status}
                          />
                          <svg viewBox="0 0 35.6 35.6">
                            <circle
                              className="background"
                              cx="17.8"
                              cy="17.8"
                              r="17.8"
                            ></circle>
                            <circle
                              className="stroke"
                              cx="17.8"
                              cy="17.8"
                              r="14.37"
                            ></circle>
                            <polyline
                              className="check"
                              points="11.78 18.12 15.55 22.23 25.17 12.87"
                            ></polyline>
                          </svg>
                        </div>

                        <div
                          className="inline-block w-3 h-3 rounded-full mr-1"
                          style={{ backgroundColor: color }}
                        ></div>
                        {status}
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="max-md:w-full max-w-lg  flex items-center justify-end gap-3 border rounded-lg max-md:p-1 md:p-2 max-lg:hidden">
            <svg
              fill="none"
              viewBox="0 0 24 24"
              className="max-md:mr-2 md:ml-1 w-5 h-5 md:w-6 md:h-6"
              xmlns="http://www.w3.org/2000/svg"
              id="fi_8017777"
            >
              <path
                clipRule="evenodd"
                d="m18 12c2.2091 0 4-1.7909 4-4 0-2.20914-1.7909-4-4-4-1.8638 0-3.4299 1.27477-3.874 3h-11.126c-.55228 0-1 .44772-1 1 0 .55229.44772 1 1 1h11.126c.4441 1.7252 2.0102 3 3.874 3zm-2-4c0 1.10457.8954 2 2 2s2-.89543 2-2-.8954-2-2-2-2 .89543-2 2zm-14 8c0-2.2091 1.79086-4 4-4 1.86384 0 3.42994 1.2748 3.87398 3h11.12602c.5523 0 1 .4477 1 1s-.4477 1-1 1h-11.12602c-.44404 1.7252-2.01014 3-3.87398 3-2.20914 0-4-1.7909-4-4zm6 0c0-1.1046-.89543-2-2-2s-2 .8954-2 2 .89543 2 2 2 2-.8954 2-2z"
                fill="rgb(0,0,0)"
                fillRule="evenodd"
              ></path>
            </svg>{" "}
            <div
              className="w-36 flex justify-center items-center "
              ref={dropdownRef}
            >
              <button
                className={`w-full flex items-center justify-between text-[#313131] font-semibold text-sm   border-[1px] bg-gray-100 px-2 py-1  ${
                  isOpen ? "rounded-t-md " : "rounded-md"
                }`}
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                {selectedStyle || "Select Style"}
                <svg
                  className="ml-1 w-4 h-4 "
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>

              {isOpen && (
                <div
                  className={`absolute top-[70px]   fadein-animation overflow-hidden   shadow-[0px_1px_1px_0px_#00000024] mt-2 w-36  z-50  border-[1px] border-t-0  transition-opacity duration-500 ease-in-out  ${
                    isOpen
                      ? "max-h-64 opac-1  rounded-b-xl bg-gray-100 "
                      : "max-h-0 opac-0 "
                  }`}
                >
                  <ul className="py-1 text-sm  text-gray-600">
                    {style.map((key) => (
                      <li
                        onClick={() => {
                          setSelectedStyle(key);
                          setIsOpen(!isOpen);
                        }}
                        className={`px-2 py-1  hover:text-[#212121] hover:bg-white font-medium  duration-200 ease-in-out cursor-pointer ${
                          selectedStyle === key
                            ? " text-[#212121] font-semibold"
                            : "text-[#616161]"
                        }`}
                      >
                        {key}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {/* Admin Dropdown */}
            {type === "admin" && (
              <div
                className="w-32 flex justify-center items-center  relative"
                ref={adminRef}
              >
                <button
                  className={`w-full flex items-center justify-between text-[#313131] font-semibold text-sm border-[1px] bg-gray-100 px-2 py-1  overflow-hidden ${
                    isOpenAdmin ? "rounded-t-md " : "rounded-md group"
                  }`}
                  onClick={() => setIsOpenAdmin(!isOpenAdmin)}
                >
                  <div className="whitespace-pre w-24 overflow-hidden flex">
                    {" "}
                    <span
                      className={` ${
                        selectedAdmin?.length > 10
                          ? "group-hover:-translate-x-[60px] duration-1000 ease-in-out "
                          : ""
                      }`}
                    >
                      {selectedAdmin || "Admin"}
                    </span>
                  </div>
                  <svg
                    className="ml-1 w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>
                {isOpenAdmin && (
                  <div
                    className={`absolute top-[20px]   fadein-animation overflow-hidden   shadow-[0px_1px_1px_0px_#00000024] mt-2 w-32  z-50  border-[1px] border-t-0  transition-opacity duration-500 ease-in-out bg-gray-100 rounded-b-lg ${
                      isOpen
                        ? "max-h-64 opac-1  rounded-b-xl "
                        : "max-h-0 opac-0 "
                    }`}
                  >
                    <ul className="py-1 text-sm text-gray-600">
                      <li
                        onClick={() => {
                          setSelectedAdmin("All");
                          setIsOpenAdmin(false);
                        }}
                        className={`px-2 py-1 group hover:text-[#212121] hover:bg-white font-medium duration-200 ease-in-out cursor-pointer whitespace-pre w-full flex ${
                          selectedAdmin === "All"
                            ? "text-[#212121] font-semibold"
                            : "text-[#616161]"
                        }`}
                      >
                        <div className="w-full overflow-scroll no-scrollbar">
                          <span className="flex">All</span>
                        </div>
                      </li>
                      {allSuppliers.map((admin) => (
                        <li
                          key={admin}
                          onClick={() => {
                            setSelectedAdmin(admin.username);
                            setIsOpenAdmin(false);
                          }}
                          className={`px-2 py-1 group hover:text-[#212121] hover:bg-white font-medium duration-200 ease-in-out cursor-pointer whitespace-pre w-full flex ${
                            selectedAdmin === admin.username
                              ? "text-[#212121] font-semibold"
                              : "text-[#616161]"
                          }`}
                        >
                          <div className="w-full overflow-scroll no-scrollbar">
                            <span
                              className={`flex ${
                                admin.username?.length > 10
                                  ? "group-hover:-translate-x-[60px] duration-1000 ease-in-out "
                                  : ""
                              }`}
                            >
                              {admin.username}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
            {/* Status Dropdown */}
            <div
              className="w-32 flex justify-center items-center  relative"
              ref={statusRef}
            >
              <button
                className={`w-full flex items-center justify-between text-[#313131] font-semibold text-sm border-[1px] bg-gray-100 px-2 py-1 ${
                  isOpenStatus ? "rounded-t-md " : "rounded-md"
                }`}
                onClick={() => setIsOpenStatus(!isOpenStatus)}
              >
                <div className="flex items-center justify-start gap-1">
                  <div
                    className="inline-block w-2 h-2 rounded-full "
                    style={{
                      backgroundColor:
                        statuses.find((s) => s.status === selectedStatus)
                          ?.color || "#fff",
                    }}
                  ></div>
                  {selectedStatus || "Status"}
                </div>
                <svg
                  className="ml-1 w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>
              {isOpenStatus && (
                <div
                  className={`absolute top-[20px] fadein-animation overflow-hidden rounded-b-lg shadow-[0px_1px_1px_0px_#00000024] mt-2 w-32 z-50 border-[1px] border-t-0 transition-opacity duration-500 ease-in-out bg-gray-100`}
                >
                  <ul className="py-1 text-sm text-gray-600">
                    {statuses.map(({ status, color }, index) => (
                      <li
                        key={status}
                        onClick={() => {
                          setSelectedStatus(status);
                          setIsOpenStatus(false);
                        }}
                        className={`px-2 py-1 hover:text-[#212121] hover:bg-white font-medium duration-200 ease-in-out cursor-pointer ${
                          selectedStatus === status
                            ? "text-[#212121] font-semibold"
                            : "text-[#616161]"
                        }`}
                      >
                        <div
                          className={`inline-block w-2 h-2 rounded-full  mr-2`}
                          style={{ backgroundColor: color }}
                        ></div>
                        {status}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className=" flex items-start justify-start  ">
          <section className=" w-full flex text-gray-900 ">
            <div className="flex flex-wrap max-sm:justify-start sm:justify-start max-sm:gap-2  sm:gap-3 md:gap-4 h-max w-full max-lg:mt-3 lg:mt-10">
              <article
                className={`relative mt-4 isolate flex flex-col justify-center h-[240px]  lg:h-[320px] rounded-2xl overflow-hidden max-sm:p-1 sm:p-2 lg:p-3 min-h-64 w-40 lg:w-64 pt-0 border-[1px] flip-card ${
                  flipped ? "flipped" : ""
                } `}
                ref={cardRef}
              >
                {/* Front Side */}
                <div
                  className="flip-card-front group flex flex-col w-full h-full justify-center items-center gap-3 cursor-pointer z-10"
                  onClick={() => setFlipped(true)}
                >
                  <img
                    src="/images/plus.svg"
                    alt="New Listing"
                    className=" object-cover w-8 h-8 lg:w-12 lg:h-12 group-hover:rotate-90 duration-300 ease-in-out"
                  />

                  <div className="absolute -inset-3 bg-gradient-to-t  from-gray-300/30 via-gray-400/0 "></div>
                  <div className="text-center font-semibold text-base md:text-lg lg:text-xl">
                    <p>New Listing</p>
                  </div>
                </div>

                {/* Back Side */}
                <div className="flip-card-back flex flex-col justify-center items-center max-lg:gap-2 lg:gap-3 w-full h-full ">
                  <h3 className="text-xs lg:text-lg font-semibold">
                    Select Sub-Category
                  </h3>
                  <div className="flex flex-col max-lg:gap-1.5 lg:gap-2 w-full max-lg:px-2 lg:px-3 h-[70%]">
                    {paginatedCategories?.map((category, index) => (
                      <button
                        className="text-[#313131] hover:text-[#111111] text-[9px] lg:text-[13px] font-semibold w-full max-lg:py-1.5 lg:py-2 text-center rounded border bg-gray-50 hover:bg-yellow-100"
                        onClick={() => {
                          setActiveMode("add");
                          setEditListingId(null);
                          setSubCategory(category);
                        }}
                      >
                        {category}
                      </button>
                    ))}
                  </div>
                  <div className="flex justify-between w-full px-4">
                    <button
                      className={`text-[#313131] font-semibold  ${
                        hasPreviousPage
                          ? "fill-black cursor-pointer opacity-100"
                          : " fill-black400 cursor-not-allowed opacity-50"
                      }`}
                      onClick={handlePrevious}
                      disabled={!hasPreviousPage}
                    >
                      <svg
                        version="1.1"
                        id="fi_318275"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4 lg:w-6 lg:h-6 rotate-180"
                        x="0px"
                        y="0px"
                        viewBox="0 0 512 512"
                      >
                        <g>
                          <g>
                            <g>
                              <path
                                d="M256,0C114.618,0,0,114.618,0,256s114.618,256,256,256s256-114.618,256-256S397.382,0,256,0z M256,469.333
				c-117.818,0-213.333-95.515-213.333-213.333S138.182,42.667,256,42.667S469.333,138.182,469.333,256S373.818,469.333,256,469.333
				z"
                              ></path>
                              <path
                                d="M228.418,134.248c-8.331-8.331-21.839-8.331-30.17,0c-8.331,8.331-8.331,21.839,0,30.17L289.83,256l-91.582,91.582
				c-8.331,8.331-8.331,21.839,0,30.17c8.331,8.331,21.839,8.331,30.17,0l106.667-106.667c8.331-8.331,8.331-21.839,0-30.17
				L228.418,134.248z"
                              ></path>
                            </g>
                          </g>
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                      </svg>
                    </button>
                    <button
                      className={`text-[#313131] font-semibold  ${
                        hasNextPage
                          ? "fill-black cursor-pointer opacity-100"
                          : " fill-black400 cursor-not-allowed opacity-50"
                      }`}
                      onClick={handleNext}
                      disabled={!hasNextPage}
                    >
                      <svg
                        version="1.1"
                        id="fi_318275"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4 lg:w-6 lg:h-6 "
                        x="0px"
                        y="0px"
                        viewBox="0 0 512 512"
                      >
                        <g>
                          <g>
                            <g>
                              <path
                                d="M256,0C114.618,0,0,114.618,0,256s114.618,256,256,256s256-114.618,256-256S397.382,0,256,0z M256,469.333
				c-117.818,0-213.333-95.515-213.333-213.333S138.182,42.667,256,42.667S469.333,138.182,469.333,256S373.818,469.333,256,469.333
				z"
                              ></path>
                              <path
                                d="M228.418,134.248c-8.331-8.331-21.839-8.331-30.17,0c-8.331,8.331-8.331,21.839,0,30.17L289.83,256l-91.582,91.582
				c-8.331,8.331-8.331,21.839,0,30.17c8.331,8.331,21.839,8.331,30.17,0l106.667-106.667c8.331-8.331,8.331-21.839,0-30.17
				L228.418,134.248z"
                              ></path>
                            </g>
                          </g>
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                      </svg>
                    </button>
                  </div>
                </div>
              </article>
              {Object.entries(mergedCardData).map(([k, v]) =>
                Object.entries(v).map(([key, value]) => {
                  const allUsers = v[key].flatMap((item) => item.user);
                  const filteredUsers = allUsers.filter(
                    (user) => user !== null && user !== undefined && user !== ""
                  );
                  const uniqueUsersArray = [...new Set(filteredUsers)];
                  return (
                    <>
                      <article
                        className={`relative isolate mt-4 h-[240px] lg:h-[320px]  min-h-64  w-40 lg:w-64  rounded-2xl flex flex-col justify-between ${
                          type === "admin" && "rounded-tl-none"
                        } max-sm:p-1 sm:p-2 lg:p-2 pt-0 border-[1px] transition-all duration-500 ${
                          activeMode === "delete" ? "border-red-300 " : ""
                        } ${
                          value[1]?.Status === "Unlisted"
                            ? "border-[1px] border-[#616161]" // Faded appearance for unlisted
                            : ""
                        }`}
                      >
                        {type === "admin" && (
                          <div className="absolute flex group w-40 items-center bg-gray-500 text-white rounded-t-md rounded-tr-full pr-4 pl-2 py-1 -top-6 -left-[1px] text-xs font-medium overflow-hidden">
                            <span
                              className={`w-32 ${
                                uniqueUsersArray[0]?.length > 16
                                  ? "group-hover:-translate-x-[60px] duration-1000 ease-in-out "
                                  : ""
                              }`}
                            >
                              {uniqueUsersArray[0] || "Admin"}
                            </span>
                            {uniqueUsersArray?.length > 1 && (
                              <button className="ml-2 text-white hover:text-gray-300 focus:outline-none">
                                <svg
                                  className="w-4 h-4 fill-gray-50 hover:fill-yellow-400 duration-300 ease-in-out"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                  id="fi_2722987"
                                >
                                  <g id="_16" data-name="16">
                                    <path d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"></path>
                                  </g>
                                </svg>
                              </button>
                            )}
                          </div>
                        )}
                        <div className="flex justify-center items-center mb-3 absolute z-40 max-lg:top-2 max-lg:right-2 lg:top-2 right-2">
                          <button
                            onClick={() => {
                              if (activeMode === "delete") {
                                sendPhoneOtp(email, key);
                              } else {
                                const category = key
                                  .split("_")
                                  .slice(0, -2)
                                  .join(" ");
                                setActiveMode("edit");
                                const index = Customize.findIndex(
                                  (item) => item?.Article_no === key
                                );
                                if (index !== -1) {
                                  Customize[index].Edit = true;
                                  secureLocalStorage.setItem(
                                    "Customize",
                                    JSON.stringify(Customize)
                                  );
                                }
                                setSubCategory(category);
                              }
                              setEditListingId(key);
                            }}
                            className={`flex overflow-hidden items-center text-xs font-medium focus-visible:outline-none focus-visible:ring-1  text-gray-100 hover:text-gray-50  max-lg:p-1.5 lg:p-2 whitespace-pre md:flex group relative w-full justify-center rounded-full transition-all duration-300 ease-out  ring-2 hover:ring-2   ${
                              activeMode === "delete"
                                ? "ring-gray-50 hover:ring-yellow-400 hover:bg-[#fff] bg-[#e5e7eb]"
                                : value[0]?.edit
                                ? "ring-gray-50 hover:ring-yellow-400 hover:bg-yellow-400 bg-yellow-500 hover:ring-offset-2"
                                : "ring-gray-50 hover:ring-yellow-400 hover:bg-black/90 bg-black/80 hover:ring-offset-2"
                            }`}
                          >
                            <span className="absolute right-0 -mt-12 h-32 w-8 translate-x-12 rotate-12 bg-[#ffffff] opacity-20 transition-all duration-700 ease-in group-hover:-translate-x-32"></span>
                            <div className="flex items-center">
                              {activeMode === "delete" &&
                              value[1]?.Status !== "Unlisted" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  id="fi_3405244"
                                  data-name="Layer 2"
                                  className="w-4 h-4 lg:w-5 lg:h-5 fill-[#000] group-hover:fill-[#facc15] duration-300 ease-in-out"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M19,7a1,1,0,0,0-1,1V19.191A1.92,1.92,0,0,1,15.99,21H8.01A1.92,1.92,0,0,1,6,19.191V8A1,1,0,0,0,4,8V19.191A3.918,3.918,0,0,0,8.01,23h7.98A3.918,3.918,0,0,0,20,19.191V8A1,1,0,0,0,19,7Z"></path>
                                  <path d="M20,4H16V2a1,1,0,0,0-1-1H9A1,1,0,0,0,8,2V4H4A1,1,0,0,0,4,6H20a1,1,0,0,0,0-2ZM10,4V3h4V4Z"></path>
                                  <path d="M11,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z"></path>
                                  <path d="M15,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z"></path>
                                </svg>
                              ) : activeMode === "delete" &&
                                value[1]?.Status === "Unlisted" ? (
                                <svg
                                  id="fi_10009684"
                                  enableBackground="new 0 0 512 512"
                                  viewBox="0 0 512 512"
                                  className="w-4 h-4 lg:w-5 lg:h-5 fill-[#000] group-hover:fill-[#facc15]"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g id="Layer_2_00000097469747894302416050000002917915783155835571_">
                                    <g id="upload_arrow">
                                      <path d="m255.5 511.9c-41.6 0-83.2 0-124.7 0-57-.1-98.1-41-98.4-98-.1-19.9-.1-39.8 0-59.7.1-20.5 12.5-33.7 31.6-33.8s31.6 13.1 31.8 33.6c.1 20.2-.1 40.5.1 60.8.2 21.2 12.6 33.6 34 33.6h252.7c21.3 0 33.8-12.4 34-33.6.1-20.2-.1-40.5.1-60.8.1-20.5 12.6-33.6 31.7-33.6s31.7 13.2 31.5 33.8c-.2 25.9 1.2 52.1-2 77.6-6 46.8-45.8 79.8-93.2 80-43.2.3-86.2.1-129.2.1z"></path>
                                      <path d="m224.3 109.2c-4.8 4.5-7.7 7-10.3 9.6-20.3 20.5-40.4 41.1-60.8 61.4-14.5 14.5-34.6 15.5-47.6 2.9s-12.5-33.4 1.7-47.8c41.4-41.6 82.9-83.1 124.5-124.5 14.7-14.6 33.8-14.6 48.4 0 41.9 41.6 83.6 83.3 125.2 125.2 13.7 13.8 13.8 34.6 1 47s-33 11.8-46.9-2c-20.7-20.6-41-41.5-61.5-62.2-2.6-2.7-5.5-5.2-9.7-9.1-.3 5.7-.6 9.4-.6 13.2 0 65.4.1 130.9-.1 196.3-.1 24.6-21.6 39.2-44.1 30.4-12.4-4.8-19.2-15.6-19.2-31.4-.1-64.7-.1-129.4 0-194.2z"></path>
                                    </g>
                                  </g>
                                </svg>
                              ) : (
                                <svg
                                  className="w-4 h-4 lg:w-5 lg:h-5 fill-white"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                  id="fi_9356210"
                                >
                                  <path d="m12.64 5.42-10.29 10.29a2.38 2.38 0 0 0 -.68 1.41l-.41 3.6a1.81 1.81 0 0 0 1.81 2h.21l3.6-.41a2.42 2.42 0 0 0 1.41-.67l10.29-10.3z"></path>
                                  <path d="m22.06 4.55-2.61-2.61a2.35 2.35 0 0 0 -3.33 0l-2.42 2.42 5.94 5.94 2.42-2.42a2.35 2.35 0 0 0 0-3.33z"></path>
                                </svg>
                              )}
                            </div>
                          </button>
                          {/* Pop-Up */}
                          {showPopup !== "" && (
                            <div className="fixed inset-0 flex items-center justify-center  ">
                              <div className="bg-white p-6 max-w-[350px] rounded-lg shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)]">
                                <button
                                  className="float-right"
                                  onClick={() => setShowPopup("")}
                                  aria-label="Close"
                                >
                                  <svg
                                    viewBox="0 0 512 512"
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="fi_1828945"
                                    className=" hover:fill-red-600 hover:bg-red-300 rounded-full duration-300 ease-in-out hover:scale-90  w-4 lg:w-5 h-4 lg:h-5"
                                  >
                                    <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
                                    <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
                                    <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
                                  </svg>
                                </button>
                                <h2 className="text-base font-semibold">
                                  {relistType === "Unlisted" ||
                                  relistType === "Delete"
                                    ? "Relist "
                                    : "Unlist "}
                                  Confirmation
                                </h2>

                                <p className="mt-4 text-xs font-medium">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Donec rhoncus finibus
                                </p>
                                {/* OTP Section */}
                                <div className="w-full flex justify-start items-center gap-2 text-sm font-semibold mt-3">
                                  <p className="w-12 md:w-16">OTP:</p>
                                  <input
                                    type="text"
                                    maxLength="5"
                                    placeholder="Enter OTP"
                                    onChange={(e) => setUserOtp(e.target.value)}
                                    className="px-2 py-1 block w-28 md:w-40 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm"
                                  />
                                  <button
                                    onClick={() => {
                                      sendPhoneOtp(showPopup, key);
                                    }}
                                    disabled={showResendCountdown}
                                    className="underline text-sm font-medium  hover:text-[#e2a731]  ease-in-out duration-300"
                                  >
                                    {showResendCountdown ? (
                                      <>{resendPhoneTimer}s</>
                                    ) : (
                                      <>Resend</>
                                    )}
                                  </button>
                                </div>
                                {(relistType === "Unlisted" ||
                                  relistType === "Delete") && (
                                  <>
                                    <div className="w-full flex justify-start items-center gap-2 text-sm font-semibold mt-3">
                                      <p className="w-12 md:w-16">Relist to:</p>
                                      <select
                                        onChange={(e) =>
                                          setSelectedSupplier(e.target.value)
                                        }
                                        className="px-2 py-1 block w-28 md:w-40 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm"
                                      >
                                        {allSuppliers.map((supplier) => (
                                          <option
                                            key={supplier.email}
                                            value={supplier.email}
                                          >
                                            {supplier.username}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </>
                                )}
                                <div className="mt-4 flex justify-end space-x-3">
                                  <button
                                    onClick={() => {
                                      if (relistType === "Unlisted")
                                        handleRelist();
                                      else if (relistType === "Delete")
                                        handleDeletedRelist();
                                      else handleDeleteClick();
                                    }}
                                    className="relative max-lg:px-4 max-md:py-1 md:py-1.5 lg:px-8 lg:py-1.5 text-[10px] md:text-xs lg:text-sm rounded-md bg-white isolation-auto z-10 border-2 border-[#FFC94A] font-semibold before:absolute before:w-full before:transition-all before:duration-500 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-full  before:bg-[#FFC94A] before:-z-10  before:aspect-square before:hover:scale-[170%] overflow-hidden before:hover:duration-500"
                                  >
                                    {relistType === "Unlisted" ||
                                    relistType === "Delete"
                                      ? "Relist"
                                      : "Unlist"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className={`w-full h-full flex flex-col justify-between ${
                            value[1]?.Status === "Unlisted"
                              ? "opacity-50 " // Faded appearance for unlisted
                              : "opacity-100"
                          }`}
                        >
                          <div className="max-lg:p-2  h-40 lg:h-56 lg:p-2 w-40 lg:w-56">
                            <CarouselCard cardData={value} />
                          </div>
                          <div className="z-10 ">
                            <div className="text-sm md:text-base lg:text-lg font-semibold text-center ">
                              {key
                                .replaceAll("_", " ")
                                .split(" ")
                                .slice(0, -1)
                                .join(" ")}
                            </div>
                            <div className="overflow-hidden text-xs lg:text-sm text-center text-gray-800 font-semibold">
                              SKU-{String(key).split("_").pop()}{" "}
                            </div>
                          </div>
                        </div>
                      </article>
                    </>
                  );
                })
              )}
            </div>
          </section>
        </div>
        {type === "admin" && Object.keys(mergedCardData2).length > 0 && (
          <>
            <hr className="my-8" />
            <h4 className="text-xl font-bold">Deleted Catalogue Listings:</h4>
            <div className=" flex items-start justify-start  ">
              <section className=" w-full flex text-gray-900 ">
                <div className="flex flex-wrap max-sm:justify-start sm:justify-start max-sm:gap-2  sm:gap-3 md:gap-4 h-max w-full max-lg:mt-3 lg:mt-4">
                  {Object.entries(mergedCardData2).map(([k, v]) =>
                    Object.entries(v).map(([key, value]) => {
                      return (
                        <>
                          <article
                            className={`relative isolate mt-4 h-[240px] lg:h-[320px]  min-h-64  w-40 lg:w-64  rounded-2xl flex flex-col justify-between ${
                              type === "admin" && "rounded-tl-none"
                            } max-sm:p-1 sm:p-2 lg:p-2 pt-0 border-[1px] transition-all duration-500 ${
                              activeMode === "delete" ? "border-red-300 " : ""
                            }`}
                          >
                            <div className="absolute flex group w-40 items-center bg-red-500 text-white rounded-t-md rounded-tr-full pr-4 pl-2 py-1 -top-6 -left-[1px] text-xs font-medium overflow-hidden">
                              <span className={`w-32`}>Deleted</span>
                            </div>
                            <div className="flex justify-center items-center mb-3 absolute z-40 max-lg:top-2 max-lg:right-2 lg:top-2 right-2">
                              <button
                                onClick={() => {
                                  sendPhoneOtp(email, "Delete");
                                  const deletedItem = Catalogue.find(
                                    (item) =>
                                      item.images.Main === value[0].img &&
                                      item.Article_no === key
                                  );
                                  setDeletedItem(deletedItem);
                                }}
                                className={`flex overflow-hidden items-center text-xs font-medium focus-visible:outline-none focus-visible:ring-1  text-gray-100 hover:text-gray-50  max-lg:p-1.5 lg:p-2 whitespace-pre md:flex group relative w-full justify-center rounded-full transition-all duration-300 ease-out  ring-2 hover:ring-2 ring-gray-50 hover:ring-yellow-400 hover:bg-[#fff] bg-[#e5e7eb]`}
                              >
                                <span className="absolute right-0 -mt-12 h-32 w-8 translate-x-12 rotate-12 bg-[#ffffff] opacity-20 transition-all duration-700 ease-in group-hover:-translate-x-32"></span>
                                <div className="flex items-center">
                                  <svg
                                    id="fi_10009684"
                                    enableBackground="new 0 0 512 512"
                                    viewBox="0 0 512 512"
                                    className="w-4 h-4 lg:w-5 lg:h-5 fill-[#000] group-hover:fill-[#facc15]"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g id="Layer_2_00000097469747894302416050000002917915783155835571_">
                                      <g id="upload_arrow">
                                        <path d="m255.5 511.9c-41.6 0-83.2 0-124.7 0-57-.1-98.1-41-98.4-98-.1-19.9-.1-39.8 0-59.7.1-20.5 12.5-33.7 31.6-33.8s31.6 13.1 31.8 33.6c.1 20.2-.1 40.5.1 60.8.2 21.2 12.6 33.6 34 33.6h252.7c21.3 0 33.8-12.4 34-33.6.1-20.2-.1-40.5.1-60.8.1-20.5 12.6-33.6 31.7-33.6s31.7 13.2 31.5 33.8c-.2 25.9 1.2 52.1-2 77.6-6 46.8-45.8 79.8-93.2 80-43.2.3-86.2.1-129.2.1z"></path>
                                        <path d="m224.3 109.2c-4.8 4.5-7.7 7-10.3 9.6-20.3 20.5-40.4 41.1-60.8 61.4-14.5 14.5-34.6 15.5-47.6 2.9s-12.5-33.4 1.7-47.8c41.4-41.6 82.9-83.1 124.5-124.5 14.7-14.6 33.8-14.6 48.4 0 41.9 41.6 83.6 83.3 125.2 125.2 13.7 13.8 13.8 34.6 1 47s-33 11.8-46.9-2c-20.7-20.6-41-41.5-61.5-62.2-2.6-2.7-5.5-5.2-9.7-9.1-.3 5.7-.6 9.4-.6 13.2 0 65.4.1 130.9-.1 196.3-.1 24.6-21.6 39.2-44.1 30.4-12.4-4.8-19.2-15.6-19.2-31.4-.1-64.7-.1-129.4 0-194.2z"></path>
                                      </g>
                                    </g>
                                  </svg>
                                </div>
                              </button>
                            </div>
                            <div
                              className={`w-full h-full flex flex-col justify-between`}
                            >
                              <div className="max-lg:p-2  h-40 lg:h-56 lg:p-2 w-40 lg:w-56">
                                <CarouselCard cardData={value} />
                              </div>
                              <div className="z-10 ">
                                <div className="text-sm md:text-base lg:text-lg font-semibold text-center ">
                                  {key
                                    .replaceAll("_", " ")
                                    .split(" ")
                                    .slice(0, -1)
                                    .join(" ")}
                                </div>
                                <div className="overflow-hidden text-xs lg:text-sm text-center text-gray-800 font-semibold">
                                  SKU-{String(key).split("_").pop()}{" "}
                                </div>
                              </div>
                            </div>
                          </article>
                        </>
                      );
                    })
                  )}
                </div>
              </section>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="relative max-md:p-2 md:p-4 xl:p-6 2xl:p-8 rounded-xl  max-w-[85rem] h-full">
      <div
        className="group relative inline-block bg-slate-100 p-2 rounded-full hover:bg-slate-200 duration-200 ease-in-out cursor-pointer"
        onClick={() => {
          if (currentStep <= 1) {
            setActiveMode("view");
            setCompletedSteps([]);
            setCurrentStep(1);
            setFlipped(false);
          } else {
            goToStep(currentStep - 1);
          }
        }}
      >
        <button className="focus:outline-none items-center flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1024 1024"
            className="w-6 h-6 stroke-2 stroke-black"
          >
            <path
              d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
              fill="#000000"
            ></path>
            <path
              d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
              fill="#000000"
            ></path>
          </svg>
        </button>
        <span className="max-md:hidden absolute -bottom-8 left-2/3 w-20 transform -translate-x-1/2 z-20  text-sm font-bold transition-transform duration-300 ease-in-out scale-0 group-hover:scale-100">
          Go back
        </span>
      </div>

      {/* subCategory */}
      <div className=" text-[#313131] text-xs lg:text-sm border-2 border-slate-200 flex items-center font-semibold rounded-lg max-lg:py-1.5 max-lg:px-2 lg:py-2 lg:px-3 gap-2 absolute max-2xl:top-2 2xl:top-10 right-2 ">
        <div className="w-4 h-4 bg-yellow-400 rounded-full border-4 border-yellow-200"></div>
        {subCategory}&nbsp;{selectedStyle}
      </div>

      {/* Stepper */}
      <div className="flex items-center justify-center max-md:mb-7 md:mb-10 max-md:scale-[70%] scale-[100%] ">
        {(type === "admin"
          ? ["Upper", "Sole", "Trims", "GLBfile", "Images"]
          : ["Upper", "Sole", "Trims", "Images"]
        ).map((step, index) => (
          <div
            className="flex items-center cursor-pointer relative"
            onClick={() => goToStep(index + 1)}
          >
            {/* Step Circle */}
            <div
              className={`relative flex items-center justify-center w-8 h-8 rounded-full ${
                completedSteps.includes(index + 1)
                  ? " text-white"
                  : currentStep === index + 1
                  ? " text-white "
                  : " text-gray-400"
              }`}
            >
              {completedSteps.includes(index + 1) ? (
                <svg
                  id="fi_10629607"
                  enableBackground="new 0 0 128 128"
                  height="26"
                  viewBox="0 0 128 128"
                  width="26 "
                  xmlns="http://www.w3.org/2000/svg"
                  // fill="#FFC94A"
                  fill="#000"
                >
                  <g>
                    <path d="m124 64c0 5.12-6.29 9.34-7.55 14.06-1.3 4.88 1.99 11.68-.48 15.95-2.51 4.34-10.06 4.86-13.58 8.38s-4.04 11.07-8.38 13.58c-4.27 2.47-11.07-.82-15.95.48-4.72 1.26-8.94 7.55-14.06 7.55s-9.34-6.29-14.06-7.55c-4.88-1.3-11.68 1.99-15.95-.48-4.34-2.51-4.86-10.06-8.38-13.58s-11.07-4.04-13.58-8.38c-2.47-4.27.82-11.07-.48-15.95-1.26-4.72-7.55-8.94-7.55-14.06s6.29-9.34 7.55-14.06c1.3-4.88-1.99-11.68.48-15.95 2.51-4.34 10.06-4.86 13.58-8.38s4.04-11.07 8.38-13.58c4.27-2.47 11.07.82 15.95-.48 4.72-1.26 8.94-7.55 14.06-7.55s9.34 6.29 14.06 7.55c4.88 1.3 11.68-1.99 15.95.48 4.34 2.51 4.86 10.06 8.38 13.58s11.07 4.04 13.58 8.38c2.47 4.27-.82 11.07.48 15.95 1.26 4.72 7.55 8.94 7.55 14.06z"></path>
                    <path
                      d="m81.34 46.12-22.84 22.84-11.84-11.83c-2.57-2.57-6.74-2.57-9.31 0-2.57 2.57-2.57 6.74 0 9.31l16.61 16.61c2.5 2.5 6.56 2.5 9.06 0l27.62-27.62c2.57-2.57 2.57-6.74 0-9.31-2.57-2.57-6.73-2.57-9.3 0z"
                      fill="#fffcee"
                    ></path>
                  </g>
                </svg>
              ) : (
                <span
                  className={`${
                    currentStep === index + 1 ? "text-white" : "text-gray-400"
                  }`}
                >
                  <img className="w-5 h-5 " src="/images/rec (1).svg" alt="" />
                </span>
              )}
            </div>

            {/* Connector Line */}
            {index < (type === "admin" ? 4 : 3) && (
              <div
                className={`h-[1.5px] w-14  md:w-44 ${
                  completedSteps.includes(index + 1)
                    ? "bg-black"
                    : "bg-gray-300"
                }`}
              ></div>
            )}

            {/* Step Label */}
            <div className="flex absolute top-8 -left-3  items-center justify-center w-10 ml-2">
              <span
                className={`text-sm flex text-center ${
                  currentStep === index + 1
                    ? "text-black font-semibold"
                    : "text-gray-400"
                }`}
              >
                {step}
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Step Content with Animation */}
      <div
        className={`transition-content ${
          isTransitioning ? "fade-out" : "fade-in"
        }`}
      >
        {renderStepContent()}
      </div>
    </div>
  );
};

export default ProductListing;
