import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import crypto from "crypto-js";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import "./ProductionStage.css";
import { CgMailReply } from "react-icons/cg";
import { RxCross2 } from "react-icons/rx";
import { IoMdAttach } from "react-icons/io";
import { GrEmoji } from "react-icons/gr";
import { useWebSocket } from "../../components/ServiceFunctions/WebSocketProvider";

function ChatPopup({ orderId }) {
  const location = useLocation();
  const hash = location.hash.substring(1);
  const { email, type } = CheckAuth({ hash });
  const { messages, sendReaction, sendMessage, isConnected, setOpenChat2 } =
    useWebSocket();
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState("");
  const [attachmentType, setAttachmentType] = useState("");
  const [showReactions, setShowReactions] = useState(-1);
  const [replied_uuid, setReplied_uuid] = useState("");
  const [sent, setSent] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const chatRef = useRef(null);
  const reactionsRef = useRef(null);
  const reactions = [
    { id: 0, type: "like", emoji: "👍" },
    { id: 1, type: "dislike", emoji: "👎" },
    { id: 2, type: "love", emoji: "❤️" },
    { id: 3, type: "angry", emoji: "😠" },
    { id: 4, type: "happy", emoji: "😂" },
    { id: 5, type: "sad", emoji: "😢" },
    { id: 6, type: "clap", emoji: "👏" },
    { id: 7, type: "surprised", emoji: "😮" },
    { id: 8, type: "thinking", emoji: "🤔" },
    { id: 9, type: "fire", emoji: "🔥" },
    { id: 10, type: "celebrate", emoji: "🎉" },
    { id: 11, type: "100", emoji: "💯" },
    { id: 12, type: "crying", emoji: "😭" },
    { id: 13, type: "wink", emoji: "😉" },
    { id: 14, type: "cool", emoji: "😎" },
    { id: 15, type: "mind_blown", emoji: "🤯" },
    { id: 16, type: "praying", emoji: "🙏" },
    { id: 17, type: "skull", emoji: "💀" },
    { id: 18, type: "nerd", emoji: "🤓" },
    { id: 19, type: "suspicious", emoji: "🧐" },
  ];

  useEffect(() => {
    setOpenChat2(openChat);
  }, [openChat]);

  const handleSend = () => {
    setSent(!sent);

    if (!isConnected) {
      console.error("WebSocket not connected!");
      return;
    }

    if (message || attachment) {
      const inputString = `${orderId}${Date.now()}${email}`;
      const hash = crypto.SHA256(inputString).toString();
      const message_uuid = hash.slice(0, 10).toLowerCase();
      const messageData = {
        Timestamp: new Date(),
        Message_uuid: message_uuid,
        Replied_uuid: replied_uuid,
        Attachment: attachment,
        Text: message,
        Reaction: "",
        Direction: type === "admin" ? true : false,
        Read: false,
        OrderId: orderId,
      };
      sendMessage(messageData);
      setMessage("");
      setAttachment("");
      setReplied_uuid("");
    } else {
      console.error("Message is empty!");
    }
  };
  console.log(messages);
  const unreadCount = messages?.filter(
    (msg) =>
      !msg.Read &&
      msg.OrderId?.toLowerCase()?.replaceAll("/", "_") ===
        orderId?.toLowerCase() &&
      ((msg.Direction && type !== "admin") ||
        (!msg.Direction && type === "admin"))
  ).length;

  const handleUploadChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64File = reader.result;
      setAttachment(base64File);
      setAttachmentType(getFileType(file.name));
    };
  };

  const imageFormats = ["png", "jpg", "jpeg", "webp", "svg", "gif"];
  const textFormats = ["txt", "md", "csv"];
  const documentFormats = ["doc", "docx", "odt", "rtf", "pdf"];
  const excelFormats = ["xls", "xlsx", "csv"];
  const videoFormats = ["mp4", "avi", "mov", "mkv", "webm"];
  const audioFormats = ["mp3", "wav", "ogg", "flac", "aac"];
  const presentationFormats = ["ppt", "pptx", "odp"];

  const getFileType = (filename) => {
    if (!filename) return "unknown";

    const ext = filename.split(".").pop().toLowerCase();

    if (imageFormats.includes(ext)) return "image";
    if (textFormats.includes(ext)) return "text";
    if (documentFormats.includes(ext)) return "document";
    if (excelFormats.includes(ext)) return "excel";
    if (videoFormats.includes(ext)) return "video";
    if (audioFormats.includes(ext)) return "audio";
    if (presentationFormats.includes(ext)) return "presentation";

    return "unknown";
  };

  const getFileType2 = (filenameOrBase64) => {
    if (!filenameOrBase64) return "unknown";

    if (filenameOrBase64.startsWith("http")) {
      const ext = filenameOrBase64.split(".").pop().toLowerCase().split("?")[0];

      if (imageFormats.includes(ext)) return "image";
      if (textFormats.includes(ext)) return "text";
      if (documentFormats.includes(ext)) return "document";
      if (excelFormats.includes(ext)) return "excel";
      if (videoFormats.includes(ext)) return "video";
      if (audioFormats.includes(ext)) return "audio";
      if (presentationFormats.includes(ext)) return "presentation";

      return "unknown";
    }

    if (filenameOrBase64.startsWith("data:")) {
      const mimeType = filenameOrBase64.split(";")[0].split(":")[1];

      if (mimeType.startsWith("image/")) return "image";
      if (mimeType.startsWith("text/")) return "text";
      if (mimeType === "application/pdf") return "document";
      if (
        mimeType === "application/msword" ||
        mimeType.includes("wordprocessingml")
      )
        return "document";
      if (mimeType.includes("spreadsheet")) return "excel";
      if (mimeType.startsWith("video/")) return "video";
      if (mimeType.startsWith("audio/")) return "audio";
      if (mimeType.includes("presentation")) return "presentation";

      return "unknown";
    }

    return "unknown";
  };

  const renderAttachment = (file, fileType, type) => {
    if (!file) return null;

    const fileLinks = {
      document: "📄 Download Document",
      text: "📄 Download Document",
      excel: "📊 Download Excel File",
      ppt: "📽️ Download PowerPoint File",
      audio: "🎵 Download Audio File",
      unknown: "📁 Download File",
    };

    if (fileType === "image") {
      return (
        <img
          src={file}
          alt="Uploaded Image"
          className={`${
            replied_uuid !== "" || type === "history-reply"
              ? "w-[100px]"
              : type === "history"
              ? "w-[200px]"
              : "w-full"
          } h-auto rounded-lg`}
        />
      );
    }
    if (fileType === "video") {
      return (
        <video
          controls
          className={`${
            replied_uuid !== "" || type === "history-reply"
              ? "w-[100px]"
              : type === "history"
              ? "w-[200px]"
              : "max-w-[300px]"
          } h-auto rounded-lg`}
        >
          <source src={file} type={file} />
          Your browser does not support the video tag.
        </video>
      );
    }

    return fileLinks[fileType] ? (
      <a href={file} download className="text-blue-600 underline block">
        {fileLinks[fileType]}
      </a>
    ) : null;
  };

  // Function to format the date
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const options = {
      weekday: "short", // 'Sat'
      day: "2-digit", // '01'
      month: "short", // 'Mar'
      hour: "2-digit", // '12'
      minute: "2-digit", // '26'
    };

    return date.toLocaleString("en-GB", options).replace(",", ""); // Remove comma between date and time
  };

  const handleClickOutside = (event) => {
    // Close chat if clicked outside the chat container
    if (chatRef.current && !chatRef.current.contains(event.target)) {
      setOpenChat(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside2 = (event) => {
    if (reactionsRef.current && !reactionsRef.current.contains(event.target)) {
      setShowReactions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside2);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);

  const repliedMessage = messages?.find(
    (data) => data.Message_uuid.toLowerCase() === replied_uuid.toLowerCase()
  );

  return (
    <>
      <div
        onClick={() => setOpenChat(true)}
        className={`w-20 h-20 flex items-center justify-center border-2 shadow-xl cursor-pointer group border-yellow-400 transition-opacity ease-in-out duration-700 rounded-full absolute bottom-10 right-10 ${
          openChat ? "opacity-0" : "opacity-100"
        }`}
      >
        {unreadCount > 0 && (
          <span className="absolute bg-blue-700 text-white w-6 h-6 top-0 right-0 text-center border rounded-full">
            {unreadCount}
          </span>
        )}
        <img
          className="w-14 h-14 group-hover:scale-90 duration-500 ease-in-out"
          src="/images/live-chat.svg"
          alt=""
        />
      </div>
      <div
        className={`transition-all duration-700 ease-in-out ${
          openChat ? "demo1-container " : "bg-transparent"
        }}`}
      >
        <div
          ref={chatRef}
          className={`rounded-lg  transition-transform duration-700 ease-in-out  w-96  absolute right-10 bottom-10  ${
            openChat ? "translate-x-[0px]" : "translate-x-[500px]"
          }`}
        >
          <div className="w-full max-w-lg h-[700px] bg-white border-2 border-yellow-200 rounded-lg overflow-hidden flex flex-col items-center justify-between">
            {/* Header */}
            <div className=" p-4  text-center h-24">
              <h2 className="text-gray-500 text-xs">Chatting with</h2>
              <p className="text-sm font-semibold ">
                {type !== "admin"
                  ? "Reshma Ranjan"
                  : orderId.replaceAll("_", "/")}
              </p>
              <p className="text-xs font-medium text-gray-700">
                {type !== "admin" ? "Admin" : "User"}
              </p>
            </div>

            {/* Chat Body */}
            <div className="p-4 space-y-4 h-full overflow-auto flex flex-col w-full justify-end">
              <div className="overflow-y-scroll no-scrollbar">
                {messages
                  ?.filter(
                    (data) =>
                      data?.OrderId?.replaceAll("/", "_")?.toLowerCase() ===
                      orderId?.toLowerCase()
                  )
                  ?.map((msg, index) => {
                    const fileType = msg?.Attachment
                      ? getFileType2(msg?.Attachment)
                      : null;
                    return (
                      <div
                        key={index}
                        className={`text-sm mt-4 w-full flex text-black justify-start ${
                          msg.Direction && type === "admin"
                            ? "flex-row-reverse"
                            : !msg.Direction && type !== "admin"
                            ? "flex-row-reverse"
                            : "flex-row"
                        } items-end gap-2`}
                      >
                        {/* User Indicator */}
                        <div className="w-2">
                          <div
                            className={`border ${
                              msg.Direction && type === "admin"
                                ? "bg-yellow-200"
                                : !msg.Direction && type !== "admin"
                                ? "bg-yellow-200"
                                : "bg-gray-200"
                            } rounded-full font-bold w-2 h-2 text-[#000000]`}
                          ></div>
                        </div>

                        {/* Message Container */}
                        <div
                          className={`${
                            msg.Direction && type === "admin"
                              ? "bg-yellow-200 text-black rounded-l-md"
                              : !msg.Direction && type !== "admin"
                              ? "bg-yellow-200 text-black rounded-l-md"
                              : "bg-gray-200 text-gray-800 rounded-r-md"
                          } rounded-t-md p-1 max-w-xs relative`}
                        >
                          {msg.Replied_uuid && msg.Replied_uuid !== "" && (
                            <>
                              <div
                                className={` ${
                                  msg.Direction && type === "admin"
                                    ? "border-l-yellow-500"
                                    : !msg.Direction && type !== "admin"
                                    ? "border-l-yellow-500"
                                    : "border-l-gray-500"
                                } border-l-4 bg-slate-100 p-1 rounded-md`}
                              >
                                <div className="text-[10px] font-bold text-gray-500">
                                  {!msg?.Direction ? "Admin" : "User"}
                                </div>
                                {messages
                                  .filter(
                                    (data) =>
                                      data.Message_uuid.toLowerCase() ===
                                      msg.Replied_uuid.toLowerCase()
                                  )
                                  .map((msg) =>
                                    msg.Text ? (
                                      <p className="p-2">{msg.Text}</p>
                                    ) : (
                                      msg?.Attachment && (
                                        <div className="border rounded-md bg-slate-100 p-0">
                                          {renderAttachment(
                                            msg?.Attachment,
                                            getFileType2(msg?.Attachment),
                                            "history-reply"
                                          )}
                                        </div>
                                      )
                                    )
                                  )}
                              </div>
                            </>
                          )}

                          {/* Handling Different File Types */}
                          {msg?.Attachment && (
                            <div className="border rounded-md bg-slate-100 p-1">
                              {renderAttachment(
                                msg?.Attachment,
                                getFileType2(msg?.Attachment),
                                "history"
                              )}
                            </div>
                          )}
                          {msg?.Text && <p className="pt-2">{msg.Text}</p>}
                          {/* Timestamp (Optional) */}
                          <div
                            className={`flex justify-between items-center gap-2 text-xs w-full text-right ${
                              msg.Direction && type === "admin"
                                ? "text-black"
                                : !msg.Direction && type !== "admin"
                                ? "text-black"
                                : "text-gray-500"
                            } mt-1`}
                          >
                            <div>
                              {formatTimestamp(msg.Timestamp)
                                .split(", ")
                                .slice(-1)}
                              {((msg.Direction && type === "admin") ||
                                (!msg.Direction && type !== "admin")) &&
                                (msg.Read ? (
                                  <span className="text-blue-500"> ✔✔</span>
                                ) : (
                                  <span className="text-gray-600"> ✔</span>
                                ))}
                            </div>
                            {msg?.Reaction?.length > 0 && (
                              <div className="absolute z-60 top-12 flex gap-[2px] p-1 rounded-full bg-white ">
                                {msg.Reaction.map((reactionType, i) => {
                                  const reaction = reactions.find(
                                    (r) => r.type === reactionType
                                  );
                                  return (
                                    reaction && (
                                      <span key={i} className="w-4 h-4">
                                        {reaction.emoji}
                                      </span>
                                    )
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="group">
                          <div
                            className={`w-[22px] group-hover:w-[44px] flex items-center justify-between duration-150 transition-all p-1 bg-slate-200 rounded-full relative`}
                          >
                            {showReactions === index && (
                              <>
                                <div
                                  ref={reactionsRef}
                                  className={`${
                                    msg.Direction && type === "admin"
                                      ? "right-0"
                                      : !msg.Direction && type !== "admin"
                                      ? "right-0"
                                      : "left-0"
                                  } absolute -top-7 w-[150px] overflow-x-scroll no-scrollbar flex items-center gap-2  justify-between p-1 bg-slate-200 rounded-full`}
                                >
                                  {reactions.map((r, i) => (
                                    <span
                                      key={i}
                                      onClick={() => {
                                        sendReaction(msg.Message_uuid, r.type);
                                        setShowReactions(-1);
                                      }}
                                      className="cursor-pointer"
                                    >
                                      {r.emoji}
                                    </span>
                                  ))}
                                </div>
                              </>
                            )}
                            <div
                              className="cursor-pointer"
                              onClick={() =>
                                setShowReactions((prev) =>
                                  prev === index ? -1 : index
                                )
                              }
                            >
                              <GrEmoji />
                            </div>
                            <div
                              className="cursor-pointer hidden group-hover:flex"
                              onClick={() => {
                                setReplied_uuid(msg.Message_uuid);
                              }}
                            >
                              <CgMailReply />
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${
                            msg.Direction && type === "admin"
                              ? "ml-10"
                              : !msg.Direction && type !== "admin"
                              ? "ml-10"
                              : "mr-10"
                          }`}
                        ></div>
                      </div>
                    );
                  })}
              </div>
            </div>

            {/* Chat Input */}

            {(attachment || replied_uuid) && (
              <div className="slideup border bg-yellow-200 rounded-t-md w-full h-auto p-2 font-bold text-[#000000]">
                {replied_uuid && repliedMessage && (
                  <div className="p-1 border-l-4 border-l-yellow-500 bg-slate-100 relative rounded-md">
                    {" "}
                    <div
                      className="absolute top-0 z-40 right-0 cursor-pointer"
                      onClick={() => {
                        setAttachment("");
                        setAttachmentType("");
                        setReplied_uuid("");
                      }}
                    >
                      <RxCross2 color="#f44336" size={15} />
                    </div>
                    <div className="text-[10px] font-bold text-gray-500">
                      {repliedMessage?.Direction ? "Admin" : "User"}
                    </div>
                    {repliedMessage?.Text ? (
                      <div className="text-sm text-gray-500">
                        {repliedMessage?.Text}
                      </div>
                    ) : (
                      renderAttachment(
                        repliedMessage?.Attachment,
                        getFileType2(repliedMessage?.Attachment),
                        "chat-reply"
                      )
                    )}
                  </div>
                )}

                {renderAttachment(attachment, attachmentType, "chat")}
              </div>
            )}
            <div className="bg-white p-2 flex gap-2 items-center w-full">
              <div className="border border-slate-300 flex w-full rounded-full shadow-sm ">
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSend();
                    }
                  }}
                  placeholder="Type here ..."
                  className="flex-1 px-2 py-1.5 focus:outline-none rounded-full focus:ring-2 focus:ring-yellow-200"
                />
                <button className="relative">
                  <IoMdAttach className="w-6 h-6" />
                  <input
                    type="file"
                    accept=""
                    onChange={(e) => handleUploadChange(e)}
                    className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer z-10 "
                  />
                </button>
              </div>
              <button className="" onClick={handleSend}>
                <img className="w-5 h-5" src="/images/send.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatPopup;
