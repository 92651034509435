import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { LuArrowUpRight } from "react-icons/lu";

function DeletePopup({ onClose, onOpen }) {
  return (
    <div className="popup-container">
      <div className="p-5 rounded-2xl  bg-white w-full md:max-w-[420px] pop-up-animation mx-2 relative">
        <span
          onClick={() => onClose()}
          className="bg-[#ffffff] hover:bg-red-300 border-[1px] border-zinc-400 hover:border-red-500 shadow-sm hover:shadow p-1 rounded-full text-black hover:scale-95 duration-300 cursor-pointer absolute -right-2 -top-2 z-10"
        >
          <IoClose className="text-xl" />
        </span>
        <h2 className="bg-red-100 p-2 rounded-full font-bold text-sm text-center">
          Are you sure you want to cancel your order
        </h2>
        <div className="flex items-start justify-start gap-2 mt-2">
          <div className="relative flex items-center border rounded-md mt-0.5">
            <input
              type="checkbox"
              id="cbx"
              // checked={isDisclaimerChecked}
              // onChange={handleCheckboxChange}
              className="peer hidden w-4 h-4"
            />
            <label htmlFor="cbx" className="check cursor-pointer relative">
              <svg
                viewBox="0 0 18 18"
                className="stroke-gray-400 transition-all peer-hover:stroke-[#FFD61F] peer-checked:stroke-[#FFD61F]"
              >
                <path
                  d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"
                  className="transition-all peer-checked:stroke-dashoffset-60"
                />
                <polyline
                  points="1 9 7 14 15 4"
                  className="transition-all peer-checked:stroke-dashoffset-42 delay-150"
                />
              </svg>
            </label>
          </div>
          <p className="text-[13px] font-medium text-gray-600 text-left">
            Please note that any{" "}
            <span className="text-[#000000] font-semibold">Loyalty Points</span>{" "}
            that may have been redeemed against it will NOT be refunded to your
            Wallet.
          </p>
        </div>
        <div className="mt-2.5">
          <p className="text-sm font-bold">Reason</p>
          <textarea
            name=""
            placeholder="Write reason here…"
            className="min-h-20 border w-full rounded-lg placeholder:text-sm p-2 text-sm outline-none hover:ring-1 hover:ring-red-300 duration-300 mt-2"
            id=""
          ></textarea>
        </div>
        <button
          onClick={() => {
            onClose();
            onOpen();
          }}
          className="bg-red-500 duration-300 px-3 py-1.5 rounded-full text-xs xl:text-[13px] font-semibold text-[#ffffff] flex items-center justify-center gap-1 w-[100%] mx-auto mt-4"
        >
          Cancel Order
          <span className="text-lg">
            <LuArrowUpRight />
          </span>
        </button>
      </div>
    </div>
  );
}

export default DeletePopup;
