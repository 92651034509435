import "./App.css";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AllRoutes from "./components/AllRoutes.jsx";
import Footer from "./components/Footer/Footer.jsx";
import { WebSocketProvider } from "./components/ServiceFunctions/WebSocketProvider.jsx";

function App() {
  return (
    <WebSocketProvider>
      <Router>
        <AllRoutes />
        <Footer />
      </Router>
    </WebSocketProvider>
  );
}

export default App;
