import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { RiUploadCloudFill } from "react-icons/ri";
import secureLocalStorage from "react-secure-storage";
import { IoMdAddCircle } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import ImageUploader from "../../components/ImageEdits/ImageUpload.jsx";
import { FiArrowUpRight } from "react-icons/fi";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth.jsx";
import ImageViewer from "../MaterialCost/ImageViewer.jsx";

function Sole() {
  const location = useLocation();
  const hash = location.hash.substring(1);
  const { phone } = CheckAuth({ hash });
  const storedData = secureLocalStorage.getItem("Material_cost");
  const materialCost = JSON.parse(storedData);
  const [isOpen1, setIsOpen1] = useState();
  const [otp, setOtp] = useState();
  const [otpInputs, setOtpInputs] = useState(["", "", "", "", ""]);
  const [userOtp, setUserOtp] = useState();
  const [selectedItem, setSelectedItem] = useState("");
  const [isAnimating2, setIsAnimating2] = useState(false);
  const [selectedSole, setSelectedSole] = useState("Italian_leather");
  const [selectedOne, setSelectedOne] = useState("Constructions_Add");
  const [dataFetched, setDataFetched] = useState(false);
  const [itemExist, setItemExist] = useState(false);
  const [materialState, setMaterialState] = useState({});
  const [soleOptions, setsoleOptions] = useState([]);
  const [weltOptions, setWeltOptions] = useState([]);
  const [thicknessOptions, setThicknessOptions] = useState([]);
  const [constructionOptions, setConstructionOptions] = useState([]);
  const [edgeOptions, setEdgeOptions] = useState([]);
  const [isOpen, setIsOpen] = useState("");
  const [isDrop, setIsDrop] = useState("");
  const popupRef = useRef(null);
  const [resendPhoneTimer, setResendPhoneTimer] = useState(90);
  const [isOpenImageViewer, setIsOpenImageViewer] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  // OTP Verification functions
  const inputRefs = useRef([]);

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) {
      return;
    }
    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = value;
    setOtpInputs(newOtpInputs);
    const isCompleteOtp = newOtpInputs.every((input) => input !== "");
    if (value && index < otpInputs.length - 1) {
      inputRefs.current[index + 1].focus();
    } else if (value === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
    if (isCompleteOtp) {
      setUserOtp(newOtpInputs.join(""));
    }
  };

  useEffect(() => {
    setIsAnimating2(true);
    const timeout = setTimeout(() => setIsAnimating2(false), 500);
    return () => clearTimeout(timeout);
  }, [selectedOne]);

  // Function to handle clicks outside the popup
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen("");
      setMaterialState({});
    }
  };

  // Effect to add and clean up the event listener
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!dataFetched) {
      materialCost?.forEach((item) => {
        if (item.Soles) {
          setsoleOptions(item.Soles);
        }
        if (item.Constructions) {
          setConstructionOptions(item.Constructions);
        }
        if (item.Edges) {
          setEdgeOptions(item.Edges);
        }
        if (item.Welts) {
          setWeltOptions(item.Welts);
        }
        if (item.Thickness) {
          setThicknessOptions(item.Thickness);
        }
      });
      setDataFetched(true);
    }
  }, [dataFetched]);

  const EditIcon = () => (
    <svg
      viewBox="0 0 492.49284 492"
      className="w-3 h-3"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M304.14 82.47L33.16 353.47c-1.36 1.37-2.35 3.09-2.82 4.95L0.3 479c-.9 3.63.17 7.49 2.82 10.14a10.01 10.01 0 007.53 3.11c.85 0 1.73-.11 2.58-.32l120.55-30.04c1.88-.47 3.59-1.45 4.95-2.81l271-271zM476.87 45.52l-30.16-30.16c-20.16-20.16-55.3-20.14-75.43 0l-36.95 36.95 105.6 105.6 36.95-36.95c10.07-10.07 15.62-23.47 15.62-37.71s-5.55-27.65-15.62-37.72z" />
    </svg>
  );

  const MeasurementIcon = () => (
    <svg
      viewBox="0 0 401.998 401.998"
      className="w-3 h-3"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M326.62 91.08c-1.71-1.71-3.9-2.57-6.56-2.57h-48.82c-3.24-15.79-9.33-29.5-18.27-41.11h66.52c2.67 0 4.85-.86 6.57-2.57 1.7-1.71 2.56-3.9 2.56-6.57V9.14c0-2.67-.85-4.85-2.56-6.57-1.71-1.71-3.9-2.57-6.57-2.57H81.94c-2.67 0-4.85.86-6.57 2.57-1.71 1.71-2.57 3.9-2.57 6.57v37.97c0 2.47.9 4.61 2.71 6.42 1.81 1.81 3.95 2.71 6.42 2.71h41.4c40.16 0 65.67 10.75 76.51 32.26H81.94c-2.67 0-4.86.86-6.57 2.57-1.71 1.71-2.57 3.9-2.57 6.57v29.12c0 2.66.86 4.85 2.57 6.56s3.9 2.57 6.57 2.57h121.91c-4.19 15.61-13.94 27.5-29.27 35.69-15.32 8.18-35.54 12.28-60.67 12.28H81.94c-2.47 0-4.62.91-6.42 2.71s-2.71 3.95-2.71 6.42v36.26c0 2.48.86 4.57 2.57 6.28 36.54 38.83 83.94 93.17 142.18 163.03 1.71 2.29 4.09 3.43 7.14 3.43h55.67c4 0 6.76-1.71 8.28-5.14 1.9-3.43 1.53-6.66-1.14-9.71-55.57-68.14-99.26-119.15-131.05-153.03 32.36-3.81 58.63-14.28 78.8-31.4 20.17-17.13 32.45-39.4 36.83-66.81h47.97c2.66 0 4.85-.86 6.56-2.57s2.57-3.9 2.57-6.56V97.65c0-2.67-.86-4.85-2.57-6.56z" />
    </svg>
  );

  const handleAddOREdit = (item) => {
    if (item.split("_")[1] === "Add") {
      let hasKey = false;
      if (
        item === "Constructions_Add" ||
        item === "Welts_Add" ||
        item === "Edges_Add" ||
        item === "Thickness_Add" ||
        item === "Soles_Add"
      ) {
        const Options =
          item === "Constructions_Add"
            ? constructionOptions
            : "Welts_Add"
            ? weltOptions
            : "Thickness_Add"
            ? thicknessOptions
            : "Soles_Add"
            ? soleOptions
            : item === "Edges_Add" && edgeOptions;
        if (materialState.type && materialState.img && materialState.price) {
          hasKey = !!Object?.keys(Options).find(
            (data) =>
              data.toLowerCase().replace(/_/g, " ") ===
              materialState.type.toLowerCase()
          );
        } else {
          alert("Please fill out all the fields before adding.");
          return;
        }
      } else if (item === "Sole Color_Add") {
        if (materialState.type && materialState.img) {
          const matchedKey = Object?.keys(soleOptions).find(
            (data) => data === selectedSole
          );
          if (matchedKey) {
            hasKey = !!Object?.entries(soleOptions[matchedKey]?.Color).find(
              ([key, value]) =>
                materialState?.type.toLowerCase() === key.toLowerCase()
            );
          }
        } else {
          alert("Please fill out all the fields before adding.");
          return;
        }
      }

      if (hasKey) {
        setItemExist(true);
      } else {
        setItemExist(false);
        if (
          item === "Constructions_Add" ||
          item === "Welts_Add" ||
          item === "Edges_Add" ||
          item === "Thickness_Add" ||
          item === "Soles_Add"
        ) {
          const itemObj = materialCost.find(
            (element) => element.item === item.split("_")[0]
          );
          if (itemObj && itemObj[item.split("_")[0]]) {
            if (item === "Soles_Add") {
              fetch(`https://buwchlifestyles.com/api/save-images-in-s3/`, {
                method: "POST",
                body: JSON.stringify({
                  material: item.split("_")[0],
                  data: materialState,
                }),
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                },
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Failed to fetch data");
                  }
                  return response.json();
                })
                .then((data) => {
                  if (data.status === "success") {
                    itemObj[item.split("_")[0]][materialState.type] = {
                      Price: parseFloat(materialState.price),
                      Img: data.img,
                      days: materialState.days,
                      desc: materialState.desc,
                      Color: {},
                    };
                    secureLocalStorage.setItem(
                      "Material_cost",
                      JSON.stringify(materialCost)
                    );
                  }
                })
                .catch((error) => {
                  console.error("Error: ", error);
                });
            } else {
              fetch(`https://buwchlifestyles.com/api/save-images-in-s3/`, {
                method: "POST",
                body: JSON.stringify({
                  material: item.split("_")[0],
                  data: materialState,
                }),
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                },
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Failed to fetch data");
                  }
                  return response.json();
                })
                .then((data) => {
                  if (data.status === "success") {
                    if (item === "Constructions_Add") {
                      itemObj[item.split("_")[0]][materialState.type] = {
                        Price: parseFloat(materialState.price),
                        Img: data.img,
                        desc: materialState.desc,
                      };
                    } else {
                      itemObj[item.split("_")[0]][materialState.type] = {
                        Price: parseFloat(materialState.price),
                        Img: data.img,
                      };
                    }
                    secureLocalStorage.setItem(
                      "Material_cost",
                      JSON.stringify(materialCost)
                    );
                  }
                })
                .catch((error) => {
                  console.error("Error: ", error);
                });
            }
          }
        } else {
          const itemObj = materialCost.find(
            (element) => element.item === "Soles"
          );
          if (itemObj && itemObj["Soles"]) {
            fetch(`https://buwchlifestyles.com/api/save-images-in-s3/`, {
              method: "POST",
              body: JSON.stringify({
                material: item.split("_")[0],
                data: materialState,
              }),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Failed to fetch data");
                }
                return response.json();
              })
              .then((data) => {
                if (data.status === "success") {
                  itemObj["Soles"][selectedSole]["Color"][materialState.type] =
                    data.img;
                  secureLocalStorage.setItem(
                    "Material_cost",
                    JSON.stringify(materialCost)
                  );
                }
              })
              .catch((error) => {
                console.error("Error: ", error);
              });
          }
        }
        secureLocalStorage.setItem(
          "Material_cost",
          JSON.stringify(materialCost)
        );
        setIsOpen("");
        setMaterialState({});
      }
    } else {
      if (
        item === "Constructions_Edit" ||
        item === "Welts_Edit" ||
        item === "Edges_Edit" ||
        item === "Thickness_Edit" ||
        item === "Soles_Edit"
      ) {
        const itemObj = materialCost.find(
          (element) => element.item === materialState.var
        );
        if (itemObj && itemObj[materialState.var]) {
          if (itemObj[materialState.var][materialState.type]) {
            itemObj[materialState.var][materialState.type].Price = parseFloat(
              materialState.price
            );
            if (item === "Constructions_Edit" || item === "Soles_Edit") {
              itemObj[materialState.var][materialState.type].desc = parseInt(
                materialState.desc
              );
            }
            itemObj[materialState.var][materialState.type].days = parseInt(
              materialState.days
            );
          }
        }
      }
      secureLocalStorage.setItem("Material_cost", JSON.stringify(materialCost));
      setIsOpen("");
    }
    materialCost?.forEach((item) => {
      if (item.Soles) {
        setsoleOptions(item.Soles);
      }
      if (item.Constructions) {
        setConstructionOptions(item.Constructions);
      }
      if (item.Edges) {
        setEdgeOptions(item.Edges);
      }
      if (item.Welts) {
        setWeltOptions(item.Welts);
      }
      if (item.Thickness) {
        setThicknessOptions(item.Thickness);
      }
    });
  };

  const handleResendPhoneTimer = () => {
    setResendPhoneTimer(90);
    const timerInterval = setInterval(() => {
      setResendPhoneTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
    }, 90000);
  };

  const sendPhoneOtp = () => {
    fetch(`https://buwchlifestyles.com/api/register/send-phone-otp/`, {
      method: "POST",
      body: JSON.stringify({
        phone: phone,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to send OTP");
        }
        return response.json();
      })
      .then((response) => {
        var dataObject = JSON.parse(response.msg91_response);
        setOtp(response.otp);
        handleResendPhoneTimer();
        setIsOpen1(true);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const handleSave = () => {
    setIsOpen1(false);
    fetch(`https://buwchlifestyles.com/api/set-material-cost/`, {
      method: "POST",
      body: JSON.stringify({
        material: selectedItem,
        data: materialCost,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        alert(data.message);
        secureLocalStorage.setItem(
          "Material_cost",
          JSON.stringify(data.materials)
        );
        materialCost?.forEach((item) => {
          if (item.Soles) {
            setsoleOptions(item.Soles);
          }
          if (item.Constructions) {
            setConstructionOptions(item.Constructions);
          }
          if (item.Edges) {
            setEdgeOptions(item.Edges);
          }
          if (item.Welts) {
            setWeltOptions(item.Welts);
          }
          if (item.Thickness) {
            setThicknessOptions(item.Thickness);
          }
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const showResendCountdown =
    isOpen1 && resendPhoneTimer > 0 && resendPhoneTimer < 90;

  // Right part API
  const buttonData = [
    { id: "Constructions_Add", label: "Construction" },
    { id: "Welts_Add", label: "Welt" },
    { id: "Edges_Add", label: "Edge" },
    { id: "Thickness_Add", label: "Thickness" },
  ];
  const scrollContainerRef2 = useRef(null);

  const handleScrollSecond = (direction) => {
    if (scrollContainerRef2.current) {
      const scrollAmount = 200; // Adjust the scroll amount as needed
      scrollContainerRef2.current.scrollBy({
        left: direction === "right" ? scrollAmount : -scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handleButtonClick = (id, setSelectedOne, setMaterialState) => {
    setSelectedOne(id);
    setMaterialState(() => ({}));
  };

  return (
    <div className="flex flex-col lg:flex-row justify-between gap-4 w-full">
      {/* soles */}
      <div className="w-full lg:w-[48%] text-black">
        <div className="flex gap-2 items-start md:items-center lg:items-start xl:items-center duration-300 border-l-4 border-y border-r border-l-[#FFD61F] rounded-md w-full mx-auto bg-white shadow-md p-2">
          <p className="font-semibold text-black text-sm flex items-start justify-center gap-2">
            <span className="fill-[#000000] bg-[#FFD61F] rounded-full p-1">
              <svg
                className="w-[10px] h-[10px]"
                id="fi_13984188"
                enableBackground="new 0 0 512 512"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Layer_2_00000040551782923388724880000016905333651627528353_">
                  <g id="Layer_1_copy_6">
                    <g id="_5">
                      <path d="m256 156.3c32.7 0 59.3 26.5 59.3 59.3v237.2c0 32.7-26.5 59.3-59.3 59.3-32.7 0-59.3-26.5-59.3-59.3v-237.2c0-32.8 26.6-59.3 59.3-59.3z"></path>
                      <circle cx="256" cy="59.3" r="59.3"></circle>
                    </g>
                  </g>
                </g>
              </svg>
            </span>{" "}
            <span className="">Sole:</span>
          </p>
          <p className="text-xs">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab,
            commodi.
          </p>
        </div>
        {/* Table */}
        <div className="rounded-lg border-[1px] shadow-md  mt-[22px] flex flex-col text-sm">
          {/* price & Save Button */}
          <div className="w-full flex items-end justify-between p-2">
            <button
              className="border-2 border-gray-400 text-center w-[110px] rounded-lg h-[37px] relative text-black font-semibold group"
              type="button"
              onClick={() => {
                setIsOpen("Soles_Add");
              }}
            >
              <div className="bg-black rounded-md h-[26px] w-[26px] flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[92%] z-10 duration-500">
                <IoMdAddCircle className="text-white text-lg" />
              </div>
              <p className="translate-x-2 text-sm">Add</p>
            </button>

            <button
              className="border-2 border-gray-400 text-center w-[110px] rounded-lg h-[37px] relative text-black font-semibold group"
              type="button"
              onClick={() => {
                sendPhoneOtp();
                setSelectedItem("Soles");
              }}
            >
              <div className="bg-black rounded-md h-[26px] w-[26px] flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[92%] z-10 duration-500">
                <RiUploadCloudFill className="text-white" />
              </div>
              <p className="translate-x-2 text-sm">Save</p>
            </button>
          </div>
          <div className="overflow-x-scroll no-scrollbar">
            <div className="flex flex-col items-center justify-between gap-2 w-full mt-1 p-3 border-t max-h-[450px] lg:max-h-[670px] overflow-y-scroll min-w-[600px] scrollbar-thin ">
              {Object?.keys(soleOptions)?.length === 0 ? (
                <p>No material added.</p>
              ) : (
                Object?.entries(soleOptions)?.map(([key, value], index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className="flex items-center justify-between px-2 py-2 w-full border-b duration-200 hover:bg-zinc-100 animate-slide"
                      >
                        <span className="flex items-center gap-1.5 border rounded-full pl-1 pr-2 py-0.5 min-w-[170px] max-w-[170px]">
                          <img
                            onClick={() => {
                              setIsOpenImageViewer(true);
                              setSelectedImage(value.Img);
                            }}
                            src={value.Img}
                            className="w-8 h-8 object-cover rounded-full cursor-pointer"
                            alt={value.Color || key}
                          />
                          <p
                            className={`relative group w-full truncate cursor-pointer ${
                              key ? " " : "hidden "
                            }`}
                            title={key.replace(/_/g, " ")}
                          >
                            <span className="group-hover:hidden">
                              {key.replace(/_/g, " ").length > 16
                                ? `${key.replace(/_/g, " ").slice(0, 16)}...`
                                : key.replace(/_/g, " ")}
                            </span>
                            <span className="hidden group-hover:flex  text-black whitespace-nowrap animate-marquee">
                              {key.replace(/_/g, " ")}
                            </span>
                          </p>
                        </span>

                        <p className="flex items-center justify-start w-1/3">
                          <span className="mx-auto flex items-center justify-start gap-1">
                            {value.days} days
                          </span>
                        </p>

                        <p className="flex items-center border justify-start w-1/3 overflow-x-scroll">
                          <span className="mx-auto flex items-center justify-start gap-1">
                            {value.desc}
                          </span>
                        </p>
                        <p className="flex items-center justify-start w-1/3">
                          <span className="mx-auto flex items-center justify-start gap-1">
                            <span>
                              <MeasurementIcon />
                            </span>
                            {value.Price}
                          </span>
                        </p>
                        <div className="flex items-center gap-3">
                          <button
                            className="bg-[#FFF4B5] hover:bg-[#FFD61F] duration-200 p-2.5 flex items-center justify-center rounded-lg cursor-pointer"
                            onClick={() => {
                              setIsOpen("Soles_Edit");
                              setMaterialState((prevState) => {
                                return {
                                  ...prevState,
                                  var: "Soles",
                                  type: key,
                                  price: value.Price,
                                  days: value.days,
                                  desc: value.desc,
                                };
                              });
                            }}
                          >
                            <EditIcon />
                          </button>
                          <button
                            className={`${
                              isDrop === key && "rotate-180"
                            } duration-300 p-1 flex items-center justify-center rounded-lg cursor-pointer`}
                            onClick={() => {
                              if (isDrop === key) setIsDrop("");
                              else setIsDrop(key);
                            }}
                          >
                            <span className="p-1.5 rounded-full border duration-300 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]">
                              <svg
                                className="w-3 h-3 fill-[#000000] duration-300"
                                version="1.1"
                                id="fi_32195"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsHlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 451.847 451.847"
                                xmlSpace="preserve"
                              >
                                <g>
                                  <path
                                    d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
		c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
		c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
                                  ></path>
                                </g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                      {isDrop === key && (
                        <>
                          <div className="p-3 mt-2 w-full">
                            <div className="flex gap-2 px-2 py-2 border-b w-full rounded-md duration-200  animate-slide border shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]">
                              {Object?.keys(value?.Color)?.length > 0 ? (
                                <div className="flex flex-col relative w-full">
                                  <p className="font-semibold absolute -top-6 left-[50%] -translate-x-[50%] bg-white px-3 py-1.5 rounded-lg border shadow-md">
                                    Color variants
                                  </p>
                                  <div className="flex items-center gap-2.5 pt-[30px] pb-2.5 flex-wrap">
                                    <button
                                      className="duration-200 p-2.5 flex items-center justify-center rounded-full border cursor-pointer text-xs font-semibold hover:scale-95 hover:rotate-90 hover:bg-black hover:fill-white"
                                      onClick={() => {
                                        setIsOpen("Sole Color_Add");
                                        setSelectedSole(key);
                                        setMaterialState((prevState) => ({
                                          ...prevState,
                                          var: key,
                                        }));
                                      }}
                                    >
                                      <svg
                                        className="w-3 h-3"
                                        viewBox="0 0 448 448"
                                        xmlns="http://www.w3.org/2000/svg"
                                        id="fi_1237946"
                                      >
                                        <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0"></path>
                                      </svg>
                                    </button>
                                    {Object?.entries(value?.Color)?.map(
                                      ([key2, value2]) => (
                                        <span
                                          key={key2}
                                          className="flex items-center gap-1.5 w-full border rounded-full pl-1 pr-2 py-0.5  min-w-[170px] max-w-[170px] bg-white"
                                        >
                                          <img
                                            src={value2}
                                            className="w-8 h-8 object-cover rounded-full"
                                            alt={key2}
                                          />
                                          <p
                                            className="relative group w-full truncate cursor-pointer"
                                            title={key2.replace(/_/g, " ")}
                                          >
                                            {/* Default text display */}
                                            <span className="group-hover:hidden">
                                              {key2.replace(/_/g, " ").length >
                                              16
                                                ? `${key2
                                                    .replace(/_/g, " ")
                                                    .slice(0, 16)}...`
                                                : key2.replace(/_/g, " ")}
                                            </span>
                                            {/* Full text animated on hover */}
                                            <span className="hidden group-hover:flex whitespace-nowrap text-black animate-marquee">
                                              {key2.replace(/_/g, " ")}
                                            </span>
                                          </p>
                                        </span>
                                      )
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div className="flex flex-col relative w-full">
                                    <p className="font-semibold absolute -top-6 left-[50%] -translate-x-[50%] bg-white px-3 py-1.5 rounded-lg border shadow-md">
                                      Color varients
                                    </p>
                                    <div className="flex items-center gap-2.5 pt-[30px] pb-2.5 flex-wrap">
                                      <button
                                        className="duration-200 p-2.5 flex items-center justify-center rounded-full border cursor-pointer text-xs font-semibold hover:scale-95 hover:rotate-90 hover:bg-black hover:fill-white"
                                        onClick={() => {
                                          setIsOpen("Sole Color_Add");
                                          setSelectedSole(key);
                                          setMaterialState((prevState) => ({
                                            ...prevState,
                                            var: key,
                                          }));
                                        }}
                                      >
                                        <svg
                                          className="w-3 h-3"
                                          viewBox="0 0 448 448"
                                          xmlns="http://www.w3.org/2000/svg"
                                          id="fi_1237946"
                                        >
                                          <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0"></path>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Others */}
      <div className="w-full lg:w-[48%] flex flex-col gap-3 text-black">
        {/* Header Section */}
        <div className="flex items-center duration-300 rounded-md justify-between w-[100%] mx-auto bg-white shadow-[0px_0px_0.5px_0.5px_#00000024] p-0">
          {/* Left Arrow */}
          <button
            className="bg-[#FFD61F] hover:bg-[#FEC23D] duration-200 h-full flex items-center justify-center text-lg py-2.5 px-2.5 rounded-l-md cursor-pointer shadow-[4px_0px_50px_1px_#00000024]"
            onClick={() => handleScrollSecond("left")}
          >
            <svg
              className="w-4 h-4 rotate-180"
              viewBox="0 0 256 256"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M228.992 146.827L48.594 250.051c-17.497 9.998-38.04-7.264-31.166-26.206l34.642-95.842-34.642-95.843c-6.874-18.982 13.669-36.205 31.166-26.207l180.398 103.224c14.606 8.319 14.568 29.331 0 37.65z"></path>
            </svg>
          </button>

          {/* Scrollable Header Container */}
          <div
            className="flex justify-start gap-5 overflow-x-scroll no-scrollbar w-full p-1 relative"
            ref={scrollContainerRef2}
          >
            {/* Animated background for the active tab */}
            <div
              className="absolute h-7 z-0 rounded-md bg-[#FFD61F] shadow-inner pointer-events-none transition-transform duration-300 ease-in-out"
              style={{
                transform: `translateX(${
                  buttonData.findIndex((btn) => btn.id === selectedOne) * 168
                }px)`,
                width: "120px",
              }}
            ></div>

            {buttonData.map((button) => (
              <button
                key={button.id}
                className={`relative z-10 rounded-md  py-1 text-sm font-medium text-black text-center whitespace-nowrap min-w-[120px] ${
                  selectedOne === button.id ? "" : "hover:bg-[#FFF4B5]"
                }  duration-200`}
                onClick={() =>
                  handleButtonClick(button.id, setSelectedOne, setMaterialState)
                }
              >
                {button.label}
              </button>
            ))}
          </div>

          {/* Right Arrow */}
          <button
            className="bg-[#FFD61F] hover:bg-[#FEC23D] duration-200 h-full flex items-center justify-center text-lg py-2.5 px-2.5 rounded-r-md cursor-pointer"
            onClick={() => handleScrollSecond("right")}
          >
            <svg
              className="w-4 h-4"
              viewBox="0 0 256 256"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M228.992 146.827L48.594 250.051c-17.497 9.998-38.04-7.264-31.166-26.206l34.642-95.842-34.642-95.843c-6.874-18.982 13.669-36.205 31.166-26.207l180.398 103.224c14.606 8.319 14.568 29.331 0 37.65z"></path>
            </svg>
          </button>
        </div>

        {/* Table Section */}
        <div className="rounded-lg border-[1px] shadow-md  mt-2 flex flex-col text-sm ">
          {/* price & Save Button */}
          <div className="w-full flex items-end justify-between p-2">
            <button
              className="border-2 border-gray-400 text-center w-[110px] rounded-lg h-[37px] relative text-black font-semibold group"
              type="button"
              onClick={() => {
                if (selectedOne === "Constructions_Add")
                  setIsOpen("Constructions_Add");
                else if (selectedOne === "Welts_Add") setIsOpen("Welts_Add");
                else if (selectedOne === "Edges_Add") setIsOpen("Edges_Add");
                else if (selectedOne === "Thickness_Add")
                  setIsOpen("Thickness_Add");
              }}
            >
              <div className="bg-black rounded-md h-[26px] w-[26px] flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[92%] z-10 duration-500">
                <IoMdAddCircle className="text-white text-lg" />
              </div>
              <p className="translate-x-2 text-sm">Add</p>
            </button>

            <button
              className="border-2 border-gray-400 text-center w-[110px] rounded-lg h-[37px] relative text-black font-semibold group"
              type="button"
              onClick={() => {
                sendPhoneOtp();
                setSelectedItem("Soles_Parts");
              }}
            >
              <div className="bg-black rounded-md h-[26px] w-[26px] flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[92%] z-10 duration-500">
                <RiUploadCloudFill className="text-white" />
              </div>
              <p className="translate-x-2 text-sm">Save</p>
            </button>
          </div>
          {selectedOne !== "" && (
            <div className="overflow-x-scroll no-scrollbar">
              <div
                className={`flex flex-col items-center justify-between gap-2 w-full mt-1 p-3 border-t max-h-[450px] lg:max-h-[670px] overflow-y-scroll min-w-[600px] scrollbar-thin ${
                  isAnimating2 ? "animate-slide" : ""
                }`}
              >
                {Object?.keys(
                  selectedOne === "Constructions_Add"
                    ? constructionOptions
                    : selectedOne === "Welts_Add"
                    ? weltOptions
                    : selectedOne === "Thickness_Add"
                    ? thicknessOptions
                    : selectedOne === "Edges_Add" && edgeOptions
                ).length === 0 ? (
                  // Loader
                  <div className="flex items-center justify-center flex-col">
                    <div className="flex scale-75">
                      <div className="loader">
                        <svg viewBox="0 0 80 80">
                          <circle r="32" cy="40" cx="40" id="test"></circle>
                        </svg>
                      </div>

                      <div className="loader triangle">
                        <svg viewBox="0 0 86 80">
                          <polygon points="43 8 79 72 7 72"></polygon>
                        </svg>
                      </div>

                      <div className="loader">
                        <svg viewBox="0 0 80 80">
                          <rect height="64" width="64" y="8" x="8"></rect>
                        </svg>
                      </div>
                    </div>

                    <p className="font-semibold mt-2 text-black">
                      No material added.
                    </p>
                  </div>
                ) : (
                  Object?.entries(
                    selectedOne === "Constructions_Add"
                      ? constructionOptions
                      : selectedOne === "Welts_Add"
                      ? weltOptions
                      : selectedOne === "Thickness_Add"
                      ? thicknessOptions
                      : selectedOne === "Edges_Add" && edgeOptions
                  )?.map(([key, value], index) => {
                    return (
                      <>
                        <div
                          key={index}
                          className="flex items-center justify-between px-2 py-2 w-full border-b duration-200 hover:bg-zinc-100 animate-slide"
                        >
                          <span className="flex items-center gap-1.5 border rounded-full pl-1 pr-2 py-0.5 min-w-[170px] max-w-[170px]">
                            {selectedOne !== "Overheads_Add" && (
                              <img
                                onClick={() => {
                                  setIsOpenImageViewer(true);
                                  setSelectedImage(value.Img);
                                }}
                                src={value.Img}
                                className="w-8 h-8 object-cover rounded-full"
                                alt={value.Color || key}
                              />
                            )}
                            <p
                              className={`relative group w-full truncate cursor-pointer ${
                                key ? " " : "hidden "
                              }`}
                              title={key.replace(/_/g, " ")}
                            >
                              <span className="group-hover:hidden">
                                {key.replace(/_/g, " ").length > 16
                                  ? `${key.replace(/_/g, " ").slice(0, 16)}...`
                                  : key.replace(/_/g, " ")}
                              </span>
                              <span className="hidden group-hover:flex  text-black whitespace-nowrap animate-marquee">
                                {key.replace(/_/g, " ")}
                              </span>
                            </p>
                          </span>
                          <p className="flex items-center justify-start w-1/3">
                            <span className="mx-auto flex items-center justify-start gap-1">
                              <span>
                                <MeasurementIcon />
                              </span>
                              {value.Price}
                            </span>
                          </p>
                          {selectedOne.split("_")[0] === "Constructions" && (
                            <p className="flex items-center border justify-start w-1/3  overflow-x-scroll">
                              <span className="mx-auto flex items-center justify-start gap-1">
                                {value.desc}
                              </span>
                            </p>
                          )}
                          <button
                            className="bg-[#FFF4B5] hover:bg-[#FFD61F] duration-200 p-2.5 flex items-center justify-center rounded-lg cursor-pointer"
                            onClick={() => {
                              setIsOpen(selectedOne.replace("Add", "Edit"));
                              setMaterialState((prevState) => {
                                if (
                                  selectedOne.split("_")[0] === "Constructions"
                                ) {
                                  return {
                                    ...prevState,
                                    var: selectedOne.split("_")[0],
                                    type: key,
                                    price: value.Price,
                                    desc: value.desc,
                                  };
                                } else {
                                  return {
                                    ...prevState,
                                    var: selectedOne.split("_")[0],
                                    type: key,
                                    price: value.Price,
                                  };
                                }
                              });
                            }}
                          >
                            <EditIcon />
                          </button>
                        </div>
                      </>
                    );
                  })
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Add / Edit popup */}
      <>
        {isOpen !== "" && (
          <div className="demo1-container p-2">
            <div
              className="mt-4 p-2 w-[400px] bg-white rounded-lg shadow-md dropdown-animation relative"
              ref={popupRef}
            >
              <span
                onClick={() => {
                  setIsOpen("");
                }}
                className="bg-[#ffffff] hover:bg-red-300 border-[1px] border-zinc-400 hover:border-red-500 shadow-sm hover:shadow p-1 rounded-full text-black hover:scale-95 duration-300 cursor-pointer absolute -right-2 -top-2 z-10"
              >
                <IoClose className="text-xl" />
              </span>
              <div className="font-bold text-black text-base flex flex-col border rounded-md relative">
                <div className="flex items-center gap-2 w-full border-b p-2">
                  <div className="">
                    <p className="font-semibold text-base leading-[18px]">
                      {isOpen.split("_")[0]}
                    </p>
                    <p className="text-xs text-zinc-500 font-semibold">
                      Lorem ipsum dolor sit, amet consectetur
                    </p>
                  </div>
                </div>

                <form className="w-full mt-2 px-3 pt-2 pb-3">
                  {itemExist && (
                    <span className="text-red-400 text-sm text-center">
                      Item exists already
                    </span>
                  )}
                  {(isOpen.split("_")[0] === "Soles" ||
                    isOpen.split("_")[0] === "Constructions") && (
                    <>
                      <p className="text-black text-sm font-semibold">
                        Description
                      </p>
                      <span className="flex items-center border-[1px] hover:border-[#FFD61F]   my-2 rounded">
                        <span className=" border-r p-2 rounded-l">
                          <svg
                            version="1.1"
                            className="w-4 h-4"
                            id="fi_66163"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsHlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 465.2 465.2"
                            xmlSpace="preserve"
                          >
                            <g>
                              <g id="Layer_2_16_">
                                <g>
                                  <path
                                    d="M279.591,423.714c-3.836,0.956-7.747,1.805-11.629,2.52c-10.148,1.887-16.857,11.647-14.98,21.804
				c0.927,4.997,3.765,9.159,7.618,11.876c3.971,2.795,9.025,4.057,14.175,3.099c4.623-0.858,9.282-1.867,13.854-3.008
				c10.021-2.494,16.126-12.646,13.626-22.662C299.761,427.318,289.618,421.218,279.591,423.714z"
                                  ></path>
                                  <path
                                    d="M417.887,173.047c1.31,3.948,3.811,7.171,6.97,9.398c4.684,3.299,10.813,4.409,16.662,2.475
				c9.806-3.256,15.119-13.83,11.875-23.631c-1.478-4.468-3.118-8.95-4.865-13.314c-3.836-9.59-14.714-14.259-24.309-10.423
				c-9.585,3.834-14.256,14.715-10.417,24.308C415.271,165.528,416.646,169.293,417.887,173.047z"
                                  ></path>
                                  <path
                                    d="M340.36,397.013c-3.299,2.178-6.704,4.286-10.134,6.261c-8.949,5.162-12.014,16.601-6.854,25.546
				c1.401,2.433,3.267,4.422,5.416,5.942c5.769,4.059,13.604,4.667,20.127,0.909c4.078-2.352,8.133-4.854,12.062-7.452
				c8.614-5.691,10.985-17.294,5.291-25.912C360.575,393.686,348.977,391.318,340.36,397.013z"
                                  ></path>
                                  <path
                                    d="M465.022,225.279c-0.407-10.322-9.101-18.356-19.426-17.953c-10.312,0.407-18.352,9.104-17.947,19.422
				c0.155,3.945,0.195,7.949,0.104,11.89c-0.145,6.473,3.021,12.243,7.941,15.711c2.931,2.064,6.488,3.313,10.345,3.401
				c10.322,0.229,18.876-7.958,19.105-18.285C465.247,234.756,465.208,229.985,465.022,225.279z"
                                  ></path>
                                  <path
                                    d="M414.835,347.816c-8.277-6.21-19.987-4.524-26.186,3.738c-2.374,3.164-4.874,6.289-7.434,9.298
				c-6.69,7.86-5.745,19.666,2.115,26.361c0.448,0.38,0.901,0.729,1.371,1.057c7.814,5.509,18.674,4.243,24.992-3.171
				c3.057-3.59,6.037-7.323,8.874-11.102C424.767,365.735,423.089,354.017,414.835,347.816z"
                                  ></path>
                                  <path
                                    d="M442.325,280.213c-9.855-3.09-20.35,2.396-23.438,12.251c-1.182,3.765-2.492,7.548-3.906,11.253
				c-3.105,8.156-0.13,17.13,6.69,21.939c1.251,0.879,2.629,1.624,4.126,2.19c9.649,3.682,20.454-1.159,24.132-10.812
				c1.679-4.405,3.237-8.906,4.646-13.382C457.66,293.795,452.178,283.303,442.325,280.213z"
                                  ></path>
                                  <path
                                    d="M197.999,426.402c-16.72-3.002-32.759-8.114-47.968-15.244c-0.18-0.094-0.341-0.201-0.53-0.287
				c-3.584-1.687-7.162-3.494-10.63-5.382c-0.012-0.014-0.034-0.023-0.053-0.031c-6.363-3.504-12.573-7.381-18.606-11.628
				C32.24,331.86,11.088,209.872,73.062,121.901c13.476-19.122,29.784-35.075,47.965-47.719c0.224-0.156,0.448-0.311,0.67-0.468
				c64.067-44.144,151.06-47.119,219.089-1.757l-14.611,21.111c-4.062,5.876-1.563,10.158,5.548,9.518l63.467-5.682
				c7.12-0.64,11.378-6.799,9.463-13.675L387.61,21.823c-1.908-6.884-6.793-7.708-10.859-1.833l-14.645,21.161
				C312.182,7.638,252.303-5.141,192.87,5.165c-5.986,1.036-11.888,2.304-17.709,3.78c-0.045,0.008-0.081,0.013-0.117,0.021
				c-0.225,0.055-0.453,0.128-0.672,0.189C123.122,22.316,78.407,52.207,46.5,94.855c-0.269,0.319-0.546,0.631-0.8,0.978
				c-1.061,1.429-2.114,2.891-3.145,4.353c-1.686,2.396-3.348,4.852-4.938,7.308c-0.199,0.296-0.351,0.597-0.525,0.896
				C10.762,149.191-1.938,196.361,0.24,244.383c0.005,0.158-0.004,0.317,0,0.479c0.211,4.691,0.583,9.447,1.088,14.129
				c0.027,0.302,0.094,0.588,0.145,0.89c0.522,4.708,1.177,9.427,1.998,14.145c8.344,48.138,31.052,91.455,65.079,125.16
				c0.079,0.079,0.161,0.165,0.241,0.247c0.028,0.031,0.059,0.047,0.086,0.076c9.142,9.017,19.086,17.357,29.793,24.898
				c28.02,19.744,59.221,32.795,92.729,38.808c10.167,1.827,19.879-4.941,21.703-15.103
				C214.925,437.943,208.163,428.223,197.999,426.402z"
                                  ></path>
                                  <path
                                    d="M221.124,83.198c-8.363,0-15.137,6.78-15.137,15.131v150.747l137.87,71.271c2.219,1.149,4.595,1.69,6.933,1.69
				c5.476,0,10.765-2.982,13.454-8.185c3.835-7.426,0.933-16.549-6.493-20.384l-121.507-62.818V98.329
				C236.243,89.978,229.477,83.198,221.124,83.198z"
                                  ></path>
                                </g>
                              </g>
                            </g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                          </svg>
                        </span>
                        <input
                          type="text"
                          value={materialState["desc"]}
                          onChange={(e) => {
                            if (isOpen === "Soles_Add") {
                              setMaterialState((prevState) => ({
                                ...prevState,
                                selectedSole,
                                desc: e.target.value.replace(/\s+/g, "_"),
                              }));
                            } else {
                              setMaterialState((prevState) => ({
                                ...prevState,
                                desc: e.target.value.replace(/\s+/g, "_"),
                              }));
                            }
                          }}
                          placeholder="description..."
                          className="px-2 py-1.5 w-full outline-none font-semibold text-sm placeholder:font-normal rounded-r"
                        />
                      </span>
                    </>
                  )}
                  {isOpen.split("_")[1] !== "Edit" && (
                    <>
                      <p className="text-black text-sm font-semibold">Type</p>
                      <span className="flex items-center border-[1px] hover:border-[#FFD61F]   my-2 rounded">
                        <span className=" border-r p-2 rounded-l">
                          <svg
                            version="1.1"
                            className="w-4 h-4"
                            id="fi_66163"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsHlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 465.2 465.2"
                            xmlSpace="preserve"
                          >
                            <g>
                              <g id="Layer_2_16_">
                                <g>
                                  <path
                                    d="M279.591,423.714c-3.836,0.956-7.747,1.805-11.629,2.52c-10.148,1.887-16.857,11.647-14.98,21.804
				c0.927,4.997,3.765,9.159,7.618,11.876c3.971,2.795,9.025,4.057,14.175,3.099c4.623-0.858,9.282-1.867,13.854-3.008
				c10.021-2.494,16.126-12.646,13.626-22.662C299.761,427.318,289.618,421.218,279.591,423.714z"
                                  ></path>
                                  <path
                                    d="M417.887,173.047c1.31,3.948,3.811,7.171,6.97,9.398c4.684,3.299,10.813,4.409,16.662,2.475
				c9.806-3.256,15.119-13.83,11.875-23.631c-1.478-4.468-3.118-8.95-4.865-13.314c-3.836-9.59-14.714-14.259-24.309-10.423
				c-9.585,3.834-14.256,14.715-10.417,24.308C415.271,165.528,416.646,169.293,417.887,173.047z"
                                  ></path>
                                  <path
                                    d="M340.36,397.013c-3.299,2.178-6.704,4.286-10.134,6.261c-8.949,5.162-12.014,16.601-6.854,25.546
				c1.401,2.433,3.267,4.422,5.416,5.942c5.769,4.059,13.604,4.667,20.127,0.909c4.078-2.352,8.133-4.854,12.062-7.452
				c8.614-5.691,10.985-17.294,5.291-25.912C360.575,393.686,348.977,391.318,340.36,397.013z"
                                  ></path>
                                  <path
                                    d="M465.022,225.279c-0.407-10.322-9.101-18.356-19.426-17.953c-10.312,0.407-18.352,9.104-17.947,19.422
				c0.155,3.945,0.195,7.949,0.104,11.89c-0.145,6.473,3.021,12.243,7.941,15.711c2.931,2.064,6.488,3.313,10.345,3.401
				c10.322,0.229,18.876-7.958,19.105-18.285C465.247,234.756,465.208,229.985,465.022,225.279z"
                                  ></path>
                                  <path
                                    d="M414.835,347.816c-8.277-6.21-19.987-4.524-26.186,3.738c-2.374,3.164-4.874,6.289-7.434,9.298
				c-6.69,7.86-5.745,19.666,2.115,26.361c0.448,0.38,0.901,0.729,1.371,1.057c7.814,5.509,18.674,4.243,24.992-3.171
				c3.057-3.59,6.037-7.323,8.874-11.102C424.767,365.735,423.089,354.017,414.835,347.816z"
                                  ></path>
                                  <path
                                    d="M442.325,280.213c-9.855-3.09-20.35,2.396-23.438,12.251c-1.182,3.765-2.492,7.548-3.906,11.253
				c-3.105,8.156-0.13,17.13,6.69,21.939c1.251,0.879,2.629,1.624,4.126,2.19c9.649,3.682,20.454-1.159,24.132-10.812
				c1.679-4.405,3.237-8.906,4.646-13.382C457.66,293.795,452.178,283.303,442.325,280.213z"
                                  ></path>
                                  <path
                                    d="M197.999,426.402c-16.72-3.002-32.759-8.114-47.968-15.244c-0.18-0.094-0.341-0.201-0.53-0.287
				c-3.584-1.687-7.162-3.494-10.63-5.382c-0.012-0.014-0.034-0.023-0.053-0.031c-6.363-3.504-12.573-7.381-18.606-11.628
				C32.24,331.86,11.088,209.872,73.062,121.901c13.476-19.122,29.784-35.075,47.965-47.719c0.224-0.156,0.448-0.311,0.67-0.468
				c64.067-44.144,151.06-47.119,219.089-1.757l-14.611,21.111c-4.062,5.876-1.563,10.158,5.548,9.518l63.467-5.682
				c7.12-0.64,11.378-6.799,9.463-13.675L387.61,21.823c-1.908-6.884-6.793-7.708-10.859-1.833l-14.645,21.161
				C312.182,7.638,252.303-5.141,192.87,5.165c-5.986,1.036-11.888,2.304-17.709,3.78c-0.045,0.008-0.081,0.013-0.117,0.021
				c-0.225,0.055-0.453,0.128-0.672,0.189C123.122,22.316,78.407,52.207,46.5,94.855c-0.269,0.319-0.546,0.631-0.8,0.978
				c-1.061,1.429-2.114,2.891-3.145,4.353c-1.686,2.396-3.348,4.852-4.938,7.308c-0.199,0.296-0.351,0.597-0.525,0.896
				C10.762,149.191-1.938,196.361,0.24,244.383c0.005,0.158-0.004,0.317,0,0.479c0.211,4.691,0.583,9.447,1.088,14.129
				c0.027,0.302,0.094,0.588,0.145,0.89c0.522,4.708,1.177,9.427,1.998,14.145c8.344,48.138,31.052,91.455,65.079,125.16
				c0.079,0.079,0.161,0.165,0.241,0.247c0.028,0.031,0.059,0.047,0.086,0.076c9.142,9.017,19.086,17.357,29.793,24.898
				c28.02,19.744,59.221,32.795,92.729,38.808c10.167,1.827,19.879-4.941,21.703-15.103
				C214.925,437.943,208.163,428.223,197.999,426.402z"
                                  ></path>
                                  <path
                                    d="M221.124,83.198c-8.363,0-15.137,6.78-15.137,15.131v150.747l137.87,71.271c2.219,1.149,4.595,1.69,6.933,1.69
				c5.476,0,10.765-2.982,13.454-8.185c3.835-7.426,0.933-16.549-6.493-20.384l-121.507-62.818V98.329
				C236.243,89.978,229.477,83.198,221.124,83.198z"
                                  ></path>
                                </g>
                              </g>
                            </g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                          </svg>
                        </span>
                        <input
                          type="text"
                          value={materialState["type"]}
                          onChange={(e) => {
                            if (isOpen === "Soles_Add") {
                              setMaterialState((prevState) => ({
                                ...prevState,
                                selectedSole,
                                type: e.target.value.replace(/\s+/g, "_"),
                              }));
                            } else {
                              setMaterialState((prevState) => ({
                                ...prevState,
                                type: e.target.value.replace(/\s+/g, "_"),
                              }));
                            }
                          }}
                          placeholder={
                            isOpen === "Soles_Add" ? "Color" : "Type"
                          }
                          className="px-2 py-1.5 w-full outline-none font-semibold text-sm placeholder:font-normal rounded-r"
                        />
                      </span>
                    </>
                  )}
                  {(isOpen === "Soles_Add" || isOpen === "Soles_Edit") && (
                    <>
                      <p className="text-black text-sm font-semibold">
                        Delivery Timeline
                      </p>
                      <span className="flex items-center border-[1px] hover:border-[#FFD61F]  my-2 rounded">
                        <span className=" border-r p-2 rounded-l">
                          <svg
                            version="1.1"
                            className="w-4 h-4"
                            id="fi_66163"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsHlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 465.2 465.2"
                            xmlSpace="preserve"
                          >
                            <g>
                              <g id="Layer_2_16_">
                                <g>
                                  <path
                                    d="M279.591,423.714c-3.836,0.956-7.747,1.805-11.629,2.52c-10.148,1.887-16.857,11.647-14.98,21.804
				c0.927,4.997,3.765,9.159,7.618,11.876c3.971,2.795,9.025,4.057,14.175,3.099c4.623-0.858,9.282-1.867,13.854-3.008
				c10.021-2.494,16.126-12.646,13.626-22.662C299.761,427.318,289.618,421.218,279.591,423.714z"
                                  ></path>
                                  <path
                                    d="M417.887,173.047c1.31,3.948,3.811,7.171,6.97,9.398c4.684,3.299,10.813,4.409,16.662,2.475
				c9.806-3.256,15.119-13.83,11.875-23.631c-1.478-4.468-3.118-8.95-4.865-13.314c-3.836-9.59-14.714-14.259-24.309-10.423
				c-9.585,3.834-14.256,14.715-10.417,24.308C415.271,165.528,416.646,169.293,417.887,173.047z"
                                  ></path>
                                  <path
                                    d="M340.36,397.013c-3.299,2.178-6.704,4.286-10.134,6.261c-8.949,5.162-12.014,16.601-6.854,25.546
				c1.401,2.433,3.267,4.422,5.416,5.942c5.769,4.059,13.604,4.667,20.127,0.909c4.078-2.352,8.133-4.854,12.062-7.452
				c8.614-5.691,10.985-17.294,5.291-25.912C360.575,393.686,348.977,391.318,340.36,397.013z"
                                  ></path>
                                  <path
                                    d="M465.022,225.279c-0.407-10.322-9.101-18.356-19.426-17.953c-10.312,0.407-18.352,9.104-17.947,19.422
				c0.155,3.945,0.195,7.949,0.104,11.89c-0.145,6.473,3.021,12.243,7.941,15.711c2.931,2.064,6.488,3.313,10.345,3.401
				c10.322,0.229,18.876-7.958,19.105-18.285C465.247,234.756,465.208,229.985,465.022,225.279z"
                                  ></path>
                                  <path
                                    d="M414.835,347.816c-8.277-6.21-19.987-4.524-26.186,3.738c-2.374,3.164-4.874,6.289-7.434,9.298
				c-6.69,7.86-5.745,19.666,2.115,26.361c0.448,0.38,0.901,0.729,1.371,1.057c7.814,5.509,18.674,4.243,24.992-3.171
				c3.057-3.59,6.037-7.323,8.874-11.102C424.767,365.735,423.089,354.017,414.835,347.816z"
                                  ></path>
                                  <path
                                    d="M442.325,280.213c-9.855-3.09-20.35,2.396-23.438,12.251c-1.182,3.765-2.492,7.548-3.906,11.253
				c-3.105,8.156-0.13,17.13,6.69,21.939c1.251,0.879,2.629,1.624,4.126,2.19c9.649,3.682,20.454-1.159,24.132-10.812
				c1.679-4.405,3.237-8.906,4.646-13.382C457.66,293.795,452.178,283.303,442.325,280.213z"
                                  ></path>
                                  <path
                                    d="M197.999,426.402c-16.72-3.002-32.759-8.114-47.968-15.244c-0.18-0.094-0.341-0.201-0.53-0.287
				c-3.584-1.687-7.162-3.494-10.63-5.382c-0.012-0.014-0.034-0.023-0.053-0.031c-6.363-3.504-12.573-7.381-18.606-11.628
				C32.24,331.86,11.088,209.872,73.062,121.901c13.476-19.122,29.784-35.075,47.965-47.719c0.224-0.156,0.448-0.311,0.67-0.468
				c64.067-44.144,151.06-47.119,219.089-1.757l-14.611,21.111c-4.062,5.876-1.563,10.158,5.548,9.518l63.467-5.682
				c7.12-0.64,11.378-6.799,9.463-13.675L387.61,21.823c-1.908-6.884-6.793-7.708-10.859-1.833l-14.645,21.161
				C312.182,7.638,252.303-5.141,192.87,5.165c-5.986,1.036-11.888,2.304-17.709,3.78c-0.045,0.008-0.081,0.013-0.117,0.021
				c-0.225,0.055-0.453,0.128-0.672,0.189C123.122,22.316,78.407,52.207,46.5,94.855c-0.269,0.319-0.546,0.631-0.8,0.978
				c-1.061,1.429-2.114,2.891-3.145,4.353c-1.686,2.396-3.348,4.852-4.938,7.308c-0.199,0.296-0.351,0.597-0.525,0.896
				C10.762,149.191-1.938,196.361,0.24,244.383c0.005,0.158-0.004,0.317,0,0.479c0.211,4.691,0.583,9.447,1.088,14.129
				c0.027,0.302,0.094,0.588,0.145,0.89c0.522,4.708,1.177,9.427,1.998,14.145c8.344,48.138,31.052,91.455,65.079,125.16
				c0.079,0.079,0.161,0.165,0.241,0.247c0.028,0.031,0.059,0.047,0.086,0.076c9.142,9.017,19.086,17.357,29.793,24.898
				c28.02,19.744,59.221,32.795,92.729,38.808c10.167,1.827,19.879-4.941,21.703-15.103
				C214.925,437.943,208.163,428.223,197.999,426.402z"
                                  ></path>
                                  <path
                                    d="M221.124,83.198c-8.363,0-15.137,6.78-15.137,15.131v150.747l137.87,71.271c2.219,1.149,4.595,1.69,6.933,1.69
				c5.476,0,10.765-2.982,13.454-8.185c3.835-7.426,0.933-16.549-6.493-20.384l-121.507-62.818V98.329
				C236.243,89.978,229.477,83.198,221.124,83.198z"
                                  ></path>
                                </g>
                              </g>
                            </g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                          </svg>
                        </span>

                        <input
                          type="number"
                          value={materialState["days"]}
                          onChange={(e) => {
                            setMaterialState((prevState) => ({
                              ...prevState,
                              days: e.target.value,
                            }));
                          }}
                          placeholder="Duration"
                          className="px-2 py-1.5 w-full outline-none font-semibold text-sm placeholder:font-normal rounded-r"
                        />
                      </span>
                    </>
                  )}
                  {isOpen.split("_")[0] !== "Sole Color" && (
                    <>
                      <p className="text-sm font-semibold text-black">Price</p>

                      <span className="flex items-center border-[1px] hover:border-[#FFD61F]  my-2 rounded">
                        <span className=" border-r p-2 rounded-l">
                          <svg
                            id="fi_17988609"
                            className="w-4 h-4"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Icon">
                              <g id="_27">
                                <path d="m382.2 46.4c5.3 2.3 7.2 6.5 7.1 12.1-.2 8.3 0 16.6 0 25 0 9.2-3.1 12.3-12.2 12.3-20.5 0-40.9 0-61.4 0-1.4 0-2.9 0-5.1 0 2.6 4.1 5.3 7.3 6.9 10.9 4 9.4 7.6 18.9 11 28.5.9 2.6 1.8 3.7 4.7 3.6 15-.2 30-.1 45-.1 8.5 0 11.6 3.1 11.6 11.6v26.2c0 8.3-3.3 11.5-11.7 11.5-14.7 0-29.5.1-44.2-.1-2.6 0-3.7.6-4.2 3.4-4.8 26.1-16.8 48.2-37 65.6-18.2 15.7-39.5 25-62.8 30-6.4 1.4-12.8 2.4-19.8 3.7 1.4 1.5 2.5 2.9 3.6 4.1 45.9 49.8 88.9 102 131.9 154.2 2.7 3.3 4.2 6.6 2.1 10.9-2.1 4.2-5.5 5.7-10 5.7-18.6-.1-37.1-.1-55.7 0-4.2 0-7.1-1.8-9.8-4.9-26.6-31.4-53.1-63-80.1-94.1-20.7-23.8-41.9-47-63.2-70.3-4.4-4.9-6.8-9.7-6.5-16.4.5-10.1.1-20.2.1-30.3 0-7.8 3.7-11.4 11.5-11.6 16.9-.3 33.9 0 50.7-1 19.1-1.2 37.4-6 53.2-17.8 9.7-7.2 16.5-16.5 20.1-28.1.3-.9.4-1.8.6-3.1-1.9 0-3.5 0-5.1 0-39.4 0-78.9 0-118.3 0-9.8 0-12.8-2.9-12.8-12.6 0-8.5 0-16.9 0-25.4.1-8.2 3.2-11.4 11.5-11.4h115 5.8c-1.1-1.9-1.8-3.4-2.6-4.8-6.7-10.4-16.5-16.9-27.8-21.2-16.4-6.3-33.7-7.6-51.1-7.6-12.6 0-25.1 0-37.7 0-10 0-13.1-3.1-13.1-13.3 0-11.1.2-22.1-.1-33.2-.1-5.7 1.6-9.8 6.8-12.3h253.1z"></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                        <input
                          type="text"
                          value={materialState["price"]}
                          onChange={(e) => {
                            setMaterialState((prevState) => ({
                              ...prevState,
                              price: e.target.value.replace(/\s+/g, "_"),
                            }));
                          }}
                          placeholder="Price"
                          className="px-2 py-1.5 w-full outline-none font-semibold text-sm placeholder:font-normal rounded-r"
                        />
                      </span>
                    </>
                  )}
                  {isOpen.split("_")[1] !== "Edit" && (
                    <div className="App">
                      <ImageUploader
                        getImg={(img) => {
                          setMaterialState((prevState) => ({
                            ...prevState,
                            img: img,
                          }));
                        }}
                      />
                    </div>
                  )}
                  <button
                    type="button"
                    className="bg-[#FFD61F] hover:bg-[#FEC23D] duration-200 text-black px-2 py-1 rounded-md w-[100px] hover:w-full mt-2 font-semibold mx-auto text-sm flex items-center justify-center gap-1"
                    onClick={() => {
                      handleAddOREdit(isOpen);
                    }}
                  >
                    {isOpen.split("_")[1]}
                    <span>
                      <FiArrowUpRight className="text-lg" />
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </>

      {/* OTP Section */}
      {isOpen1 && (
        <div className="demo1-container flex items-center justify-center h-screen">
          <div className="w-[95%] md:w-[380px] h-auto space-y-4 dropdown-animation">
            <div className="flex flex-col items-center justify-center relative rounded-xl p-4 bg-white shadow-md">
              <span
                onClick={() => {
                  setIsOpen1(false);
                }}
                className="bg-[#ffffff] hover:bg-red-300 border-[1px] border-zinc-300 hover:border-red-500 shadow-sm hover:shadow p-1 rounded-full text-black hover:scale-95 duration-300 cursor-pointer absolute -right-2 -top-2 z-10"
              >
                <IoClose className="text-xl" />
              </span>
              <h6 className="text-xl font-bold">OTP Verification</h6>

              <div className="my-6 w-full grid grid-cols-5 gap-4 items-center">
                {Array.from({ length: 5 }).map((_, index) => (
                  <input
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    className="text-center h-12 w-12 text-[20px] font-bold rounded-md border border-gray-300 focus:ring-2 focus:ring-[#FFD61F] focus:outline-none"
                    type="tel"
                    maxLength="1"
                    onChange={(e) => handleInputChange(e, index)}
                  />
                ))}
              </div>

              <span className="text-gray-500 text-[13px] text-center">
                <span>
                  Please enter the 4-digit (OTP) that we sent to your registered
                  Mobile no.
                </span>
                <p className="mt-2.5">A code has been sent to xxxxxx0676</p>
              </span>

              <button
                onClick={() => {
                  if (otp === userOtp) {
                    handleSave();
                  } else {
                    alert("Otp is incorrect.");
                  }
                }}
                type="button"
                className="mt-4 text-black rounded-md w-full px-4 py-2 bg-[#FFD61F] hover:bg-[#FEC23D] text-sm font-semibold transition duration-200"
              >
                Verify
              </button>

              <div className="text-center">
                <button
                  onClick={sendPhoneOtp}
                  disabled={showResendCountdown}
                  className={`mt-2 text-sm font-semibold cursor-pointer`}
                >
                  {showResendCountdown ? (
                    <>{resendPhoneTimer} seconds</>
                  ) : (
                    <>Resend Code?</>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Image Viewer */}
      {isOpenImageViewer && (
        <ImageViewer
          img={selectedImage}
          onClose={() => setIsOpenImageViewer(false)}
        />
      )}
    </div>
  );
}

export default Sole;
