import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import secureLocalStorage from "react-secure-storage";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth.jsx";
import { useLocation } from "react-router-dom";

function Popup1({ onClose, Popuptype, PopupData, defaultData }) {
  const location = useLocation();
  const hash = location.hash.substring(1);
  const { email } = CheckAuth({ hash });
  let storedData1 = secureLocalStorage.getItem("kycData");
  let userKycData = JSON.parse(storedData1);
  const gstNumber = userKycData?.gst ?? null;
  const [date, setDate] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceValue, setInvoiceValue] = useState("");
  const [igst, setIgst] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [GST, setGST] = useState(0);
  const [selectedPurchaseId, setSelectedPurchaseId] = useState(null);

  function formattedPrice(price) {
    if (price === "Nil") return 0;
    else return Number(price.replace(/[₹,]/g, "").trim());
  }
  function formattedDate(val) {
    if (!val) return "";
    const [day, month, year] = val.split("-");
    return `${year}-${month}-${day}`;
  }
  

  useEffect(() => {
    if (Popuptype === "Edit") {
      const selectedObject = PopupData?.find(
        (item) => item.Purchase_id === defaultData?.purchase_id
      );
      setSelectedPurchaseId(defaultData?.purchase_id);
      setDate(formattedDate(defaultData?.date));
      setInvoiceNumber(defaultData?.invoice_no);
      setInvoiceValue(formattedPrice(defaultData?.invoice_value));
      setIgst(formattedPrice(defaultData?.igst));
      setCgst(formattedPrice(defaultData?.cgst));
      setSgst(formattedPrice(defaultData?.sgst));
      setGST(
        formattedPrice(defaultData?.igst || 0) +
          formattedPrice(defaultData?.cgst || 0) +
          formattedPrice(defaultData?.sgst || 0)
      );
    }
  }, [Popuptype, selectedPurchaseId]);



  useEffect(() => {
    if (!invoiceValue) return; 
    
    const gstRate = 18;
    
    if (gstNumber?.slice(0, 2) === "07") {
      let newCgst = (invoiceValue * gstRate) / 200;
      let newSgst = (invoiceValue * gstRate) / 200;
      setCgst(newCgst);
      setSgst(newSgst);
      setIgst(0);
      setGST(newCgst + newSgst); // Update GST
    } else {
      let newIgst = (invoiceValue * gstRate) / 100;
      setIgst(newIgst);
      setCgst(0);
      setSgst(0);
      setGST(newIgst); // Update GST
    }
  }, [gstNumber, invoiceValue]);
  
  
  const handleSubmit = () => {
    if (!selectedPurchaseId || !invoiceNumber || !invoiceValue) {
      alert("Please fill in all required fields.");
      return;
    }
  
    fetch(`https://buwchlifestyles.com/api/supplier-invoice/`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        date:date,
        selected_purchaseid: selectedPurchaseId,
        old_invoice_number: defaultData.invoice_no,
        invoice_number: invoiceNumber, 
        invoice_value: invoiceValue,
        GST: GST
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update Invoice");
        }
        return response.json();
      })
      .then((data) => {
        alert("Invoice updated successfully!");
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Failed to update Invoice");
      });
  };
  
  

  return (
    <div className="popup-container">
      <div className="p-3 rounded-2xl  bg-white w-full md:max-w-[450px] pop-up-animation relative">
        <h2 className="text-[15px] font-semibold border-b flex items-center justify-between pb-2.5 px-3">
          {Popuptype === true ? <>Add Invoice</> : <>Edit Invoice</>}
          <span
            onClick={() => onClose()}
            className="bg-[#ffffff] hover:bg-red-300 border-[1px] border-zinc-400 hover:border-red-500 shadow-sm hover:shadow p-1 rounded-full text-black hover:scale-95 duration-300 cursor-pointer absolute -right-2 -top-2 z-10"
          >
            <IoClose className="text-xl" />
          </span>
        </h2>

        <div className="flex flex-col gap-4 px-3 items-start justify-between py-3.5">
          <div className="font-semibold flex items-center justify-between w-full">
            Purchase id :
            <select
              name="purchaseId"
              id="purchaseId"
              value={
                Popuptype === "Edit"
                  ? defaultData.purchase_id
                  : selectedPurchaseId?.Purchase_id || ""
              }
              onChange={(e) => {
                const selectedObject = PopupData.find(
                  (item) => item.Purchase_id === e.target.value
                );
                setSelectedPurchaseId(selectedObject);
              }}
              className="mt-1 px-2 py-1 text-gray-700 bg-white border-gray-300 rounded-md focus:outline-none shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] transition-all font-medium w-[52%] text-[13px]"
            >
              <option value="" disabled selected>
                Select Purchase ID
              </option>
              {PopupData?.map((item, index) => (
                <option key={index} value={item.Purchase_id}>
                  {item.Purchase_id}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center justify-between w-full">
            <h2 className="font-semibold">Date:</h2>

            <div className="flex min-w-[52%] flex-wrap md:flex-nowrap gap-4">
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
                className="px-2 py-1 text-gray-700  border-gray-300 rounded-md focus:outline-none shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] transition-all font-medium w-full placeholder:text-xs"
              />
            </div>
          </div>

          {/*  */}

          <div className="font-semibold  flex items-center justify-between w-full">
            Invoice No :
            <input
              id="text"
              type="text"
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              required
              placeholder="Enter Invoice Number"
              className="mt-1 px-2 py-1 text-gray-700 bg-white  border-gray-300 rounded-md focus:outline-none shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] transition-all font-medium w-[52%]  placeholder:text-xs"
            />
          </div>
          <div className="flex items-center justify-between w-full">
            <h2 className="font-semibold">Invoice value:</h2>
            <input
              id="text"
              type="number"
              value={invoiceValue}
              onChange={(e) => setInvoiceValue(e.target.value)}
              required
              placeholder="Enter Invoice value"
              className="mt-1 px-2 py-1 text-gray-700 bg-white  border-gray-300 rounded-md focus:outline-none shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] transition-all font-medium w-[52%]  placeholder:text-xs"
            />
          </div>

          {/* GST */}
          <div className="flex flex-col w-full">
            <h2 className="font-semibold">GST: {GST?.toFixed(2)}</h2>

            <div className="space-y-1 pl-3 mt-2">
              {" "}
              {/* Spacing between items */}
              <div className="flex items-baseline">
                {/* IGST */}
                <span className="mr-2 flex items-center gap-2 font-medium">
                  <svg
                    className="w-3.5 h-3.5"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_2740706"
                  >
                    <g id="Layer_70" data-name="Layer 70">
                      <circle cx="12" cy="12" r="5"></circle>
                      <path d="m12 2a10 10 0 1 0 10 10 10 10 0 0 0 -10-10zm0 17a7 7 0 1 1 7-7 7 7 0 0 1 -7 7z"></path>
                    </g>
                  </svg>
                  IGST :
                </span>

                <span className="">
                  {igst?.toFixed(2)}
                </span>
              </div>
              <div className="flex items-baseline">
                {/* CGST */}
                <span className="mr-2 flex items-center gap-2 font-medium">
                  <svg
                    className="w-3.5 h-3.5"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_2740706"
                  >
                    <g id="Layer_70" data-name="Layer 70">
                      <circle cx="12" cy="12" r="5"></circle>
                      <path d="m12 2a10 10 0 1 0 10 10 10 10 0 0 0 -10-10zm0 17a7 7 0 1 1 7-7 7 7 0 0 1 -7 7z"></path>
                    </g>
                  </svg>
                  CGST :
                </span>

                <span className="">
                  {cgst?.toFixed(2)}
                </span>
              </div>
              <div className="flex items-baseline">
                {/* SGST */}
                <span className="mr-2 flex items-center gap-2 font-medium">
                  <svg
                    className="w-3.5 h-3.5"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_2740706"
                  >
                    <g id="Layer_70" data-name="Layer 70">
                      <circle cx="12" cy="12" r="5"></circle>
                      <path d="m12 2a10 10 0 1 0 10 10 10 10 0 0 0 -10-10zm0 17a7 7 0 1 1 7-7 7 7 0 0 1 -7 7z"></path>
                    </g>
                  </svg>
                  SGST :
                </span>

                <span className="">
                  {sgst?.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Details */}
        <div className="flex justify-between rounded-lg font-semibold p-2.5 m-2.5 bg-[#FFF4B5]">
          <p>Total Purchase Value:</p>
          <span className="text-sm">
            {Popuptype === "Edit"
              ? defaultData.total_invoice_value
              : selectedPurchaseId?.Total_purchase_value}
          </span>
        </div>
        <div className="flex flex-col bg-zinc-100 rounded-lg font-semibold m-2.5">
          <div className="flex justify-between px-3 py-2">
            <p>Amount Paid:</p>
            <span className="text-sm ">₹{" "}
      {selectedPurchaseId?.Bank_Transactions?.reduce(
        (acc, txn) => acc + (txn.Amount ? parseInt(txn.Amount) : 0),
        0
      ) || 0}</span>
          </div>
          <div className="flex justify-between  px-3 py-2 rounded-b-lg">
            <p>Balance Value:</p>
            <span className="text-sm text-red-600">₹{" "}
      {selectedPurchaseId?.Total_purchase_value
        ? parseInt(selectedPurchaseId.Total_purchase_value) -
          selectedPurchaseId?.Bank_Transactions?.reduce(
            (acc, txn) => acc + (txn.Amount ? parseInt(txn.Amount) : 0),
            0
          )
        : "N/A"}</span>
          </div>
        </div>

        {/* Change Button */}
        {Popuptype === "edit" || PopupData ? (
          <button
            onClick={() => {
              handleSubmit();
              onClose();
            }}
            className="bg-[#FFD61F] hover:bg-[#FEC23D] duration-300 px-5 py-1.5 rounded-full text-sm font-semibold text-black flex items-center justify-center gap-2 w-fit disabled:opacity-50 disabled:cursor-not-allowed mx-auto mb-1 mt-4"
          >
            Change
            <span className="text-xl">
              <svg
                version="1.1"
                className="w-3.5 h-3.5"
                id="fi_545661"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsHlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M493.815,70.629c-11.001-1.003-20.73,7.102-21.733,18.102l-2.65,29.069C424.473,47.194,346.429,0,256,0
              C158.719,0,72.988,55.522,30.43,138.854c-5.024,9.837-1.122,21.884,8.715,26.908c9.839,5.024,21.884,1.123,26.908-8.715
              C102.07,86.523,174.397,40,256,40c74.377,0,141.499,38.731,179.953,99.408l-28.517-20.367c-8.989-6.419-21.48-4.337-27.899,4.651
              c-6.419,8.989-4.337,21.479,4.651,27.899l86.475,61.761c12.674,9.035,30.155,0.764,31.541-14.459l9.711-106.53
              C512.919,81.362,504.815,71.632,493.815,70.629z"
                    ></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M472.855,346.238c-9.838-5.023-21.884-1.122-26.908,8.715C409.93,425.477,337.603,472,256,472
              c-74.377,0-141.499-38.731-179.953-99.408l28.517,20.367c8.989,6.419,21.479,4.337,27.899-4.651
              c6.419-8.989,4.337-21.479-4.651-27.899l-86.475-61.761c-12.519-8.944-30.141-0.921-31.541,14.459l-9.711,106.53
              c-1.003,11,7.102,20.73,18.101,21.733c11.014,1.001,20.731-7.112,21.733-18.102l2.65-29.069C87.527,464.806,165.571,512,256,512
              c97.281,0,183.012-55.522,225.57-138.854C486.594,363.309,482.692,351.262,472.855,346.238z"
                    ></path>
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </span>
          </button>
        ) : (
          <button
            onClick={() => {
              handleSubmit();
              onClose();
            }}
            className="bg-[#FFD61F] hover:bg-[#FEC23D] duration-300 px-5 py-1.5 rounded-full text-sm font-semibold text-black mx-auto flex items-center justify-center gap-2 w-fit disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Submit
            <span className="text-xl">
              <svg
                id="fi_5467630"
                className="w-2.5 h-2.5"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
              >
                <path d="m512 40v432a40 40 0 0 1 -80 0v-335.432l-363.716 363.717a40 40 0 0 1 -56.569-56.569l363.717-363.716h-335.432a40 40 0 0 1 0-80h432a40 40 0 0 1 40 40z"></path>
              </svg>
            </span>
          </button>
        )}
      </div>
    </div>
  );
}

export default Popup1;
