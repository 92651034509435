import React from "react";
import secureLocalStorage from "react-secure-storage";


function TableData({ editpopup, deletepopup, userType }) {
  let storedData1 = secureLocalStorage.getItem("kycData");
  let userKycData = JSON.parse(storedData1);
  let storedData2 = secureLocalStorage.getItem("purchase_specs");
  let purchase_specs_data = JSON.parse(storedData2);
  const gstNumber = userKycData?.gst ?? null;
  
  let data_API = [];
  
  purchase_specs_data.forEach((purchase) => {
    if (purchase.Invoices && Array.isArray(purchase.Invoices)) {
      purchase.Invoices.forEach((invoice) => {
        let gst = Number(invoice.GST) || 0;
        let igst = "Nil",
          cgst = "Nil",
          sgst = "Nil";
  
        if (gst > 0) {
          if (gstNumber?.slice(0, 2) === "07") {
            let halfGST = gst / 2;
            cgst = `₹ ${halfGST.toLocaleString("en-IN")}.00`;
            sgst = `₹ ${halfGST.toLocaleString("en-IN")}.00`;
          } else {
            igst = `₹ ${gst.toLocaleString("en-IN")}.00`;
          }
        }
  
        data_API.push({
          date: invoice.Date || "",
          purchase_id: purchase.Purchase_id || "",
          invoice_no: invoice.Invoice_No || "",
          invoice_value: `₹ ${Number(invoice.Amount).toLocaleString("en-IN")}.00`,
          igst: igst,
          cgst: cgst,
          sgst: sgst,
          total_invoice_value: `₹ ${Number(purchase.Total_purchase_value).toLocaleString("en-IN")}.00`, // Use Total_purchase_value here
        });
      });
    }
  });

  return (
    <table className="min-w-full font-medium table-auto shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-xl overflow-hidden text-[13px]">
      <thead className="">
        <tr className="rounded-t-xl bg-[#FFF4B5] text-[#000000]">
          <th className="px-3 py-2 text-center">Date</th>
          <th className="px-3 py-2 text-center">Purchase id</th>
          <th className="px-3 py-2 text-center ">Invoice No</th>
          <th className="px-3 py-2 text-center ">Invoice Value</th>
          <th className="px-3 py-2 text-center">IGST</th>
          <th className="px-3 py-2 text-center ">CSGT</th>
          <th className="px-3 py-2 text-center ">SSGT</th>
          <th className="px-3 py-2 text-center ">Total Purchase Value</th>
          {userType === "supplier" && (
            <th className="px-3 py-2 text-center ">Action</th>
          )}
        </tr>
      </thead>
      <tbody>
        {data_API.map((item, index) => (
          <>
            <tr key={item.id} className="border-b bg-white">
              <td className="px-3 py-2  text-center ">{item.date}</td>
              <td className="px-3 py-2  text-center  border-zinc-200">
                {item.purchase_id}
              </td>
              <td className="px-3 py-2  text-center  border-zinc-200 w-fit">
                {item.invoice_no}
              </td>

              <td className="px-3 py-2  text-center   border-zinc-200">
                {item.invoice_value}
              </td>
              <td className="px-3 py-2  text-center  border-zinc-200">
                {item.igst}
              </td>
              <td className="px-3 py-2  text-center  border-zinc-200 w-fit">
                {item.cgst}
              </td>
              <td className="px-3 py-2  text-center  border-zinc-200 w-fit">
                {item.sgst}
              </td>
              <td className="px-3 py-2  text-center  border-zinc-200 w-fit">
                {item.total_invoice_value}
              </td>
              {userType === "supplier" && (
                <td className="px-3 py-2  text-center  border-zinc-200 w-fit flex items-center gap-2">
                  <button
                    onClick={() => editpopup(item)}
                    className="hover:bg-[#FFF4B5] duration-100 p-1.5 flex items-center justify-center rounded-md cursor-pointer border-[1px] border-gray-700 bg-slate-50 hover:border-[#FEC23D]"
                  >
                    <svg
                      viewBox="0 0 492.49284 492"
                      className="w-3 h-3"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M304.14 82.47L33.16 353.47c-1.36 1.37-2.35 3.09-2.82 4.95L0.3 479c-.9 3.63.17 7.49 2.82 10.14a10.01 10.01 0 007.53 3.11c.85 0 1.73-.11 2.58-.32l120.55-30.04c1.88-.47 3.59-1.45 4.95-2.81l271-271zM476.87 45.52l-30.16-30.16c-20.16-20.16-55.3-20.14-75.43 0l-36.95 36.95 105.6 105.6 36.95-36.95c10.07-10.07 15.62-23.47 15.62-37.71s-5.55-27.65-15.62-37.72z" />
                    </svg>
                  </button>
                  <button onClick={() => deletepopup(item)} className="w-7 h-7">
                    <img
                      // onClick={() => handleRemove(index)}
                      src="/images/delete.svg"
                      alt=""
                      className="w-7 h-7 bg-slate-50 border-[1px] border-gray-800 hover:border-red-600 hover:bg-red-200 duration-200 ease-in-out  rounded-full p-1"
                    />
                  </button>
                </td>
              )}
            </tr>
          </>
        ))}
      </tbody>
    </table>
  );
}

export default TableData;
