import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import secureLocalStorage from "react-secure-storage";

function Sourcing({ orderId, onNext }) {
  const location = useLocation();
  const hash = location.hash.substring(1);
  const { email, type, currency } = CheckAuth({ hash });
  let MaterialCost = JSON.parse(secureLocalStorage.getItem("Material_cost"));
  let productionStages = JSON.parse(
    secureLocalStorage.getItem("production_stages")
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState({});

  const handleChange = (event, idx, refId) => {
    const newStatus = event.target.value;

    setSelectedStatuses((prev) => ({
      ...prev,
      [refId]: {
        ...(prev[refId] || {}),
        [idx]: newStatus,
      },
    }));
  };

  const checkAllItemsReceived = (orderId, productionStages, data) => {
    const productionStage = productionStages.find(
      (stage) => stage.Order_id === orderId.replaceAll("_", "/")
    );

    if (!productionStage) {
      console.log(`No production stage found for Order ID: ${orderId}`);
      return;
    }

    let allReceived = true;

    data.map((card) =>
      card.items.map((item) => {
        const sourcingStatus =
          productionStage["Products"][card.ref_id]["Sourcing"][
            item.admin_action
          ];
        if (sourcingStatus !== "Received") {
          allReceived = false;
          console.log(
            `Not all items are received for product: ${card.ref_id} (Item: ${item.name})`
          );
        }
      })
    );

    if (!allReceived) {
      console.log("Not all items are received, stopping the process.");
      return;
    }

    console.log("All items are received for order:", orderId);
    onNext();
  };

  const handleSourcingStatus = () => {
    fetch(`https://buwchlifestyles.com/api/change-sourcing-status/`, {
      method: "POST",
      body: JSON.stringify({
        email,
        data: selectedStatuses,
        orderId: orderId.replaceAll("_", "/"),
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((response) => {
        if (response.message === "success") {
          secureLocalStorage.setItem(
            "production_stages",
            JSON.stringify(response.data)
          );
          productionStages = JSON.parse(
            secureLocalStorage.getItem("production_stages")
          );
          checkAllItemsReceived(orderId, productionStages, data);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const transformProductionStages = (productionStages) => {
    let transformedData = {};

    productionStages
      ?.filter(
        (order) =>
          order.Order_id.toLowerCase() ===
          orderId.replaceAll("_", "/").toLowerCase()
      )
      .forEach((stage) => {
        Object.entries(stage.Products).forEach(([productId, productData]) => {
          if (!transformedData[productId]) {
            transformedData[productId] = {};
          }
          Object.entries(productData.Sourcing).forEach(([key, value]) => {
            transformedData[productId][key] = value || "Not Available";
          });
        });
      });

    return transformedData;
  };

  useEffect(() => {
    const order = productionStages?.find(
      (order) =>
        order.Order_id?.toLowerCase() ===
        orderId?.replaceAll("_", "/").toLowerCase()
    );

    const modified_data = Object.entries(order?.Products || {})?.map(
      ([key, data]) => {
        // Helper function to safely get an image based on the item type
        const getItemImage = (itemType, typeKey, dataKey) => {
          const item = MaterialCost?.find((mat) => mat.item === itemType);
          const type = data?.[dataKey]?.type?.replaceAll(" ", "_");
          return item?.[itemType]?.[type]?.Img || "NA";
        };

        // Get the image for the buckle
        const buckleImage = getItemImage("Buckles", "Buckles", "Buckles");

        // Build the items array, conditionally including the buckle if the image isn't "NA"
        const items = [
          {
            name: `${data?.Leather?.type || "NA"} Leather`,
            action: "view",
            admin_action: "Leather",
            image: MaterialCost?.find((mat) => {
              if (
                mat.item === "Leathers" &&
                mat.Leathers?.[data?.Leather?.type?.replaceAll(" ", "_")]
              ) {
                const leather =
                  mat.Leathers[data?.Leather?.type?.replaceAll(" ", "_")];
                const leatherKey = Object.keys(leather).find(
                  (key) =>
                    leather[key].Color ===
                    data?.color_leather?.replaceAll(" ", "_")
                );
                return leatherKey;
              }
            })?.Leathers?.[data?.Leather?.type?.replaceAll(" ", "_")]?.[
              Object.keys(
                MaterialCost.find(
                  (mat) =>
                    mat.item === "Leathers" &&
                    mat.Leathers?.[data?.Leather?.type?.replaceAll(" ", "_")]
                )?.Leathers?.[data?.Leather?.type?.replaceAll(" ", "_")]
              ).find(
                (key) =>
                  MaterialCost.find(
                    (mat) =>
                      mat.item === "Leathers" &&
                      mat.Leathers?.[data?.Leather?.type?.replaceAll(" ", "_")]
                  )?.Leathers?.[data?.Leather?.type?.replaceAll(" ", "_")][key]
                    .Color === data?.color_leather?.replaceAll(" ", "_")
              )
            ]?.Img,
          },
          {
            name: `${data?.Sole?.type || "NA"} Sole`,
            action: "view",
            admin_action: "Sole",
            image: getItemImage("Soles", "Soles", "Sole"),
          },
          {
            name: `${data?.Lining?.type || "NA"} Lining`,
            action: "view",
            admin_action: "Lining",
            image: getItemImage("Linings", "Linings", "Lining"),
          },
        ];

        // Only include the buckle if the image is not "NA"
        if (buckleImage !== "NA") {
          items.push({
            name: `${data?.Buckles?.type || "NA"} Buckles`,
            action: "view",
            admin_action: "Buckles",
            image: buckleImage,
          });
        }

        return {
          image: "/images/test-tan.webp",
          ref_id: key,
          title: data.Article_no,
          items,
        };
      }
    );

    setData(modified_data);

    const transformedData = transformProductionStages(productionStages);
    setSelectedStatuses(transformedData);
  }, [email, JSON.stringify(productionStages)]);

  const handleViewClick = (item) => {
    setSelectedItem(item);
    setModalOpen(true);
  };

  const statuses = ["Received", "Pending", "Not Available"];

  return (
    <div>
      <div className="flex flex-wrap gap-3">
        {data.map((card, index) => (
          <>
            <div
              key={index}
              className="  max-md:p-2 md:p-3 bg-white shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-lg h-max w-[450px] 2xl:w-[550px]"
            >
              <h3 className="font-semibold text-gray-800 text-xs md:text-sm w-full h-3 md:h-4">
                {card.title.replace(/_/g, " ")}
              </h3>
              <div className="flex md:flex-row max-md:flex-col">
                {/* Image Section */}
                <div className="flex items-center max-md:justify-center md:justify-start md:w-28 h-36 w-full md:h-28">
                  <img
                    src={card.image}
                    alt={card.title}
                    className="w-44 md:w-24 h-32 md:h-24 object-cover rounded"
                  />
                </div>
                {/* Details Section */}
                <div className="flex-grow overflow-y-auto ">
                  <ul className="mt-2 space-y-1">
                    {card.items.map((item, idx) => (
                      <li
                        key={idx}
                        className="flex justify-between items-center w-full"
                      >
                        <div className="text-[10px] md:text-xs text-gray-700 w-6/12 font-semibold">
                          {item.name}
                        </div>
                        {type === "admin" ? (
                          <div className="text-xs font-semibold  w-4/12 md:w-3/12 text-end underline cursor-pointer  duration-300 ease-in-out max-md:pb-1">
                            <select
                              id={item.admin_action}
                              onChange={(e) => {
                                handleChange(e, item.admin_action, card.ref_id);
                              }}
                              value={
                                selectedStatuses[card.ref_id][item.admin_action]
                              }
                              className={`w-full py-0 md:py-0.5  border max-md:rounded-[4px] md:rounded-md text-[10px] md:text-xs font-medium  ring-1  focus:outline-none appearance-none truncate ${
                                selectedStatuses[
                                  `${card.ref_id} ${item.admin_action}`
                                ] === "Received"
                                  ? "ring-green-500 "
                                  : selectedStatuses[
                                      `${card.ref_id} ${item.admin_action}`
                                    ] === "Not Available"
                                  ? "ring-red-500 "
                                  : selectedStatuses[
                                      `${card.ref_id} ${item.admin_action}`
                                    ] === "Pending"
                                  ? "ring-yellow-500 "
                                  : ""
                              }`}
                            >
                              {statuses.map((status, index) => (
                                <option key={index} value={status}>
                                  {status}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : (
                          <div
                            className={` text-[10px] md:text-xs font-medium w-4/12 md:w-3/12 ${
                              selectedStatuses[card.ref_id][
                                item.admin_action
                              ] === "Received"
                                ? "text-green-500"
                                : selectedStatuses[card.ref_id][
                                    item.admin_action
                                  ] === "Pending"
                                ? "text-yellow-500"
                                : "text-red-500"
                            }`}
                          >
                            {selectedStatuses[card.ref_id][item.admin_action]}
                          </div>
                        )}
                        <div
                          className={` text-[10px] md:text-xs font-semibold w-2/12 md:w-3/12 text-end underline cursor-pointer  duration-300 ease-in-out ${
                            selectedStatuses[card.ref_id][item.admin_action] ===
                            "Received"
                              ? "hover:text-yellow-400"
                              : "hover:text-red-400"
                          }`}
                          onClick={() =>
                            item.action === "view" && handleViewClick(item)
                          }
                        >
                          {item.action}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
      {modalOpen && selectedItem && (
        <div className="demo1-container ">
          <div className="bg-white max-md:p-2 md:p-6 rounded-lg w-[300px] lg:w-[600px] pop-up-animation border">
            <div className="md:text-base text-sm lg:text-lg font-semibold text-center mb-3">
              {selectedItem.name}

              <button
                className="float-right"
                onClick={() => setModalOpen(false)}
                aria-label="Close"
              >
                <svg
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_1828945"
                  className=" hover:fill-red-600 hover:bg-red-300 rounded-full duration-300 ease-in-out hover:scale-90  w-4 lg:w-6 h-4 lg:h-6"
                >
                  <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
                  <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
                  <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
                </svg>
              </button>
            </div>
            <div className=" space-y-4">
              {selectedItem.image && (
                <img
                  src={selectedItem.image}
                  alt={selectedItem.name}
                  className="w-full  object-cover rounded"
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-end">
        <button
          className="border rounded-md shadow-sm py-1 px-3 bg-yellow-500"
          onClick={() => {
            handleSourcingStatus();
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default Sourcing;
