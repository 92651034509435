import { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";

const CheckAuth = ({ hash }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [currency, setCurrency] = useState();
  const [type, setType] = useState("");
  const [loaderCheckAuth, setLoaderCheckAuth] = useState(true);
  const [message, setMessage] = useState("");
  const storedData = secureLocalStorage.getItem("kycData");
  const storedData2 = secureLocalStorage.getItem("Material_cost");
  const storedData3 = secureLocalStorage.getItem("Customize");
  const storedData4 = secureLocalStorage.getItem("Catalogue");
  const storedData5 = secureLocalStorage.getItem("user_order");
  const storedData6 = secureLocalStorage.getItem("production_stages");
  const storedData7 = secureLocalStorage.getItem("purchase_specs");

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  const setTokens = (accessToken, refreshToken) => {
    const access_token_expires = new Date();
    const refresh_token_expires = new Date();

    // Set access token to expire after 1 minute
    access_token_expires.setTime(
      access_token_expires.getTime() + 60 * 60 * 1000
    );

    // Set refresh token to expire after 12 hours
    refresh_token_expires.setTime(
      refresh_token_expires.getTime() + 12 * 60 * 60 * 1000
    );

    document.cookie = `access_token=${accessToken};expires=${access_token_expires.toUTCString()};path=/`;
    document.cookie = `refresh_token=${refreshToken};expires=${refresh_token_expires.toUTCString()};path=/`;
    secureLocalStorage.setItem("access_token_time", access_token_expires);
  };

  function getDeviceInfo() {
    const deviceInfo = {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      browser: navigator.appName,
      browserVersion: navigator.appVersion,
      language: navigator.language,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
    };
    return deviceInfo;
  }

  useEffect(() => {
    setLoaderCheckAuth(true);
    fetch(`https://buwchlifestyles.com/api/check/`, {
      method: "POST",
      body: JSON.stringify({
        access_token: "access_token=" + getCookie("access_token"),
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          setIsAuthenticated(false);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setIsAuthenticated(data["authenticated"]);
        if (data["authenticated"] === true) {
          setEmail(data["user_email"]);
          setPhone(data["user_phone"]);
          setType(data["user_type"].toLowerCase());
          setCurrency(data["currency"]);
        } else {
          if (hash !== "" && message === "" && !isAuthenticated) {
            const parts = hash.split("#");
            const mobileRegex = /^\d{1,4}\d{10}$/;
            const isMobileValid = mobileRegex.test(parts[0]);
            const alphanumericRegex = /^[a-zA-Z0-9]{7}$/;
            const isAlphanumericValid = alphanumericRegex.test(parts[1]);
            if (isMobileValid && isAlphanumericValid) {
              const deviceInfo = getDeviceInfo();
              fetch(`https://buwchlifestyles.com/api/whatsapp-login/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  device_info: deviceInfo,
                  mobile: parts[0],
                  secretKey: parts[1],
                }),
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Failed to logout");
                  }
                  return response.json();
                })
                .then((response) => {
                  if (response.data.message === "success") {
                    setIsAuthenticated(true);
                    setTokens(
                      response.data.access_token,
                      response.data.refresh_token
                    );
                    if (
                      storedData === null ||
                      storedData2 === null ||
                      storedData3 === null ||
                      storedData4 === null ||
                      storedData5 === null ||
                      storedData6 === null ||
                      storedData7 === null
                    ) {
                      secureLocalStorage.setItem(
                        "kycData",
                        JSON.stringify(response.data.data5)
                      );
                      secureLocalStorage.setItem(
                        "Customize",
                        JSON.stringify(response.data.customize)
                      );
                      secureLocalStorage.setItem(
                        "Material_cost",
                        JSON.stringify(response.data.material_cost)
                      );
                      secureLocalStorage.setItem(
                        "Catalogue",
                        JSON.stringify(response.data.catalogue)
                      );
                      secureLocalStorage.setItem(
                        "user_order",
                        JSON.stringify(response.data.user_order)
                      );
                      secureLocalStorage.setItem(
                        "purchase_specs",
                        JSON.stringify(response.data.purchase_specs)
                      );
                    }
                    setMessage(response.data.message);
                  } else {
                    setMessage(response.data);
                  }
                })
                .catch((error) => {});
            }
          }
        }
        setLoaderCheckAuth(false);
      })
      .catch((error) => {
        console.warn("error: ", error);
      });
  }, [isAuthenticated, email, phone, type, currency]);

  return {
    loaderCheckAuth,
    isAuthenticated,
    email,
    phone,
    type,
    currency,
    message,
  };
};

export default CheckAuth;
