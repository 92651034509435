import React, { useEffect, useRef, useState } from "react";
import CheckAuth from "../ServiceFunctions/CheckAuth";
import secureLocalStorage from "react-secure-storage";
import { useLocation } from "react-router-dom";
import "./AIChat.css";

function Enquiry() {
  const location = useLocation();
  const hash = location.hash.substring(1);
  const { isAuthenticated } = CheckAuth({ hash });
  const containerRef = useRef(null);
  let kycData = JSON.parse(secureLocalStorage.getItem("kycData"));
  let AIChat = JSON.parse(secureLocalStorage.getItem("AIChat"));
  const [fontSize, setFontSize] = useState("text-2xl");
  const [fontSize2, setFontSize2] = useState("text-xl");
  const [svgSize, setSvgSize] = useState("w-7");
  const [svgSize2, setSvgSize2] = useState("right-14");
  const [padding, setPadding] = useState("p-1");
  const [text, setText] = useState("");
  const [block, setBlock] = useState(false);
  const [chat, setChat] = useState(
    AIChat
      ? AIChat
      : [
          { user: "Joe AI", msg: "Hi I'm Joe. 👋" },
          {
            user: "Joe AI",
            msg: "I'm specially trained to help you with all problems related to BUWCH. Please let me know what you need",
          },
        ]
  );
  const demoQuestion = [
    "I want leather shoe",
    "I want Shoe Accessories",
    "Shipping Details",
    "I want cheaper shipping",
    "I want premium leather shoe",
  ];
  const [isTyping, setIsTyping] = useState(false);
  const messageContainerRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const [visibleCharacters, setVisibleCharacters] = useState(0);
  const sentence =
    "I'm specially trained to help you with all problems related to BUWCH. Please let me know what you need.";
  const characters = sentence.split(""); // Split into characters, including spaces

  useEffect(() => {
    // Show one character at a time
    const interval = setInterval(() => {
      setVisibleCharacters((prev) =>
        prev < characters.length ? prev + 1 : prev
      );
    }, 20); // Adjust timing (100ms) for the delay between each character

    return () => clearInterval(interval);
  }, [characters.length]);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [chat]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setFontSize(width < 450 ? "text-sm" : "text-base");
        setFontSize2(
          width < 450 ? "text-sm" : "text-base lg:mt-3 max-w-[650px]"
        );
        setSvgSize(width < 450 ? "w-5 h-5" : "lg:w-5 lg:h-5 xl:w-6 xl:h-6");
        setSvgSize2(width < 450 ? "right-8" : "right-14");
        setPadding(
          width < 450
            ? "p-4 h-[calc(100vh-320px)]"
            : "p-5  h-[calc(100vh-120px)]"
        );
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSendMessage = (userMessage) => {
    if (userMessage.trim() !== "") {
      setBlock(true);
      setChat((prevChat) => [
        ...prevChat,
        {
          user: isAuthenticated ? kycData?.["username"] : "User",
          msg: userMessage,
        },
      ]);

      if (isAuthenticated) {
        secureLocalStorage.setItem("AIChat", JSON.stringify(chat));
        AIChat = JSON.parse(secureLocalStorage.getItem("AIChat"));
      }

      setText("");
      setIsTyping(true);
      setTimeout(() => {
        setIsTyping(false);
        setChat((prevChat) => [
          ...prevChat,
          {
            user: "Joe AI",
            msg: "",
            typing: true,
          },
        ]);

        let charIndex = 0;
        const typingInterval = setInterval(() => {
          setChat((prevChat) => {
            let message =
              "Thanks for your message! Let me assist you with that. 😊";
            const updatedChat = [...prevChat];
            const currentMessage = updatedChat[updatedChat.length - 1];
            if (charIndex < message.length) {
              currentMessage.msg += message.charAt(charIndex);
              charIndex++;
              if (isAuthenticated) {
                secureLocalStorage.setItem(
                  "AIChat",
                  JSON.stringify(updatedChat)
                );
                AIChat = JSON.parse(secureLocalStorage.getItem("AIChat"));
              }
              return updatedChat;
            } else {
              clearInterval(typingInterval);
              currentMessage.typing = false;
              setBlock(false);
              if (isAuthenticated) {
                secureLocalStorage.setItem(
                  "AIChat",
                  JSON.stringify(updatedChat)
                );
                AIChat = JSON.parse(secureLocalStorage.getItem("AIChat"));
              }
              return updatedChat;
            }
          });
        }, 100);
      }, 2000);
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  // Function to trigger the default alert when trying to navigate away
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // If the user is not authenticated
      if (!isAuthenticated) {
        // Custom message will not show, but browser will show default message
        const message =
          "Since you've not logged in, your chat history will be deleted if you close this chat messenger. Do you want to continue?";
        event.returnValue = message; // Required for most modern browsers
        return message; // For compatibility with some older browsers
      }
    };

    // Attach the beforeunload event listener to detect when the user is trying to leave
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isAuthenticated]);

  return (
    <div ref={containerRef}>
      <div
        className={`lg:rounded-xl shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] ${padding} bg-white relative flex flex-col justify-between`}
      >
        <div
          className="absolute z-0 top-0 left-0 h-full w-full opacity-10"
          style={{
            backgroundImage: "url('/images/ai.png')",
            backgroundSize: `200px 200px`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>
        <button
          className={`absolute ${svgSize2} z-30 cursor-pointer`}
          onClick={() => {
            setChat([
              { user: "Joe AI", msg: "Hi I'm Joe. 👋" },
              {
                user: "Joe AI",
                msg: "I'm specially trained to help you with all problems related to BUWCH. Please let me know what you need",
              },
            ]);
            setText("");
            if (isAuthenticated) {
              secureLocalStorage.removeItem("AIChat");
              JSON.parse(secureLocalStorage.getItem("AIChat"));
            }
          }}
        >
          <svg
            className={`${svgSize} hover:fill-red-600 duration-300`}
            version="1.1"
            id="fi_484662"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsHlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
          >
            <g>
              <g>
                <path
                  d="M436,60h-75V45c0-24.813-20.187-45-45-45H196c-24.813,0-45,20.187-45,45v15H76c-24.813,0-45,20.187-45,45
              c0,19.928,13.025,36.861,31.005,42.761L88.76,470.736C90.687,493.875,110.385,512,133.604,512h244.792
              c23.22,0,42.918-18.125,44.846-41.271l26.753-322.969C467.975,141.861,481,124.928,481,105C481,80.187,460.813,60,436,60z M181,45
              c0-8.271,6.729-15,15-15h120c8.271,0,15,6.729,15,15v15H181V45z M393.344,468.246c-0.643,7.712-7.208,13.754-14.948,13.754
              H133.604c-7.739,0-14.305-6.042-14.946-13.747L92.294,150h327.412L393.344,468.246z M436,120H76c-8.271,0-15-6.729-15-15
              s6.729-15,15-15h360c8.271,0,15,6.729,15,15S444.271,120,436,120z"
                ></path>
              </g>
            </g>
          </svg>
        </button>
        <div
          className="relative z-20 overflow-y-scroll no-scrollbar"
          ref={messageContainerRef}
        >
          {chat.map((entry, index) => (
            <div
              className={`${fontSize} mt-4 w-full text-black flex ${
                entry.user === "Joe AI" ? "justify-start" : "justify-end"
              } items-start gap-2`}
              key={index}
            >
              {entry.user === "Joe AI" && (
                <div className="w-8">
                  <div className="border rounded-full font-bold w-8 h-8 text-xs flex items-center justify-center bg-[#FFD61F] text-[#000000]">
                    {entry.user[0].toUpperCase()}
                  </div>
                </div>
              )}
              <span
                className={`text-[#000000] border shadow-sm p-2 break-words rounded-b-lg ${
                  entry.user === "Joe AI"
                    ? "rounded-r-lg mr-10 bg-[#ffd61f94]"
                    : "rounded-l-lg ml-10 bg-[#afafae73]"
                }`}
              >
                {chat.length < 4 &&
                entry.msg ===
                  "I'm specially trained to help you with all problems related to BUWCH. Please let me know what you need"
                  ? characters.slice(0, visibleCharacters).join("")
                  : entry.msg}
              </span>
              {entry.user !== "Joe AI" && (
                <div className="w-8">
                  <div className="border rounded-full bg-gray-500 font-bold w-8 h-8 text-xs flex items-center justify-center text-[#ffffff]">
                    {entry.user[0].toUpperCase()}
                  </div>
                </div>
              )}
            </div>
          ))}
          {isTyping && (
            <div className="mt-4 w-full text-black flex justify-start items-center gap-2">
              <div className="w-8">
                <div className="border rounded-full font-bold w-8 h-8 text-xs flex items-center justify-center bg-[#FFD61F] text-[#000000]">
                  J
                </div>
              </div>
              <span className="text-[#000000] border shadow-sm p-2 bg-[#ffffffc7] rounded-r-lg rounded-b-lg mr-10">
                Typing
                <span className="dot-wave">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </span>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <div className="flex items-center p-1 mb-3 border-2 bg-white backdrop-blur-md border-zinc-300 rounded-full mt-4 justify-between group cursor-pointer hover:-translate-y-1 duration-200">
            <input
              type="text"
              placeholder="Type your doubt here..."
              value={text}
              className="px-2 py-1.5 rounded-full w-full outline-none placeholder:text-sm text-black"
              onChange={(e) => setText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleSendMessage(e.target.value);
              }}
            />
            <span className="bg-[#FFD61F] py-2.5 px-2.5 rounded-full">
              {!block ? (
                <svg
                  onClick={() => handleSendMessage(text)}
                  enableBackground="new 0 0 256 256"
                  className="w-4 h-4 fill-zinc-600  duration-200"
                  viewBox="0 0 256 256"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_9903638"
                >
                  <g id="_x30_7_Arrow_Right">
                    <g>
                      <path d="m228.992 146.827-180.398 103.224c-17.497 9.998-38.04-7.264-31.166-26.206l34.642-95.842-34.642-95.843c-6.874-18.982 13.669-36.205 31.166-26.207l180.398 103.224c14.606 8.319 14.568 29.331 0 37.65z"></path>
                    </g>
                  </g>
                </svg>
              ) : (
                <>
                  <span className="dot-wave">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </>
              )}
            </span>
          </div>
          <div className="relative">
            {/* Left Arrow */}
            <button
              onClick={scrollLeft}
              className="absolute left-0 top-1/2 -translate-y-1/2 z-[1] hover:rounded-full p-2 hover:shadow-md hover:bg-zinc-200 duration-300 bg-white px-2"
              aria-label="Scroll Left"
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                id="fi_2722991"
              >
                <g id="_17" data-name="17">
                  <path d="m15 19a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 0-1.41l6-6a1 1 0 0 1 1.41 1.41l-5.29 5.29 5.29 5.29a1 1 0 0 1 -.7 1.71z"></path>
                </g>
              </svg>
            </button>

            {/* Scrollable Container */}
            <div
              ref={scrollContainerRef}
              className="text-xs flex gap-2.5 justify-start flex-nowrap overflow-x-scroll no-scrollbar scroll-smooth scrollbar-hide lg:w-[95%] px-4 lg:px-2 mx-auto"
            >
              {demoQuestion.map((data) => (
                <button
                  onClick={() => {
                    if (!block) handleSendMessage(data);
                  }}
                  className="rounded-full px-2 py-1 border-[1px] bg-[#ffffff] border-zinc-400 hover:border-[#FFD61F] hover:shadow-none hover:bg-[#FFF4B5] duration-300 hover:text-[#000000] cursor-pointer font-semibold w-fit text-nowrap"
                >
                  {data}
                </button>
              ))}
            </div>

            {/* Right Arrow */}
            <button
              onClick={scrollRight}
              className="absolute right-0 top-1/2 -translate-y-1/2 z-[1] hover:rounded-full p-2 hover:shadow-md hover:bg-zinc-200 duration-300 bg-white px-2"
              aria-label="Scroll Right"
            >
              <svg
                id="fi_2989988"
                className="w-4 h-4"
                viewBox="0 0 6.3499999 6.3500002"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsSvg="http://www.w3.org/2000/svg"
              >
                <g id="layer1" transform="translate(0 -290.65)">
                  <path
                    id="path9429"
                    d="m2.2580394 291.96502a.26460982.26460982 0 0 0 -.1741496.46871l1.6190225 1.38699-1.6190225 1.38648a.26460982.26460982 0 1 0 .3436483.40049l1.8536335-1.58595a.26460982.26460982 0 0 0 0-.40256l-1.8536335-1.5875a.26460982.26460982 0 0 0 -.1694987-.0667z"
                    font-variant-ligatures="normal"
                    font-variant-position="normal"
                    font-variant-caps="normal"
                    font-variant-numeric="normal"
                    font-variant-alternates="normal"
                    font-feature-settings="normal"
                    text-indent="0"
                    text-align="start"
                    text-decoration-line="none"
                    text-decoration-style="solid"
                    text-decoration-color="rgb(0,0,0)"
                    text-transform="none"
                    text-orientation="mixed"
                    white-space="normal"
                    shape-padding="0"
                    isolation="auto"
                    mix-blend-mode="normal"
                    solid-color="rgb(0,0,0)"
                    solid-opacity="1"
                    vector-effect="none"
                  ></path>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Enquiry;
