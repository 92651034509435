import React, { useState } from "react";
import Calendar from "./Calendar.jsx";
import { FaRegClock } from "react-icons/fa";

function EventScheduler() {
  const [rating, setRating] = useState({});
  const [openRating, setOpenRating] = useState(false);
  const sessions = [
    {
      id: 0,
      date: "12/02/25",
      time: "10:57:46",
      subject: "Hi i am chandan kumar and i want ...",
      accountManager: "Nitesh Mishra",
      avatar: "N",
      rating: 4,
    },
    {
      id: 1,
      date: "12/02/25",
      time: "10:57:46",
      subject:
        "Hi i am chandan kumar and i want some information about leather shoes...",
      accountManager: "Chandan Kumar",
      avatar: "https://i.pravatar.cc/40",
      rating: 3,
    },
  ];

  // Notifications function and API
  const notifications = [
    { id: "Alpha_02" },
    { id: "Alpha_01" },
    { id: "Alpha_03" },
  ];
  return (
    <div className="flex gap-2 justify-between">
      <div className="flex flex-col gap-5 w-[63.5%]">
        {/* Calendar */}
        <div className="max-h-[500px]">
          <Calendar />
        </div>
        {/* Session info */}
        <div className="p-2 w-full mx-auto shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-xl">
          <div className="bg-white ">
            <h2 className="text-base font-semibold p-1.5 border-b">
              Session Info
            </h2>
            <div className="overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-[#F1F7F9] text-gray-600 text-left text-[13px] font-semibold">
                    <th className="p-1.5">Date</th>
                    <th className="p-1.5">Subject</th>
                    <th className="p-1.5">Account Manager</th>
                    <th className="p-1.5">Rating</th>
                  </tr>
                </thead>
                <tbody>
                  {sessions.map((session, index) => (
                    <tr
                      key={index}
                      className="border-t text-sm w-full text-left"
                    >
                      <td className="p-1.5">
                        <div className="text-[13px]">{session.date}</div>
                        <div className="text-gray-500 text-xs flex items-center gap-1">
                          <FaRegClock /> {session.time}
                        </div>
                      </td>
                      <td className="p-1.5 max-w-[400px]">{session.subject}</td>
                      <td className="p-1.5">
                        <div className="flex items-center gap-2">
                          {session.avatar.startsWith("http") ? (
                            <img
                              src={session.avatar}
                              alt={session.accountManager}
                              className="w-6 h-6 rounded-full"
                            />
                          ) : (
                            <span className="w-6 h-6 flex items-center justify-center bg-yellow-300 text-black font-semibold rounded-full">
                              {session.avatar}
                            </span>
                          )}
                          {session.accountManager}
                        </div>
                      </td>
                      <td className="p-1.5">
                        {/* Five star */}
                        <div className="flex justify-start items-center">
                          <div className="rating z-50 flex gap-1">
                            {Array.from({ length: 5 }, (_, i) => {
                              const value = 5 - i; // Create ratings from 5 to 1
                              return (
                                <React.Fragment key={value}>
                                  <input
                                    value={value}
                                    name={`rating-${index}`} // Make the name unique for each session
                                    id={`star-${index}-${value}`} // Unique ID for each star
                                    type="radio"
                                    onChange={(e) => {
                                      setRating((prevState) => ({
                                        ...prevState,
                                        [index]: e.target.value, // Store the rating based on the session index
                                      }));
                                      setOpenRating(true); // Open image upload & textarea section when a star is clicked
                                    }}
                                    checked={rating[index] === String(value)} // Control the checked state
                                    className="hidden"
                                  />
                                  <label
                                    title={`${value} stars`}
                                    htmlFor={`star-${index}-${value}`}
                                  >
                                    <svg
                                      strokeLinejoin="round"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                      stroke="#000000"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="svgOne w-5 h-5 md:w-6 md:h-6"
                                    >
                                      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                    </svg>
                                    <svg
                                      strokeLinejoin="round"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                      stroke="#000000"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="svgTwo "
                                    >
                                      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                    </svg>
                                  </label>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Notification */}
      <div className="w-[35%] flex flex-col justify-between shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-xl p-3">
        <div>
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-base text-[#000000] font-semibold">
              Notification
            </h1>
            <button className="bg-white border px-2 py-1 text-xs font-medium rounded-lg shadow-sm flex items-center gap-1">
              Subscribe <span>
            <svg
              className="w-3.5 h-3.5 fill-gray-600"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              id="fi_2722985"
            >
              <g id="_15" data-name="15">
                <path d="m9 19a1 1 0 0 1 -.71-1.71l5.3-5.29-5.3-5.29a1 1 0 0 1 1.42-1.42l6 6a1 1 0 0 1 0 1.41l-6 6a1 1 0 0 1 -.71.3z"></path>
              </g>
            </svg>
          </span>
            </button>
          </div>
          {/* All Notifications */}
          <div className="space-y-3">
            {notifications.map((item) => (
              <div
                key={item.id}
                className="bg-white rounded-lg p-4 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] relative flex flex-col overflow-hidden"
              >
                <p className="text-xs text-gray-400 font-medium">12/11/2025</p>
                <h2 className="text-[15px] font-semibold">
                  Leathers are Finished
                </h2>
                <p className="text-[13px] text-gray-500 w-[90%]">
                  Leather production is complete, the final inspection is done,
                  and it is ready for use.
                </p>
                <div class="absolute w-0 h-0 border-l-[100px] right-0 bottom-0 border-l-transparent border-b-[100px] border-b-[#FFD700] border-r-0">
                  <span className="text-xs font-semibold bg-red-100 text-[#000000] absolute -top-[100%] left-0">
                    {item.id}
                  </span>
                </div>

                {/* </div> */}
              </div>
            ))}
          </div>
        </div>

        <button className="text-gray-600 w-full text-sm text-center py-2 flex items-center gap-1 mx-auto">
          Load More
          <span>
            <svg
              className="w-3.5 h-3.5 fill-gray-600"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              id="fi_2722985"
            >
              <g id="_15" data-name="15">
                <path d="m9 19a1 1 0 0 1 -.71-1.71l5.3-5.29-5.3-5.29a1 1 0 0 1 1.42-1.42l6 6a1 1 0 0 1 0 1.41l-6 6a1 1 0 0 1 -.71.3z"></path>
              </g>
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
}

export default EventScheduler;
