import React from "react";
import { IoClose } from "react-icons/io5";

function ConfermationReview({ onClose }) {
  return (
    <div className="popup-container">
      <div className="p-5 rounded-2xl  bg-white w-full md:max-w-[400px] pop-up-animation mx-2 relative">
        <span
          onClick={() => onClose()}
          className="bg-[#ffffff] hover:bg-red-300 border-[1px] border-zinc-400 hover:border-red-500 shadow-sm hover:shadow p-1 rounded-full text-black hover:scale-95 duration-300 cursor-pointer absolute -right-2 -top-2 z-10"
        >
          <IoClose className="text-xl" />
        </span>
        <div className="flex flex-col justify-between items-center">
          <span>
            <svg
              className="w-14 h-14"
              viewBox="0 0 64 64"
              xmlns="http://www.w3.org/2000/svg"
              id="fi_2584606"
            >
              <circle cx="32" cy="32" fill="#ffcd00" r="32"></circle>
              <g fill-rule="evenodd">
                <path
                  d="m32 64a16 16 0 0 1 -16-16 16 16 0 0 1 16-16h32a32 32 0 0 1 -32 32z"
                  fill="#ffb400"
                ></path>
                <path
                  d="m32 0a16 16 0 0 1 16 16 16 16 0 0 1 -16 16h-32a32 32 0 0 1 32-32z"
                  fill="#ffeb00"
                ></path>
                <path
                  d="m12 32.002 40-.002a19.9515 19.9515 0 0 1 -6.77 14.9992h-26.4609q-.4679-.4126-.9118-.8565a19.9977 19.9977 0 0 1 -5.8573-14.1407z"
                  fill="#46321e"
                ></path>
                <path
                  d="m18.7691 46.9992a15.9809 15.9809 0 0 1 13.2289-6.9992h.004a15.9813 15.9813 0 0 1 13.2286 6.9986 19.9237 19.9237 0 0 1 -13.2306 5.0014h-.002a19.9979 19.9979 0 0 1 -13.2289-5.0008z"
                  fill="#c8005a"
                ></path>
              </g>
              <path
                d="m55.217 22.142-3.217 9.902-8.423-6.119h-10.412l3.218-9.903-3.218-9.901h10.412l8.423-6.121 3.217 9.903 8.423 6.119z"
                fill="#ff961e"
              ></path>
              <path
                d="m8.783 22.142 3.217 9.902 8.423-6.119h10.413l-3.219-9.903 3.219-9.901h-10.413l-8.423-6.121-3.217 9.903-8.423 6.119z"
                fill="#ff961e"
              ></path>
            </svg>
          </span>
          <h2 className="text-[#000000] font-semibold mt-3">
            Thank you for your Review
          </h2>
        </div>
      </div>
    </div>
  );
}

export default ConfermationReview;
