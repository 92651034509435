import React from "react";
import Header from "../../components/Header/Header";
import { TbPointFilled } from "react-icons/tb";

function ShippingDelivery() {
  return (
    <>
      <Header />
      <div className="after-header pb-5 max-w-[1600px] mx-auto">
        <div className="w-[90%] mx-auto">
          <div className="w-full flex flex-wrap justify-start mt-4">
            <div className="w-full p-4">
              <div className="text-[#212121] font-medium">
                <p className="text-center font-semibold text-2xl md:text-3xl  text-yellow-500">
                  Shipping & Delivery
                </p>
                <p className="mt-4 text-sm">
                  We at BUWCH Lifestyles and Management understand how important
                  it is for you to receive your purchased products in the finest
                  condition, and on time. So, we have tied up with a large
                  network of leading couriers and logistic partners to deliver
                  your products to you as early and as safely as possible.
                </p>
                <p className="text-sm  tracking-wide mt-4 pl-8">
                  <ol class="list-disc">
                    <li className="mt-3">
                      Under normal operations, we aim to maintain a
                      ‘within-one-week’ shipping policy, starting from dispatch
                      to delivery to your doorstep. This is applicable for Air
                      freights anywhere in the world, provided as the default
                      option for international retail orders (1-5 pairs) only.
                      However, depending upon the exact location or country,
                      this timeline may vary by a maximum of 7 days.
                    </li>
                    <li className="mt-3">
                      Sample development time may vary and typically ranges from
                      3 to 4 weeks, depending on material availability. When
                      using our best and commonly available materials, sample
                      development can be completed within 7 days. This timeline
                      is excluding the time taken in-transit by Courier.
                    </li>
                    <li className="mt-3">
                      Transportation within India by Surface usually about 5-6
                      days. Address/PIN code within the Delhi/NCR region orders
                      take less than 48 hours to be delivered.
                    </li>
                    <li className="mt-3">
                      For bulk orders, we provide quotes from multiple logistics
                      partners, allowing customers to select their preferred
                      carrier or arrange for self-pickup from our factory. The
                      customer chooses the mode of transport (air, surface, or
                      sea), and delivery timelines vary accordingly.
                    </li>
                    <li className="mt-3">
                      BUWCH Lifestyles and Management Pvt Ltd merely functions
                      as a platform to book and pay for the transportation of
                      your order to your doorstep using third-party logistics
                      providers.
                    </li>
                    <li className="mt-3">
                      BUWCH Lifestyles and Management Pvt Ltd keeps no cuts or
                      commissions between the User and their preferred logistics
                      provider, nor does it assume any responsibility for the
                      physical delivery to the User after the shipment has been
                      dispatched from our Factory premises.
                    </li>
                    <li className="mt-3">
                      We assume no responsibility for the costs, insurance, or
                      transportation damages enroute to the User’s address after
                      Quality Check approval has been provided by the Client or
                      their authorized representatives or third-party OC
                      agencies) on the Factory premises.
                    </li>
                    <li className="mt-3">
                      BUWCH Lifestyles and Management Pvt Ltd is only
                      responsible for booking your order to the address provided
                      on your user profile. We assume no liability for delivery
                      to the wrong address by the logistics provider.
                      Furthermore, providing the correct Shipping Address is
                      solely at the discretion of the User on this web portal.
                    </li>
                  </ol>
                </p>
                <h6 className="font-bold  mt-4">Delivery Policy</h6>
                <p className="text-sm  tracking-wide mt-4 pl-4">
                  <ol class="list-decimal">
                    <li className="mt-3">
                      If the packaging of the product is open or damaged, the
                      Client is requested not to accept the order from the
                      delivery representative. Please inform us of the same via
                      mail at{" "}
                      <span className="text-blue-500 hover:underline">
                        <a href="mailto:support@buwch.com">support@buwch.com</a>
                      </span>{" "}
                      immediately so that remedial measures against the
                      logistics provider can be initiated.
                    </li>
                    <li className="mt-3">
                      The actual product pictures from six different angles (two
                      for X, Y and Z axis respectively), along with the its
                      real-world 3D render, will be shared on the User’s
                      registered WhatsApp No. prior to handover to the logistics
                      partner. In case no third-party QC agencies are employed
                      by the Client, BUWCH Lifestyles and Management Pvt Ltd
                      will assume the Client’s unequivocal approval to the
                      quality of the order and consent in its receipt in the
                      present condition.
                    </li>
                    <li className="mt-3">
                      BUWCH Lifestyles and Management Pvt Ltd will not be held
                      liable for any quality-related complaints or manufacturing
                      defects whatsoever post the final consent provided by the
                      Client prior to dispatch from our Factory premises.
                    </li>
                    <li className="mt-3">
                      The Client is obligated to immediately revert back to the
                      WhatsApp notification, or email us at{" "}
                      <span className="text-blue-500 hover:underline">
                        <a href="mailto:support@buwch.com">support@buwch.com</a>
                      </span>{" "}
                      , of any quality or product-related complaints. BUWCH
                      Lifestyles and Management Pvt Ltd will not be held liable
                      for processing your replacement/refund if the condition of
                      the shoe or accessories deviate in any way from the
                      approved pictures/3D render.
                    </li>
                    <li className="mt-3">
                      Once the purchase process is complete, customers will
                      receive confirmation thereof via their registered Email
                      ID. If you have not received confirmation of your order
                      within 24 hours of confirmation and payment thereof,
                      please contact BUWCH Lifestyles and Management Pvt Ltd
                      immediately at{" "}
                      <span className="text-blue-500 hover:underline">
                        <a href="mailto:support@buwch.com">support@buwch.com</a>
                      </span>{" "}
                      or WhatsApp No.{" "}
                      <span className="text-blue-500 hover:underline">
                        <a href="https://wa.me/919953140287" target="_blank">
                          +919953140287
                        </a>
                      </span>{" "}
                      .
                    </li>
                    <li className="mt-3">
                      The Users will have access to the GST Invoice of their
                      orders for download from our portal in the page DISPATCHED
                      SHIPMENTS. The physical copy of the same, as well as the
                      corresponding Airway/E-way bill (and other
                      customs-clearance forms) will be handed over along with
                      the actual shipment by the logistics provider.
                    </li>
                    <li className="mt-3">
                      Order specific Delivery Deadlines will be visible by the
                      User on the PRODUCTION STAGES page, and BUWCH Lifestyles
                      and Management Pvt Ltd commits to adhering strictly to it.
                      In the rare event of delays to the reported Delivery
                      Deadlines due to unforeseen circumstances, the Clients
                      will be promptly informed by both email and WhatsApp.
                    </li>
                    <li className="mt-3">
                      In case of supply-chain failures in procurement, that will
                      either delay the production or render it infeasible, the
                      Client will be immediately prompted on our portal to
                      re-customize the order to replace the materials
                      unavailable. Production will only begin once the User
                      themselves have selected the alternate
                      style/product/material from our CUSTOMIZE 3D page and
                      fresh Invoice generated against the same.
                    </li>
                    <li className="mt-3">
                      BUWCH Lifestyles and Management Pvt Ltd retains no access
                      to changing any parameter of an order once paid for and
                      confirmed by the User. Any changes thereto must be made by
                      the User alone using their credentials. Our liability is
                      restricted to informing the Clients in advance of any
                      delays or unavailability by registered Email and WhatsApp.
                    </li>
                  </ol>
                </p>
                <p className="mt-4 text-sm">
                  Please note that the provided time periods are indicative and
                  subject to change. The Company bears no responsibility for
                  delays arising from the unavailability of non-standard
                  materials, made-to-order sizes, breakdowns in the supply chain
                  or other force majeure events.
                </p>
                <h6 className="font-bold  mt-4">
                  By placing an order with us, you acknowledge and accept these
                  delivery terms."
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShippingDelivery;
